// dependencies
import React, { useState, useEffect, useMemo } from "react";
import { ProgressBar } from "react-native-web";
import { useRouter } from "next/router";

type PropsType = {
  initialVisible?: boolean;
};

const RoutingProgressBar: React.FunctionComponent<PropsType> = (props) => {
  const { initialVisible = false } = props;

  const router = useRouter();

  const [visible, setVisible] = useState(initialVisible);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    /**
     * Gère le lancement de l’indicateur de chargement
     */
    function handleStartProgress() {
      setVisible(true);
    }

    /**
     * Gère l’arret de l’indicateur de chargement
     */
    function handleStopProgress() {
      setVisible(false);
    }

    window.document.addEventListener("startProgress", handleStartProgress);
    window.document.addEventListener("stopProgress", handleStopProgress);
    router.events.on("routeChangeStart", handleStartProgress);
    router.events.on("routeChangeComplete", handleStopProgress);
    router.events.on("routeChangeError", handleStopProgress);

    return () => {
      window.document.removeEventListener("startProgress", handleStartProgress);
      window.document.removeEventListener("stopProgress", handleStopProgress);
      router.events.off("routeChangeStart", handleStartProgress);
      router.events.off("routeChangeComplete", handleStopProgress);
      router.events.off("routeChangeError", handleStopProgress);
    };
  }, [router.events]);

  return useMemo(
    () => (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          height: 2,
          zIndex: 99,
        }}
      >
        {visible && (
          <ProgressBar
            indeterminate={true}
            style={{ height: 2 }}
            trackColor={"rgba(255, 0, 0, .25)"}
            color={"rgba(255, 0, 0, 1)"}
          />
        )}
      </div>
    ),
    [visible]
  );
};

export default RoutingProgressBar;
