// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";

const { useTheme } = theming;

const styles = StyleSheet.create({
  text__icon: {
    display: "inline-flex",
    alignItems: "center",
  },
});

type PropsType = {
  as?: JSX.IntrinsicElements | React.JSXElementConstructor<any>;
  icon?: boolean;
  aStyle?;
} & React.ComponentProps<any>;

const Text: React.FunctionComponent<PropsType> = React.forwardRef(function Text(
  props,
  ref
) {
  const {
    aStyle,
    style,
    icon = false,
    children,
    as: Tag = "div",
    ...otherProps
  } = props;

  const theme = useTheme();

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        text: {
          fontFamily: theme.FONT_FAMILY,
          color: theme.PRIMARY_COLOR_TEXT,
        },
      }),
    [theme]
  );

  return (
    <Tag
      {...(otherProps as React.ComponentProps<typeof Tag>)}
      ref={ref}
      style={style}
      className={css(icon && styles.text__icon, dynamicStyles.text, aStyle)}
    >
      {children}
    </Tag>
  );
});

export default Text;
