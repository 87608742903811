// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";

// libraries
import { emToPx } from "../../../libraries";

// hocs
import withBreakpoint, {
  WithBreakpointInjectedPropsType,
} from "../../../hocs/withBreakpoint";

type PropsType = {
  type?: "fixed" | "autofit";
  breakpointList?: { [breakpointName: string]: string | number };
  gutter?: number;
  style?: any;
  col?: { [breakpointName: string]: number };
} & WithBreakpointInjectedPropsType;

const getStyles = ({ gutter }) =>
  StyleSheet.create({
    view: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: gutter,
      marginRight: gutter,
      marginBottom: gutter,
      marginLeft: gutter,
    },
  });

const Row: React.FunctionComponent<PropsType> = (props) => {
  const {
    gutter = 0,
    col,
    breakpoint,
    type = "fixed",
    style,
    children,
  } = props;

  const styles = getStyles({
    gutter: emToPx(-gutter),
  });

  return (
    <View style={[styles.view, style]}>
      {React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as React.ReactElement<any>, {
            gutter,
            breakpoint,
            type,
            col: null != child.props.col ? child.props.col : col,
            key: index,
          });
        }
      })}
    </View>
  );
};

export default withBreakpoint(Row);
