import { SOCIAL_LIST } from "@cloudspire/legacy-resources/src/constants/social";

type LayoutGetSocialLinkListParam0Type = {
  configuration: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/configuration").configurationParseData
  >;
};

type LayoutGetSocialLinkListReturnType = Array<{
  social: string;
  to: string;
}>;

/**
 * Récupère les réseaux sociaux définis pour le channel.
 */
const layoutGetSocialLinkList = ({
  configuration,
}: LayoutGetSocialLinkListParam0Type): LayoutGetSocialLinkListReturnType => {
  return SOCIAL_LIST.reduce((socialList, social) => {
    const configurationSocial = configuration.channel.socials[social.id];

    if (configurationSocial) {
      socialList.push({
        social: social.id,
        to: configurationSocial.url,
      });
    }

    return socialList;
  }, []);
};

export default layoutGetSocialLinkList;
