import FilledRoute from "./filled-route";
import AbstractRoute from "./abstract-route";

class Route extends AbstractRoute {
  public toFilled(): FilledRoute {
    return FilledRoute.fromRoute(this);
  }
}

export default Route;
