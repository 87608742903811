// dependencies
import React from "react";
import { View, Dimensions, ScaledSize, LayoutChangeEvent } from "react-native";

// libraries
import getBreakpoint from "../libraries/utils/getBreakpoint";

export type WithBreakpointInjectedPropsType = {
  breakpoint: string;
};

type WithBreakpointWrappedProps = {
  breakpointList: { [breakpoint: string]: number };
  breakpointType?: "viewport" | "element";
};

type IState = {
  breakpoint: string;
};

const withBreakpoint = <P extends WithBreakpointInjectedPropsType>(
  WrappedComponent: React.ComponentType<P>
) => {
  class WrapperComponent extends React.Component<
    Omit<P & WithBreakpointWrappedProps, keyof WithBreakpointInjectedPropsType>,
    IState
  > {
    public static defaultProps: Partial<WithBreakpointWrappedProps>;

    public constructor(
      props: Omit<
        P & WithBreakpointWrappedProps,
        keyof WithBreakpointInjectedPropsType
      >
    ) {
      super(props);

      this.state = {
        breakpoint: getBreakpoint({
          // Ici, on passe toujours la taille maximale de l'écran,
          //   malgré que le prop soit `breakpointType = "element"`.
          // Il est impossible de connaître la taille de l'élément avant que le composant soit monté.
          // On l'initialise alors avec la valeur du viewport puis la mise à jour se fera lors du rendu.
          width: undefined,
          breakpointList: props.breakpointList,
        }),
      };

      this.handleLayout = this.handleLayout.bind(this);
      this.handleChangeDimensions = this.handleChangeDimensions.bind(this);
    }

    public componentDidMount(): void {
      const { breakpointType } = this.props;

      if (breakpointType === "viewport") {
        this.initMountChangeDimensions({ update: true });
      }
    }

    public componentDidUpdate(
      prevProps: Omit<
        P & WithBreakpointWrappedProps,
        keyof WithBreakpointInjectedPropsType
      >
    ): void {
      if (prevProps.breakpointType !== this.props.breakpointType) {
        if (this.props.breakpointType === "element") {
          this.initUnmountChangeDimensions();
        } else {
          this.initMountChangeDimensions({ update: true });
        }
      }
    }

    public componentWillUnmount(): void {
      this.initUnmountChangeDimensions();
    }

    private initMountChangeDimensions({ update = false } = {}): void {
      Dimensions.addEventListener("change", this.handleChangeDimensions);

      if (update) {
        this.computeBreakpoint({ width: Dimensions.get("window").width });
      }
    }

    private initUnmountChangeDimensions(): void {
      Dimensions.removeEventListener("change", this.handleChangeDimensions);
    }

    /**
     * Lance le calcul du `breakpoint` actif et l'enregistre dans le `state`
     */
    private computeBreakpoint({ width }): void {
      const { breakpointList } = this.props;
      const { breakpoint } = this.state;

      const localBreakpoint = getBreakpoint({
        width,
        breakpointList,
      });

      if (localBreakpoint !== breakpoint) {
        this.setState({ breakpoint: localBreakpoint });
      }
    }

    private handleChangeDimensions({
      window,
    }: {
      window: ScaledSize;
      screen: ScaledSize;
    }): void {
      this.computeBreakpoint({ width: window.width });
    }

    private handleLayout(event: LayoutChangeEvent): void {
      this.computeBreakpoint({ width: event.nativeEvent.layout.width });
    }

    public render(): JSX.Element {
      const { breakpointType, ...otherProps } = this.props;
      const { breakpoint } = this.state;

      return (
        <View
          {...(breakpointType === "element"
            ? { onLayout: this.handleLayout }
            : null)}
        >
          <WrappedComponent {...(otherProps as P)} breakpoint={breakpoint} />
        </View>
      );
    }
  }

  WrapperComponent.defaultProps = {
    breakpointType: "viewport",
  };

  return WrapperComponent;
};

export default withBreakpoint;
