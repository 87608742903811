// constants
import { STORAGE_THEME_KEY } from "../../constants/theme";
import browser from "../../constants/browser";

/**
 * Récupère le thème courant.
 */
export default async function getTheme({ realm, uri }) {
  if (browser) {
    const theme = window.sessionStorage.getItem(
      `${STORAGE_THEME_KEY}.${realm}`
    );

    if (null === theme) {
      // On est passé d'un royaume à un autre ou le client a vidé son session-storage.
      console.info(
        "Switch from one realm to another or user delete his session-storage, reload the page to get the updated theme."
      );

      window.location.href = uri.toString();
    }

    return { theme: JSON.parse(theme) };
  }

  return import(`../../constants/style/${realm}`)
    .catch(() => import(`../../constants/style/default`))
    .then(({ default: theme }) => {
      return { theme };
    });
}
