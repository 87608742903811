import EnhancedDate from "../EnhancedDate";

/**
 * Retourne la plus petite des dates passées en paramètre.
 */
const min = (...dateList: EnhancedDate[]) => {
  return dateList.reduce((minDate, date) => {
    if (
      date instanceof EnhancedDate &&
      (null === minDate || date.isLessThan(minDate))
    ) {
      return date;
    }

    return minDate;
  }, null);
};

export default min;
