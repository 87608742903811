// dependencies
import React, { useMemo } from "react";
import { StyleSheet, css } from "aphrodite";

import { RowContextProvider } from "./context";

type PropsType = {
  type?: "cell" | "inline" | "column";
  gutter?: number | { vertical: number; horizontal: number };
  /**
   * Only works with `type = "cell"`
   */
  maxColumns?: number;
  /**
   * Only works with `type = "inline"`
   */
  flexWrap?: boolean;
  aStyle?;
} & React.HTMLProps<HTMLDivElement>;

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexWrap: "wrap",
  },
  inline: {
    display: "inline-flex",
    flexWrap: "nowrap",
    width: "100%",
  },
  inline__wrap: {
    flexWrap: "wrap",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
});

const Row: React.FunctionComponent<PropsType> = (props) => {
  const {
    type = "cell",
    gutter = 0,
    maxColumns = 12,
    flexWrap = false,
    aStyle,
    style,
    children,
    ...otherProps
  } = props;

  const verticalGutter = "object" === typeof gutter ? gutter.vertical : gutter;
  const horizontalGutter =
    "object" === typeof gutter ? gutter.horizontal : gutter;

  const contextValue = useMemo(
    () => ({ type, maxColumns, verticalGutter, horizontalGutter }),
    [type, maxColumns, verticalGutter, horizontalGutter]
  );

  return (
    <RowContextProvider value={contextValue}>
      <div
        {...otherProps}
        className={css(
          styles.row,
          styles[type],
          "inline" === type && flexWrap && styles.inline__wrap,
          aStyle
        )}
        style={{
          ...("inline" === type && {
            marginLeft: `${-horizontalGutter}rem`,
            marginRight: `${-horizontalGutter}rem`,
            marginTop: `${-verticalGutter}rem`,
            marginBottom: `${-verticalGutter}rem`,
          }),
          ...("column" === type && {
            marginTop: `${-verticalGutter}rem`,
            marginBottom: `${-verticalGutter}rem`,
          }),
          ...("cell" === type && {
            marginLeft: `${-horizontalGutter}rem`,
            marginRight: `${-horizontalGutter}rem`,
            marginTop: `${-verticalGutter}rem`,
            marginBottom: `${-verticalGutter}rem`,
          }),
          ...style,
        }}
      >
        {children}
      </div>
    </RowContextProvider>
  );
};

export default Row;
