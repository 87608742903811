import EnhancedDate from ".";

/**
 * @see https://github.com/jacwright/date.format/blob/master/date.format.js
 */

/**
 * Retourne le jour avec l'ajout de "0" si besoin.
 */
export const d = (date: EnhancedDate): string => {
  const d = date.getDate();
  return (d < 10 ? "0" : "") + d;
};

/**
 * Retourne le jour sans l'ajout de "0".
 */
export const j = (date: EnhancedDate): string => {
  return `${date.getDate()}`;
};

/**
 * Retourne la représentation du jour de la semaine selon la norme ISO-8601. (1 pour lundi et 7 pour dimanche).
 */
export const N = (date: EnhancedDate) => {
  const N = date.getDay();

  return N === 0 ? 7 : N;
};

/**
 * Retourne la représentation du jour de la semaine. (0 pour dimanche et 6 pour samedi).
 */
export const w = (date: EnhancedDate) => {
  return date.getDay();
};

// Week
export const W = (date) => {
  const target = new Date(date.valueOf());
  const dayNr = (date.getDay() + 6) % 7;

  target.setDate(target.getDate() - dayNr + 3);
  const firstThursday = target.valueOf();

  target.setMonth(0, 1);
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }
  const retVal = 1 + Math.ceil((firstThursday - target.valueOf()) / 604800000);

  return retVal < 10 ? `0${retVal}` : retVal;
};

// Month

/**
 * Retourne le mois avec les zéros initiaux.
 */
export const m = (date) => {
  const m = date.getMonth();
  return (m < 9 ? "0" : "") + (m + 1);
};

/**
 * Retourne le mois sans les zéros initiaux.
 */
export const n = (date) => {
  return `${date.getMonth() + 1}`;
};

/**
 * Retourne le nombre de jour sur le mois.
 */
export const t = (date) => {
  let year = date.getYear();
  let nextMonth = date.getMonth() + 1;

  if (nextMonth === 12) {
    year = year++;
    nextMonth = 0;
  }

  return new Date(year, nextMonth, 0).getDate();
};

// Year

/**
 * Retourne si l'année est bissextile.
 */
export const L = (date): boolean => {
  const L = date.getYear();

  return L % 400 === 0 || (L % 100 !== 0 && L % 4 === 0);
};

/**
 * Retourne l'année complète.
 * @example 2019
 */
export const Y = (date): string => {
  return `${date.getYear()}`;
};

/**
 * Retourne l'année avec les zéros initiaux.
 * @example 19
 */
export const y = (date) => {
  return `${date.getYear()}`.substr(2);
};

// Time

/**
 * Retourne "am" (ante meridiem) ou "pm" (post meridiem) en miniscule.
 */
export const a = (date) => {
  return date.getHours() < 12 ? "am" : "pm";
};

/**
 * Retourne "am" (ante meridiem) ou "pm" (post meridiem) en majuscule.
 */
export const A = (date) => {
  return date.getHours() < 12 ? "AM" : "PM";
};

/**
 * Retourne les heures au format 12h, sans les zéros initiaux.
 */
export const g = (date) => {
  return date.getHours() % 12 || 12;
};

/**
 * Retourne les heures au format 24h, sans les zéros initiaux.
 */
export const G = (date) => {
  return date.getHours();
};

/**
 * Retourne les heures au format 12h, avec les zéros initiaux.
 */
export const h = (date) => {
  const h = date.getHours();

  return ((h % 12 || 12) < 10 ? "0" : "") + (h % 12 || 12);
};

/**
 * Retourne les heures au format 24h, avec les zéros initiaux.
 */
export const H = (date) => {
  const H = date.getHours();

  return (H < 10 ? "0" : "") + H;
};

/**
 * Retoune les minutes, avec les zéros initiaux.
 */
export const i = (date) => {
  const i = date.getMinutes();
  return (i < 10 ? "0" : "") + i;
};

/**
 * Retourne les secondes, avec les zéros initiaux.
 */
export const s = (date) => {
  const s = date.getSeconds();
  return (s < 10 ? "0" : "") + s;
};

/**
 * Retourne les millisecondes, avec les zéros initiaux.
 */
export const v = (date) => {
  const v = date.getMilliseconds();
  return (v < 10 ? "00" : v < 100 ? "0" : "") + v;
};

export default { d, j, N, w, W, m, n, t, L, Y, y, a, A, g, G, h, H, i, s, v };
