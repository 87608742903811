import { CONTENT_TYPE_FREE } from "@cloudspire/legacy-resources/src/constants/content";

type ContentIsTypeFreeParam0Type = {
  content:
    | ReturnType<
        typeof import("@cloudspire/legacy-resources/src/normalizers/content").contentParseData
      >
    | ReturnType<
        typeof import("@cloudspire/legacy-resources/src/normalizers/configuration").configurationParseData
      >["channel"]["website"]["contents"]["list"][0];
};

/**
 * Retourne vrai si le content est une page libre.
 */
const contentIsTypeFree = ({ content }: ContentIsTypeFreeParam0Type): boolean =>
  CONTENT_TYPE_FREE === content.type;

export default contentIsTypeFree;
