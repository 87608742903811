// helpers
import { contentGenerateUriRelayOnType } from "@cloudspire/legacy-resources/src/helpers/content";

// libraries
import { get } from "@cloudspire/legacy-shared/src/libraries";

type LayoutGetBottomBarLinkListParam0Type = {
  intl: import("react-intl").IntlShape;
  configuration: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/configuration").configurationParseData
  >;
  frontRouter: import("@cloudspire/legacy-shared/src/libraries/Router").Router;
};

const layoutGetBottomBarLinkList = ({
  configuration,
  intl,
  frontRouter,
}: LayoutGetBottomBarLinkListParam0Type) => {
  return get(
    configuration,
    "channel.website.contents.bySection.bottom",
    []
  ).map((content) => {
    return {
      label: content.title,
      to: contentGenerateUriRelayOnType({ intl, content, frontRouter }),
    };
  });
};

export default layoutGetBottomBarLinkList;
