// dependencies
import React from "react";
import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  ViewProps,
} from "react-native";

// libraries
import { emToPx } from "../../libraries";

type PropsType = {
  gutter?: number;
  style?: StyleProp<ViewStyle>;
  last?: boolean;
  inline?: boolean;
} & ViewProps;

const getStyles = ({ inline, gutter }) =>
  StyleSheet.create({
    item: {
      [inline ? "marginRight" : "marginBottom"]: emToPx(gutter),
    },
    item__last: {
      [inline ? "marginRight" : "marginBottom"]: 0,
    },
  });

const Item: React.FunctionComponent<PropsType> = (props) => {
  const { last, style, children, inline, gutter, ...restProps } = props;

  const styles = getStyles({ inline, gutter });

  return (
    <View
      {...restProps}
      style={[styles.item, last && styles.item__last, style]}
    >
      {children}
    </View>
  );
};

export default Item;
