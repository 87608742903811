import React from "react";
import {
  View,
  StyleSheet,
  ViewStyle,
  TextStyle,
  ViewProps,
  StyleProp,
} from "react-native";

// components
import Text from "../Text";

// theming
import theming, { IPropsWithTheme } from "../../constants/theming";

const { withTheme } = theming;

type IProps = {
  containerStyle?: StyleProp<ViewStyle>;
  wrapperStyle?: StyleProp<TextStyle>;
  childrenStyle?: StyleProp<ViewStyle>;
  ref?;
} & IPropsWithTheme &
  ViewProps;

const BaseComponent: React.FunctionComponent<IProps> = React.forwardRef(
  function BaseComponent(props, ref: React.Ref<View>) {
    const {
      theme,
      children,
      containerStyle,
      wrapperStyle,
      childrenStyle,
      ...attrs
    } = props;

    const styles: StyleSheet.NamedStyles<{
      component: TextStyle;
      children: ViewStyle;
    }> = StyleSheet.create({
      component: {
        fontSize: `${theme.FONT_SIZE / 16}rem`,
        display: "flex",
        width: "100%",
        height: "100%",
      },
      children: {
        display: "flex",
        width: "100%",
        height: "100%",
      },
    });

    return (
      <View {...attrs} style={containerStyle} ref={ref}>
        <Text style={[styles.component, wrapperStyle]}>
          <View style={[styles.children, childrenStyle]}>{children}</View>
        </Text>
      </View>
    );
  }
);

export default withTheme(BaseComponent);
