/**
 * Converti du base64 en chaîne de caractère.
 */
const parseBase64 = (str) => {
  if (!process.browser) {
    return Buffer.from(str, "base64").toString();
  } else {
    return window.atob(str);
  }
};

export default parseBase64;
