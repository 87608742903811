export const SOCIAL_VALUE_FACEBOOK = "fb";
export const SOCIAL_VALUE_TWITTER = "tw";
export const SOCIAL_VALUE_PINTEREST = "pi";
export const SOCIAL_VALUE_INSTAGRAM = "in";
export const SOCIAL_VALUE_TUMBLR = "th";
export const SOCIAL_VALUE_LINKEDIN = "li";
export const SOCIAL_VALUE_VIADEO = "vi";
export const SOCIAL_VALUE_YOUTUBE = "yo";

export const SOCIALS = {
  [SOCIAL_VALUE_FACEBOOK]: {
    id: SOCIAL_VALUE_FACEBOOK,
    icon: "IconFacebook",
    color: "#3b599b",
    label: "Facebook",
  },
  [SOCIAL_VALUE_TWITTER]: {
    id: SOCIAL_VALUE_TWITTER,
    icon: "IconTwitter",
    color: "#55acee",
    label: "Twitter",
  },
  [SOCIAL_VALUE_PINTEREST]: {
    id: SOCIAL_VALUE_PINTEREST,
    icon: "IconPinterest",
    color: "#cd201f",
    label: "Pinterest",
  },
  [SOCIAL_VALUE_INSTAGRAM]: {
    id: SOCIAL_VALUE_INSTAGRAM,
    icon: "IconInstagram",
    color: "#d93175",
    label: "Instagram",
  },
  [SOCIAL_VALUE_TUMBLR]: {
    id: SOCIAL_VALUE_TUMBLR,
    icon: "IconTumblr",
    color: "#529ecc",
    label: "Tumblr",
  },
  [SOCIAL_VALUE_LINKEDIN]: {
    id: SOCIAL_VALUE_LINKEDIN,
    icon: "IconLinkedin",
    color: "#0375b4",
    label: "LinkedIn",
  },
  [SOCIAL_VALUE_VIADEO]: {
    id: SOCIAL_VALUE_VIADEO,
    icon: "IconViadeo",
    color: "#f07355",
    label: "Viadeo",
  },
  [SOCIAL_VALUE_YOUTUBE]: {
    id: SOCIAL_VALUE_YOUTUBE,
    icon: "IconYoutube",
    color: "#fe0000",
    label: "YouTube",
  },
};

export const SOCIAL_LIST = Object.values(SOCIALS);
