import parseBase64 from "../utils/parseBase64";

/**
 * Classe de decodage d'un JWT token
 */
class JWTDecode {
  /**
   * Récupére et de décode la partie souhaitée d'un JWT token.
   * @param {string} jwt
   * @param {int} part Partie du JWT à décoder.
   * @return {any}
   */
  public static splitAndDecode(jwt: string = null, part = 0) {
    if (null === jwt) {
      throw new Error("Specify a string JWT Token.");
    }

    if (part < 0 || part > 2) {
      throw new Error(
        "JWT token consists of 3 main parts. Header, Payload and Signature."
      );
    }
    const splitJWT = jwt.split(".");
    // il n'y a que trois parties dans un JWT token
    if (splitJWT.length !== 3) {
      throw new Error(
        "JWT token consists of 3 main parts. Header, Payload and Signature."
      );
    }
    // si le JWT token est invalid le parse ne fonctionnera pas
    try {
      return JSON.parse(parseBase64(splitJWT[part]));
    } catch (e) {
      throw new Error("JWT token is invalid");
    }
  }

  /**
   * Récupère la partie Header
   * @param {string} jwt
   * @return {object}
   */
  public static getHeader(jwt) {
    return this.splitAndDecode(jwt, 0);
  }

  /**
   * Récupère la partie Payload
   * @param {string} jwt
   * @return {object}
   */
  public static getPayload(jwt) {
    return this.splitAndDecode(jwt, 1);
  }

  /**
   * Récupére la partie Signature
   * @param {string} jwt
   * @return {object}
   */
  public static getSignature(jwt) {
    return this.splitAndDecode(jwt, 2);
  }
}

export default JWTDecode;
