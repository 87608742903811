// dependencies
import React from "react";
import { View } from "react-native";
import { useIntl } from "react-intl";

// components
import Link from "@cloudspire/legacy-resources/src/components/Link2";
import Image from "@cloudspire/legacy-resources/src/components/Image";

// hocs/hooks
import { useConfiguration } from "@cloudspire/legacy-resources/src/contexts/configuration";

type PropsType = {
  to: any;
  logoSource: { uri: string };
};

const TextLogo: React.FunctionComponent<PropsType> = (props) => {
  const { to, logoSource } = props;

  const configuration = useConfiguration();

  const intl = useIntl();

  const title = intl.formatMessage(
    { id: "app.components.layout.textLogo.homepage" },
    {
      title: configuration.channel.title,
    }
  );

  return (
    <Link to={to} title={title}>
      <View style={{ width: 266 }}>
        <Image
          accessibilityLabel={`Logo ${configuration.channel.title}`}
          source={logoSource}
          ratio={64 / 266}
          alt={title}
        />
      </View>
    </Link>
  );
};

export default TextLogo;
