import AbstractCacheManager from "./AbstractCacheManager";

class ObjectCacheManager extends AbstractCacheManager {
  private store = {};

  /**
   * @inheritdoc
   */
  public async has(key) {
    return Object.prototype.hasOwnProperty.call(
      this.store,
      await AbstractCacheManager.getHash(key)
    );
  }

  /**
   * @inheritdoc
   */
  public async get(key) {
    return this.store[await AbstractCacheManager.getHash(key)];
  }

  /**
   * @inheritdoc
   */
  public async set(key, value) {
    this.store[await AbstractCacheManager.getHash(key)] = value;
  }

  /**
   * @inheritdoc
   */
  public async delete(key) {
    delete this.store[await AbstractCacheManager.getHash(key)];
  }
}

export default ObjectCacheManager;
