import Uri from "../Uri";
import hasOwnProperty from "../utils/hasOwnProperty";

import AbstractRoute from "./abstract-route";

class AbstractFilledRoute extends AbstractRoute {
  protected parameters: { [name: string]: string } = {};

  protected foundByOverloadedPath = false;

  public setParameter(name, parameter): this {
    this.parameters[name] = parameter;

    return this;
  }

  public setParameters(parameters: { [name: string]: string }): this {
    this.parameters = parameters;

    return this;
  }

  public getParameters() {
    return { ...this.parameters };
  }

  public getParameter(name: string) {
    if (hasOwnProperty(this.parameters, name)) {
      return this.parameters[name];
    }

    return null;
  }

  public setFoundByOverloadedPath(foundByOverloadedPath: boolean) {
    this.foundByOverloadedPath = foundByOverloadedPath;
  }

  public isFoundByOverloadedPath() {
    return this.foundByOverloadedPath;
  }

  public generateUri(): Uri {
    const parameters = this.getParameters();

    const constrains = this.getConstrains();
    const overloadedPaths = this.getOverloadedPaths();

    let path =
      null !== overloadedPaths.constrainName &&
      "undefined" !==
        typeof overloadedPaths.paths[parameters[overloadedPaths.constrainName]]
        ? overloadedPaths.paths[parameters[overloadedPaths.constrainName]]
        : this.getPath();

    for (const parameter of Object.keys(constrains)) {
      if (hasOwnProperty(constrains, parameter)) {
        path = path.replace(
          new RegExp(`(?:([^\\\\]*?):${parameter})`, "g"),
          (_, fragment1) => `${fragment1}${parameters[parameter]}`
        );
      }
    }

    return this.uri.withPath(path);
  }
}

export default AbstractFilledRoute;
