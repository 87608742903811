import flattenDeepPropertyList from "../flattenDeepPropertyList";
import recursiveBracket from "../recursiveBracket";

/**
 * Simply encode a base js object as { a: 2, d: [1, "two"], c: { foo: { bar: 1 } } }
 * And returns URL encoded string: a=2&d[]=1&d[]=two&c[foo][bar]=1
 *
 * @param {Object} obj A base javascript object : {}
 * @return {String} URL encoded query string
 */
const encodeQuery = (obj: object): string => {
  const flattenKeyValueList = flattenDeepPropertyList(obj);

  if (flattenKeyValueList.length === 0) {
    return "";
  }

  return flattenKeyValueList
    .reduce((keyListAcc, [keyList, value]) => {
      if (undefined !== value && null !== value && value !== "") {
        keyListAcc.push(`${recursiveBracket(keyList)}=${value}`);
      }
      return keyListAcc;
    }, [])
    .join("&");
};

export default encodeQuery;
