// deps
import { useEffect } from "react";

/**
 * Désactive le scroll sur le document tant que la valeur est vraie.
 * @param {boolean} disableScroll
 */
export default function useDisableScroll(disableScroll) {
  useEffect(
    function () {
      if (disableScroll) {
        document.querySelector("html").scrollTo(0, 0);
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = null;
      }
    },
    [disableScroll]
  );
}
