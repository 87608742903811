// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";
import { useIntl } from "react-intl";

// components
import Image from "@cloudspire/legacy-resources/src/components/Image";
import Text from "@cloudspire/legacy-resources/src/components/Text";
import {
  List,
  ListItem,
} from "@cloudspire/legacy-resources/src/components/List";

// hocs/hooks
import { useConfiguration } from "@cloudspire/legacy-resources/src/contexts/configuration";

// libraries
import { emToPx } from "@cloudspire/legacy-resources/src/libraries";

type PropsType = {
  logoSource: { uri: string };
  title: string;
  description: string;
};

const styles = StyleSheet.create({
  list: {
    alignItems: "center",
  },
  logo: {
    width: emToPx(3.75),
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "0%",
  },
  text: {
    fontSize: emToPx(0.75),
  },
});

const Logo: React.FunctionComponent<PropsType> = (props) => {
  const { logoSource, title, description } = props;

  const configuration = useConfiguration();

  const intl = useIntl();

  return (
    <View>
      <List inline={true} gutter={1} style={styles.list}>
        <ListItem style={styles.logo}>
          <Image
            source={logoSource}
            ratio={64 / 64}
            accessibilityLabel={intl.formatMessage(
              { id: "app.components.layout.textLogo.homepage" },
              {
                title: configuration.channel.title,
              }
            )}
          />
        </ListItem>

        <ListItem style={styles.textContainer}>
          <Text style={styles.text}>{title}</Text>

          <Text style={styles.text}>{description}</Text>
        </ListItem>
      </List>
    </View>
  );
};

export default Logo;
