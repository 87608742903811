// deps
import React from "react";
import Script from "next/script";

// constants
import {
  GOOGLE_TRACKING_SERVICE_VALUE_GA,
  GOOGLE_TRACKING_SERVICE_VALUE_GTM,
  GOOGLE_TRACKING_STATE_VALUE_ACCEPTED,
  GOOGLE_TRACKING_STATE_VALUE_PENDING,
  GOOGLE_TRACKING_STATE_VALUE_REFUSED,
} from "../../constants/googleTracking";

export function getGoogleTagManagerScript(key) {
  if (null == key) {
    return null;
  }
  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${key}');`}
    </Script>
  );
}

export function getGoogleAnalyticsScript(key) {
  if (null == key) {
    return null;
  }

  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${key}`}
      />
      <Script strategy="afterInteractive" id="google-analytics">
        {`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${key}', {page_path: window.location.pathname,});`}
      </Script>
    </>
  );
}

export function getGoogleTagManagerNoScript(key) {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${key}`}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      />
    </noscript>
  );
}

export function trackPageView(url, key) {
  try {
    window.gtag("config", key, {
      page_path: url,
    });
  } catch (error) {
    console.warn(`Unable to track page ${url} using key ${key}`, error);
  }
}

/**
 *
 * @param {object} param0
 * @param {ReturnType<import("../../normalizers/configuration/parseData").default>} param0.configuration
 * @return {keyof import("../../constants/googleTracking")["GOOGLE_TRACKING_SERVICES"] | null}
 */
export function getGoogleTrackingServiceUsingConfiguration({ configuration }) {
  if (configuration.channel.thirdPartyServices.google.tagManagerKey) {
    return GOOGLE_TRACKING_SERVICE_VALUE_GTM;
  }

  if (configuration.channel.thirdPartyServices.google.analyticsKey) {
    return GOOGLE_TRACKING_SERVICE_VALUE_GA;
  }

  return null;
}

/**
 *
 * @param {object} param0
 * @param {ReturnType<import("../../normalizers/configuration/parseData").default>} param0.configuration
 * @return {string | null}
 */
export function getGoogleTrackingKeyUsingConfiguration({ configuration }) {
  if (configuration.channel.thirdPartyServices.google.tagManagerKey) {
    return configuration.channel.thirdPartyServices.google.tagManagerKey;
  }

  if (configuration.channel.thirdPartyServices.google.analyticsKey) {
    return configuration.channel.thirdPartyServices.google.analyticsKey;
  }

  return null;
}

/**
 * Récupère l’état du tracking en fonction de la valeur du cookie.
 * @param {object} param0
 * @param {string} param0.cookieValue
 * @return {keyof typeof import("../../../constants/googleTracking").GOOGLE_TRACKING_STATES}
 */
export function getTrackingState({ cookieValue }) {
  switch (cookieValue) {
    case "1":
      return GOOGLE_TRACKING_STATE_VALUE_ACCEPTED;
    case "0":
      return GOOGLE_TRACKING_STATE_VALUE_REFUSED;
    default:
      return GOOGLE_TRACKING_STATE_VALUE_PENDING;
  }
}
