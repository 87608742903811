import { CONTENT_TYPE_PAGE } from "@cloudspire/legacy-resources/src/constants/content";

type ContentIsTypePageParam0Type = {
  content:
    | ReturnType<
        typeof import("@cloudspire/legacy-resources/src/normalizers/content").contentParseData
      >
    | ReturnType<
        typeof import("@cloudspire/legacy-resources/src/normalizers/configuration").configurationParseData
      >["channel"]["website"]["contents"]["list"][0];
};

/**
 * Retourne vrai si le content est une page.
 */
const contentIsTypePage = ({ content }: ContentIsTypePageParam0Type): boolean =>
  CONTENT_TYPE_PAGE === content.type;

export default contentIsTypePage;
