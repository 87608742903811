// dependencies
import React from "react";
import {
  View,
  StyleSheet,
  TextStyle,
  ViewStyle,
  TextProps,
  StyleProp,
  AccessibilityRole,
} from "react-native";

// hocs
import withMedia, { WithMediaInjectedPropsType } from "../../hocs/withMedia";

// components
import Text from "../Text";

// theming
import theming from "../../constants/theming";

const { useTheme } = theming;

type PropsType = {
  level?: 1 | 2 | 3 | 4 | 5 | 6;
  size?: "sm" | "md" | "lg" | "xl" | number | string;
  style?: StyleProp<TextStyle>;
  textStyle?: StyleProp<TextStyle>;
  borderStyle?: StyleProp<ViewStyle>;
  withBorder?: "right" | "bottom" | "none";
} & TextProps &
  WithMediaInjectedPropsType;

const getSize = ({ size, theme }) => {
  switch (size) {
    case "sm": {
      return `${theme.FONT_SIZE / 16}em`;
    }

    case "md": {
      return `${(theme.FONT_SIZE * 1.25) / 16}em`;
    }

    case "lg": {
      return `${(theme.FONT_SIZE * 1.625) / 16}em`;
    }

    case "xl": {
      return `${(theme.FONT_SIZE * 2) / 16}em`;
    }

    default: {
      return size;
    }
  }
};

const getStyles = ({ theme, size }) =>
  StyleSheet.create({
    component: {
      fontSize: `${theme.FONT_SIZE / 16}rem` as any,
      color: theme.PRIMARY_COLOR_TEXT,
    },
    wrapper: {
      display: "flex",
    },
    wrapper__withRightBorder: {
      flexDirection: "row",
      alignItems: "center",
    },
    text: {
      fontSize: size,
      color: "currentColor",
    },
    border: {
      borderStyle: "solid",
      borderColor: "currentColor",
      borderTopWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
    },
    rightBorder: {
      flex: 1,
      marginLeft: ".625em",
    },
    bottomBorder: {
      marginTop: ".625em",
    },
  });

const Heading: React.FunctionComponent<PropsType> = (props) => {
  const {
    level = 1,
    size = "md",
    style,
    textStyle,
    withBorder = "none",
    borderStyle,
    matches,
    children,
    ...otherProps
  } = props;

  const theme = useTheme();

  const styles = getStyles({ theme, size: getSize({ theme, size }) });

  return (
    <View>
      <Text style={[styles.component, style]}>
        <View
          style={[
            styles.wrapper,
            withBorder === "right" && styles.wrapper__withRightBorder,
          ]}
        >
          <Text
            {...otherProps}
            style={[styles.text, textStyle]}
            accessibilityRole={"heading" as AccessibilityRole}
            aria-level={level.toString()}
          >
            {children}
          </Text>

          {withBorder === "bottom" && (
            <View
              aria-hidden="true"
              style={[styles.border, styles.bottomBorder, borderStyle]}
            />
          )}

          {withBorder === "right" && !matches && (
            <View
              aria-hidden="true"
              style={[styles.border, styles.rightBorder, borderStyle]}
            />
          )}
        </View>
      </Text>
    </View>
  );
};

export default withMedia({
  query: { maxWidth: 800 },
  breakpointType: "element",
})(Heading);
