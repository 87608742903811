// dependencies
import React from "react";
import { StyleSheet } from "react-native";

// theming
import theming, { IPropsWithTheme } from "../../../constants/theming";

// components
import Link, { IProps as IPropsLink } from "../Link";

const { withTheme } = theming;

type IProps = {} & IPropsLink & IPropsWithTheme;

type IState = {};

class Footer extends React.Component<IProps, IState> {
  public render(): JSX.Element {
    const { style, theme, isHovered, ...attrs } = this.props;

    const styles = StyleSheet.create({
      link: {
        color: "#757575",
      },
      link__hover: {
        color: theme.SECONDARY_COLOR,
        textDecorationLine: "underline",
      },
    });

    return (
      <Link {...attrs} style={styles.link} hoverStyle={styles.link__hover} />
    );
  }
}

export default withTheme(Footer);
