// api
import { setCookie } from "../../api/cookies";

// constants
import { USER_TOKEN_COOKIE_NAME } from "../../constants/user";

/**
 * Supprime le token d’authentification
 */
export default function nextDeleteAuthToken({ res, domain }) {
  setCookie({
    name: USER_TOKEN_COOKIE_NAME,
    value: "",
    maxAge: 0,
    domain,
    res,
  });
}
