// deps
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// libraries
import JWTDecode from "@cloudspire/legacy-shared/src/libraries/JWTDecode";
import { generateUri, request } from "@cloudspire/legacy-shared/src/libraries";

// api
import {
  deleteCookie,
  setCookie,
} from "@cloudspire/legacy-resources/src/api/cookies";

// constants
import { apiRouter } from "@cloudspire/legacy-shared/src/constants/router";
import {
  METHOD_VALUE_GET,
  METHOD_VALUE_POST,
} from "@cloudspire/legacy-shared/src/constants/methods";
import {
  USER_TOKEN_COOKIE_NAME,
  USER_LOGIN_CONTEXT_WEBSITE,
  USER_RENEW_DELAY,
} from "@cloudspire/legacy-resources/src/constants/user";

export const AuthContext = createContext<{
  user: object;
  token: string;
  domain: string;
  onLoginUsingCredentials: (param0: {
    username: string;
    password: string;
    remember: boolean;
  }) => Promise<void>;
  onLoginUsingToken: (param0: {
    tokenInfo: {
      user: object;
      token: string;
    };
  }) => Promise<void>;
  onChangeToken: (param0: { token: string }) => void;
  onShouldDeleteToken: () => void;
}>(null);

/**
 * Récupère et de décode la partie souhaitée d'un JWT token.
 * @param {object} param0
 * @param {string} param0.token
 * @return {object}
 */
function parseToken({ token }) {
  let user = null;

  if (token) {
    user = JWTDecode.getPayload(token);
  }

  return {
    user,
    token,
  };
}

/**
 * Récupère la date d'expiration du token en millisecondes.
 * @param {object} param0
 */
function getExpiration({ token }) {
  return JWTDecode.getPayload(token).exp * 1000;
}

/**
 * Vérifie si le token est expiré.
 * @param {object} param0
 * @param {string} param0.token
 * @return {boolean}
 */
function isTokenExpired({ token }) {
  return getExpiration({ token }) < Date.now();
}

/**
 * Vérifie si le token est valide mais sur le point d'expirer.
 * @param {object} param0
 * @param {string} param0.token
 * @return {boolean}
 */
function isTokenAboutToExpire({ token }) {
  return getExpiration({ token }) < Date.now() + USER_RENEW_DELAY;
}

export function AuthProvider(props) {
  const { domain, initialToken, children } = props;

  const [tokenInfo, setTokenInfo] = useState(
    parseToken({ token: initialToken })
  );

  /**
   * Gère le changement de token.
   */
  const handleChangeToken = useCallback(
    function handleChangeToken({ token }) {
      // Si le token est défini
      if (token) {
        setTokenInfo(parseToken({ token }));

        setCookie({
          name: USER_TOKEN_COOKIE_NAME,
          value: token,
          path: "/",
          expires: new Date(getExpiration({ token })),
          sameSite: "lax",
          secure: true,
          httpOnly: false,
          domain,
        });
      } else {
        setTokenInfo(parseToken({ token: null }));
        deleteCookie({
          name: USER_TOKEN_COOKIE_NAME,
          sameSite: "lax",
          domain,
        });
      }
    },
    [domain]
  );

  /**
   * Gère la connexion à partir d’un token.
   */
  const handleLoginUsingToken = useCallback(
    async function handleLoginUsingToken({ tokenInfo }) {
      await request({
        url: generateUri({
          router: apiRouter,
          name: "Api.Action.User.Action.Whois",
          parameters: {
            userId: tokenInfo.user.userId,
          },
        }),
        method: METHOD_VALUE_GET,
        context: {
          token: tokenInfo.token,
          channelId: null,
          onUnauthorized: null,
        },
      }).then((response) => {
        handleChangeToken({ token: response.body?.meta?.token });
      });
    },
    [handleChangeToken]
  );

  /**
   * Gère la connexion à partir d’un couple d’identifiants.
   */
  const handleLoginUsingCredentials = useCallback(
    async function handleLodingUsingCredentials({
      username,
      password,
      remember,
    }) {
      await request({
        url: generateUri({
          router: apiRouter,
          name: "Api.Action.User.Login",
        }),
        method: METHOD_VALUE_POST,
        type: "formData",
        body: {
          username,
          password,
          remember,
          context: USER_LOGIN_CONTEXT_WEBSITE,
        },
      }).then((response) => {
        handleChangeToken({ token: response?.body?.data?.token });
      });
    },
    [handleChangeToken]
  );

  // Vérifie si le token est à jour
  useEffect(
    function () {
      let timeout;
      // Si l’utilisateur est connecté
      if (tokenInfo.user && tokenInfo.token) {
        // Si la validité du token est dépassée
        if (isTokenExpired({ token: tokenInfo.token })) {
          console.log("JWT Token expired");

          handleChangeToken({ token: null });
        }
        // Si la validité du token est valide
        else {
          // Si la validité du token est sur le point d’expirer
          if (isTokenAboutToExpire({ token: tokenInfo.token })) {
            handleLoginUsingToken({ tokenInfo });
          }
          // Sinon, on programme le renouvellement à la milliseconde où le token est sur le point d’expirer
          else {
            /** Délai avant la fin de validité du token pour le renouveler (10 minutes) */
            const delay =
              getExpiration({ token: tokenInfo.token }) -
              Date.now() -
              USER_RENEW_DELAY +
              1;
            timeout = setTimeout(function () {
              handleLoginUsingToken({ tokenInfo });
            }, delay);
          }
        }
      }

      return function () {
        clearTimeout(timeout);
      };
    },
    [tokenInfo, handleLoginUsingToken]
  );

  const value = useMemo(
    function () {
      return {
        domain,
        user: tokenInfo.user,
        token: tokenInfo.token,
        onLoginUsingCredentials: handleLoginUsingCredentials,
        onLoginUsingToken: handleLoginUsingToken,
        onChangeToken: handleChangeToken,
        onShouldDeleteToken: function () {
          handleChangeToken({ token: null });
        },
      };
    },
    [
      domain,
      tokenInfo,
      handleLoginUsingCredentials,
      handleLoginUsingToken,
      handleChangeToken,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
