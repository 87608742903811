/*
 * Catégories
 */

export const ROOM_CATEGORY_VALUE_APPARTEMENT = "appartement";
export const ROOM_CATEGORY_VALUE_AUBERGE_FAMILIALE = "auberge-familiale";
export const ROOM_CATEGORY_VALUE_BUNGALOW = "bungalow";
export const ROOM_CATEGORY_VALUE_CAMPING = "camping";
export const ROOM_CATEGORY_VALUE_CAMPING_FERME = "camping-ferme";
export const ROOM_CATEGORY_VALUE_CAMPING_MOBIL_HOME = "camping-mobil-home";
export const ROOM_CATEGORY_VALUE_CHALET = "chalet";
export const ROOM_CATEGORY_VALUE_CHALET_LOISIRS = "chalet-loisirs";
export const ROOM_CATEGORY_VALUE_CHAMBRE_HOTE = "hote";
export const ROOM_CATEGORY_VALUE_CHATEAU = "chateau";
export const ROOM_CATEGORY_VALUE_CITY_BREAK = "city_break";
export const ROOM_CATEGORY_VALUE_ECOGITE = "ecogite";
export const ROOM_CATEGORY_VALUE_FERME = "ferme";
export const ROOM_CATEGORY_VALUE_FERME_PEDAGOGIQUE = "ferme-pedagogique";
export const ROOM_CATEGORY_VALUE_FUSTE = "fuste";
export const ROOM_CATEGORY_VALUE_GITE = "gite";
export const ROOM_CATEGORY_VALUE_GITE_ENFANT = "gite-enfant";
export const ROOM_CATEGORY_VALUE_GOURMANDE_FERME = "gourmande-ferme";
export const ROOM_CATEGORY_VALUE_GOURMANDE_LOCAUX = "gourmande-locaux";
export const ROOM_CATEGORY_VALUE_GROUPE = "groupe";
export const ROOM_CATEGORY_VALUE_HAUT_GAMME = "haut-gamme";
export const ROOM_CATEGORY_VALUE_HOTEL = "hotel";
export const ROOM_CATEGORY_VALUE_HOTE_FERME = "hote-ferme";
export const ROOM_CATEGORY_VALUE_INSOLITE = "insolite";
export const ROOM_CATEGORY_VALUE_MAISON = "maison";
export const ROOM_CATEGORY_VALUE_MOBIL_HOME = "mobil-home";
export const ROOM_CATEGORY_VALUE_MOULIN = "moulin";
export const ROOM_CATEGORY_VALUE_VILLA = "villa";
export const ROOM_CATEGORY_VALUE_VILLAGE_CLUB = "village-club";
export const ROOM_CATEGORY_VALUE_CHAMBRE_HABITANT = "chambre-habitant";

export const ROOM_CATEGORIES = {
  [ROOM_CATEGORY_VALUE_APPARTEMENT]: {
    id: ROOM_CATEGORY_VALUE_APPARTEMENT,
    slug: "appartement",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_AUBERGE_FAMILIALE]: {
    id: ROOM_CATEGORY_VALUE_AUBERGE_FAMILIALE,
    slug: "auberge_familiale",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_BUNGALOW]: {
    id: ROOM_CATEGORY_VALUE_BUNGALOW,
    slug: "bungalow",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_CAMPING]: {
    id: ROOM_CATEGORY_VALUE_CAMPING,
    slug: "camping",
    weight: 1,
    mainColor: "#e7ac26",
  },
  [ROOM_CATEGORY_VALUE_CAMPING_FERME]: {
    id: ROOM_CATEGORY_VALUE_CAMPING_FERME,
    slug: "camping_ferme",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_CAMPING_MOBIL_HOME]: {
    id: ROOM_CATEGORY_VALUE_CAMPING_MOBIL_HOME,
    slug: "camping_mobil_home",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_CHALET]: {
    id: ROOM_CATEGORY_VALUE_CHALET,
    slug: "chalet",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_CHALET_LOISIRS]: {
    id: ROOM_CATEGORY_VALUE_CHALET_LOISIRS,
    slug: "chalet_loisirs",
    weight: 1,
    mainColor: "#e7ac26",
  },
  [ROOM_CATEGORY_VALUE_CHAMBRE_HOTE]: {
    id: ROOM_CATEGORY_VALUE_CHAMBRE_HOTE,
    slug: "hote",
    weight: 1,
    mainColor: "#108dbb",
  },
  [ROOM_CATEGORY_VALUE_CHATEAU]: {
    id: ROOM_CATEGORY_VALUE_CHATEAU,
    slug: "chateau",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_CITY_BREAK]: {
    id: ROOM_CATEGORY_VALUE_CITY_BREAK,
    slug: "city_break",
    weight: 4,
    mainColor: "#814a97",
  },
  [ROOM_CATEGORY_VALUE_ECOGITE]: {
    id: ROOM_CATEGORY_VALUE_ECOGITE,
    slug: "ecogite",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_FERME]: {
    id: ROOM_CATEGORY_VALUE_FERME,
    slug: "ferme",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_FERME_PEDAGOGIQUE]: {
    id: ROOM_CATEGORY_VALUE_FERME_PEDAGOGIQUE,
    slug: "ferme_pedagogique",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_FUSTE]: {
    id: ROOM_CATEGORY_VALUE_FUSTE,
    slug: "fuste",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_GITE]: {
    id: ROOM_CATEGORY_VALUE_GITE,
    slug: "gite",
    weight: 1,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_GITE_ENFANT]: {
    id: ROOM_CATEGORY_VALUE_GITE_ENFANT,
    slug: "gite_enfant",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_GOURMANDE_FERME]: {
    id: ROOM_CATEGORY_VALUE_GOURMANDE_FERME,
    slug: "gourmande_ferme",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_GOURMANDE_LOCAUX]: {
    id: ROOM_CATEGORY_VALUE_GOURMANDE_LOCAUX,
    slug: "gourmande_locaux",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_GROUPE]: {
    id: ROOM_CATEGORY_VALUE_GROUPE,
    slug: "groupe",
    weight: 2,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_HAUT_GAMME]: {
    id: ROOM_CATEGORY_VALUE_HAUT_GAMME,
    slug: "haut_gamme",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_HOTEL]: {
    id: ROOM_CATEGORY_VALUE_HOTEL,
    slug: "hotel",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_HOTE_FERME]: {
    id: ROOM_CATEGORY_VALUE_HOTE_FERME,
    slug: "hote_ferme",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_INSOLITE]: {
    id: ROOM_CATEGORY_VALUE_INSOLITE,
    slug: "insolite",
    weight: 3,
    mainColor: "#f8d501",
  },
  [ROOM_CATEGORY_VALUE_MAISON]: {
    id: ROOM_CATEGORY_VALUE_MAISON,
    slug: "maison",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_MOBIL_HOME]: {
    id: ROOM_CATEGORY_VALUE_MOBIL_HOME,
    slug: "mobil_home",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_MOULIN]: {
    id: ROOM_CATEGORY_VALUE_MOULIN,
    slug: "moulin",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_VILLA]: {
    id: ROOM_CATEGORY_VALUE_VILLA,
    slug: "villa",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_VILLAGE_CLUB]: {
    id: ROOM_CATEGORY_VALUE_VILLAGE_CLUB,
    slug: "village_club",
    weight: 0,
    mainColor: "#7aa62e",
  },
  [ROOM_CATEGORY_VALUE_CHAMBRE_HABITANT]: {
    id: ROOM_CATEGORY_VALUE_CHAMBRE_HABITANT,
    slug: "chambre_habitant",
    weight: 0,
    mainColor: "#7aa62e",
  },
};

export const ROOM_CATEGORY_LIST = Object.values(ROOM_CATEGORIES);

/*
 * Couleur par défaut
 */

export const ROOM_DEFAULT_MAIN_COLOR = "#7aa62e";

/*
 * Fonctionnalités d'hébergement
 */

//  équipements intérieurs
export const ROOM_FEATURE_CATEGORY_VALUE_EQUIPMENT_INDOOR = "ei";
// équipements extérieurs
export const ROOM_FEATURE_CATEGORY_VALUE_EQUIPMENT_OUTDOOR = "eo";
// centres d'intérêt
export const ROOM_FEATURE_CATEGORY_VALUE_INTEREST = "in";
// services
export const ROOM_FEATURE_CATEGORY_VALUE_SERVICE = "se";
// caractéristiques
export const ROOM_FEATURE_CATEGORY_VALUE_FEATURE = "fe";
// thèmes
export const ROOM_FEATURE_CATEGORY_VALUE_THEME = "th";
// Qualification Gîtes de France
export const ROOM_FEATURE_CATEGORY_VALUE_QUALIFICATION_INT = "qi";
// Services extérieurs : sx
export const ROOM_FEATURE_CATEGORY_VALUE_SERVICE_EXT = "sx";
// Activités extérieurs : ax
export const ROOM_FEATURE_CATEGORY_VALUE_ACTIVITY_EXT = "ax";

export const ROOM_FEATURE_CATEGORIES = {
  [ROOM_FEATURE_CATEGORY_VALUE_EQUIPMENT_INDOOR]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_EQUIPMENT_INDOOR,
    label: "Équipements intérieurs",
    useDistance: false,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_EQUIPMENT_OUTDOOR]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_EQUIPMENT_OUTDOOR,
    useDistance: false,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_INTEREST]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_INTEREST,
    useDistance: true,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_SERVICE]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_SERVICE,
    useDistance: false,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_FEATURE]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_FEATURE,
    useDistance: false,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_THEME]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_THEME,
    useDistance: false,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_QUALIFICATION_INT]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_QUALIFICATION_INT,
    useDistance: false,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_SERVICE_EXT]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_SERVICE_EXT,
    useDistance: true,
  },
  [ROOM_FEATURE_CATEGORY_VALUE_ACTIVITY_EXT]: {
    id: ROOM_FEATURE_CATEGORY_VALUE_ACTIVITY_EXT,
    useDistance: true,
  },
};

export const ROOM_FEATURE_CATEGORY_LIST = Object.values(
  ROOM_FEATURE_CATEGORIES
);

export const ROOM_FEATURE_VALUE_ALL_INCLUSIVE_RATE = "330";

/**
 * Option requires
 */

export const ROOM_OPTION_REQUIRE_TYPE_VALUE_NOT_REQ = "not";
export const ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_ALL = "all";
export const ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PRO = "pro";
export const ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PAR = "par";

export const ROOM_OPTION_REQUIRE_TYPES = {
  [ROOM_OPTION_REQUIRE_TYPE_VALUE_NOT_REQ]: {
    id: ROOM_OPTION_REQUIRE_TYPE_VALUE_NOT_REQ,
    slug: "not",
    type: "all",
  },
  [ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_ALL]: {
    id: ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_ALL,
    slug: "all",
    type: "all",
  },
  [ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PRO]: {
    id: ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PRO,
    slug: "pro",
  },
  [ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PAR]: {
    id: ROOM_OPTION_REQUIRE_TYPE_VALUE_REQ_PAR,
    slug: "par",
  },
};

/**
 * Option strategies
 */

export const ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_NIGHT = "papn";
export const ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_DAY = "papd";
export const ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_TRIP = "papt";
export const ROOM_OPTION_STRATEGY_VALUE_PER_NIGHT = "pn";
export const ROOM_OPTION_STRATEGY_VALUE_PER_DAY = "pd";
export const ROOM_OPTION_STRATEGY_VALUE_PER_TRIP = "pt";
export const ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_NIGHT = "pppn";
export const ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_DAY = "pppd";
export const ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_TRIP = "pppt";
export const ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_NIGHT = "pepn";
export const ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_DAY = "pepd";
export const ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_TRIP = "pept";
export const ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_NIGHT = "pb1ppn";
export const ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_DAY = "pb1ppd";
export const ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_TRIP = "pb1ppt";
export const ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_NIGHT = "pb2ppn";
export const ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_DAY = "pb2ppd";
export const ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_TRIP = "pb2ppt";

export const ROOM_OPTION_STRATEGIES = {
  [ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_NIGHT]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_NIGHT,
    slug: "per_adult_per_night",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_DAY]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_DAY,
    slug: "per_adult_per_day",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_TRIP]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_ADULT_PER_TRIP,
    slug: "per_adult_per_trip",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_NIGHT]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_NIGHT,
    slug: "per_night",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_DAY]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_DAY,
    slug: "per_day",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_TRIP]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_TRIP,
    slug: "per_trip",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_NIGHT]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_NIGHT,
    slug: "per_pers_per_night",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_DAY]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_DAY,
    slug: "per_pers_per_day",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_TRIP]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_PERS_PER_TRIP,
    slug: "per_pers_per_trip",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_NIGHT]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_NIGHT,
    slug: "per_pet_per_night",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_DAY]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_DAY,
    slug: "per_pet_per_day",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_TRIP]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_PET_PER_TRIP,
    slug: "per_pet_per_trip",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_NIGHT]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_NIGHT,
    slug: "per_bed1_p_per_night",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_DAY]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_DAY,
    slug: "per_bed1_p_per_day",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_TRIP]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_BED1P_PER_TRIP,
    slug: "per_bed1_p_per_trip",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_NIGHT]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_NIGHT,
    slug: "per_bed2_p_per_night",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_DAY]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_DAY,
    slug: "per_bed2_p_per_day",
  },
  [ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_TRIP]: {
    id: ROOM_OPTION_STRATEGY_VALUE_PER_BED2P_PER_TRIP,
    slug: "per_bed2_p_per_trip",
  },
};

export const ROOM_OPTION_STRATEGY_LIST = Object.values(ROOM_OPTION_STRATEGIES);

/**
 * Handicaps
 */

export const ROOM_HANDICAP_VALUE_MENTAL = "men";
export const ROOM_HANDICAP_VALUE_MOTEUR = "mot";
export const ROOM_HANDICAP_VALUE_VISUEL = "vis";
export const ROOM_HANDICAP_VALUE_AUDITIF = "aud";

export const ROOM_HANDICAPS = {
  [ROOM_HANDICAP_VALUE_MENTAL]: {
    id: ROOM_HANDICAP_VALUE_MENTAL,
    slug: "mental",
  },
  [ROOM_HANDICAP_VALUE_MOTEUR]: {
    id: ROOM_HANDICAP_VALUE_MOTEUR,
    slug: "moteur",
  },
  [ROOM_HANDICAP_VALUE_VISUEL]: {
    id: ROOM_HANDICAP_VALUE_VISUEL,
    slug: "visuel",
  },
  [ROOM_HANDICAP_VALUE_AUDITIF]: {
    id: ROOM_HANDICAP_VALUE_AUDITIF,
    slug: "auditif",
  },
};

/**
 * State
 */

export const ROOM_STATE_VALUE_REGISTERED = "re";
export const ROOM_STATE_VALUE_ENABLED = "en";
export const ROOM_STATE_VALUE_DISABLED = "di";
export const ROOM_STATE_VALUE_TRASHED = "tr";

export const ROOM_STATES = {
  [ROOM_STATE_VALUE_REGISTERED]: {
    id: ROOM_STATE_VALUE_REGISTERED,
  },
  [ROOM_STATE_VALUE_ENABLED]: {
    id: ROOM_STATE_VALUE_ENABLED,
  },
  [ROOM_STATE_VALUE_DISABLED]: {
    id: ROOM_STATE_VALUE_DISABLED,
  },
  [ROOM_STATE_VALUE_TRASHED]: {
    id: ROOM_STATE_VALUE_TRASHED,
  },
};

/**
 * Contact list
 */

export const ROOM_BOOKING_CONFIG_CONTACT_VALUE_OWNER = "owner";
export const ROOM_BOOKING_CONFIG_CONTACT_VALUE_CHANNEL = "channel";

/**
 * Room availability background-color
 */

export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_AVAILABLE_WITHOUT_CONDITIONS =
  "#82A145";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_AVAILABLE_WITH_CONDITIONS =
  "#D6A35C";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_UNAVAILABLE = "#C76B7A";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_CLOSED = "#C76B7A";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_BUSY = "#C76B7A";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_HOVER = "#1F9462";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_INTERACTION = "#156543";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_NON_TRAILING_OR_LEADING_DEFAULT =
  "#eeeeef";
export const ROOM_AVAILABILITY_BACKGROUND_COLOR_VALUE_TRAILING_OR_LEADING_DEFAULT =
  "#eeeeef";

/**
 * Room availability text-color
 */

export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_AVAILABLE_WITHOUT_CONDITIONS =
  "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_AVAILABLE_WITH_CONDITIONS =
  "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_UNAVAILABLE = "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_CLOSED = "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_BUSY = "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_HOVER = "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_INTERACTION = "#ffffff";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_NON_TRAILING_OR_LEADING_DEFAULT =
  "#d4d4d4";
export const ROOM_AVAILABILITY_TEXT_COLOR_VALUE_TRAILING_OR_LEADING_DEFAULT =
  "#ffffff";

/*
 * Nature
 */

export const ROOM_NATURE_VALUE_COMMON = "co"; // structure seule
export const ROOM_NATURE_VALUE_PARENT = "pa"; // structure mère
export const ROOM_NATURE_VALUE_CHILD = "ch"; // sous-structure

export const ROOM_NATURES = {
  [ROOM_NATURE_VALUE_COMMON]: {
    id: ROOM_NATURE_VALUE_COMMON,
  },
  [ROOM_NATURE_VALUE_PARENT]: {
    id: ROOM_NATURE_VALUE_PARENT,
  },
  [ROOM_NATURE_VALUE_CHILD]: {
    id: ROOM_NATURE_VALUE_CHILD,
  },
};

/**
 * Type
 */

export const ROOM_TYPE_GITE = "G";
export const ROOM_TYPE_CHAMBRE_HOTE = "H";
export const ROOM_TYPE_GITE_GROUPE = "P";
export const ROOM_TYPE_GITE_ETAPE = "E";
export const ROOM_TYPE_CAMPING = "C";

export const ROOM_TYPES = {
  [ROOM_TYPE_GITE]: {
    id: ROOM_TYPE_GITE,
  },
  [ROOM_TYPE_CHAMBRE_HOTE]: {
    id: ROOM_TYPE_CHAMBRE_HOTE,
  },
  [ROOM_TYPE_GITE_GROUPE]: {
    id: ROOM_TYPE_GITE_GROUPE,
  },
  [ROOM_TYPE_GITE_ETAPE]: {
    id: ROOM_TYPE_GITE_ETAPE,
  },
  [ROOM_TYPE_CAMPING]: {
    id: ROOM_TYPE_CAMPING,
  },
};

/**
 * Room ranking type
 */

export const ROOM_RANKING_TYPE_EPI = "epi";

export const ROOM_RANKING_TYPE_CITYBREAK = "citybreak";

export const ROOM_RANKING_TYPE_NONE = "none";

export const ROOM_RANKING_TYPES = {
  [ROOM_RANKING_TYPE_EPI]: ROOM_RANKING_TYPE_EPI,
  [ROOM_RANKING_TYPE_CITYBREAK]: ROOM_RANKING_TYPE_CITYBREAK,
  [ROOM_RANKING_TYPE_NONE]: ROOM_RANKING_TYPE_NONE,
};

export const ROOM_RANKING_TYPE_LIST = Object.values(ROOM_RANKING_TYPES);

/**
 * Room ranking citybreak
 */

export const ROOM_CITYBREAK_RANKING_VALUE_CON = "con";
export const ROOM_CITYBREAK_RANKING_VALUE_PRE = "pre";
export const ROOM_CITYBREAK_RANKING_VALUE_LUX = "lux";

export const ROOM_CITYBREAK_RANKINGS = {
  [ROOM_CITYBREAK_RANKING_VALUE_CON]: {
    id: ROOM_CITYBREAK_RANKING_VALUE_CON,
    epi: 3,
  },
  [ROOM_CITYBREAK_RANKING_VALUE_PRE]: {
    id: ROOM_CITYBREAK_RANKING_VALUE_PRE,
    epi: 4,
  },
  [ROOM_CITYBREAK_RANKING_VALUE_LUX]: {
    id: ROOM_CITYBREAK_RANKING_VALUE_LUX,
    epi: 5,
  },
};

export const ROOM_CITYBREAK_RANKING_LIST = Object.values(
  ROOM_CITYBREAK_RANKINGS
);

/**
 * Room trophies
 */

export const ROOM_TROPHY_VALUE_GDF_2017 = "GDF_2017";
export const ROOM_TROPHY_VALUE_GDF_2018 = "GDF_2018";
export const ROOM_TROPHY_VALUE_GDF_2019 = "GDF_2019";
export const ROOM_TROPHY_VALUE_GDF_2020 = "GDF_2020";
export const ROOM_TROPHY_VALUE_GDF_2021 = "GDF_2021";
export const ROOM_TROPHY_VALUE_GDF_2022 = "GDF_2022";
export const ROOM_TROPHY_VALUE_GDF_2023 = "GDF_2023";
export const ROOM_TROPHY_VALUE_GDF_2024 = "GDF_2024";

export const ROOM_TROPHIES = {
  [ROOM_TROPHY_VALUE_GDF_2017]: {
    id: ROOM_TROPHY_VALUE_GDF_2017,
    slug: "2017",
  },
  [ROOM_TROPHY_VALUE_GDF_2018]: {
    id: ROOM_TROPHY_VALUE_GDF_2018,
    slug: "2018",
  },
  [ROOM_TROPHY_VALUE_GDF_2019]: {
    id: ROOM_TROPHY_VALUE_GDF_2019,
    slug: "2019",
  },
  [ROOM_TROPHY_VALUE_GDF_2020]: {
    id: ROOM_TROPHY_VALUE_GDF_2020,
    slug: "2020",
  },
  [ROOM_TROPHY_VALUE_GDF_2021]: {
    id: ROOM_TROPHY_VALUE_GDF_2021,
    slug: "2021",
  },
  [ROOM_TROPHY_VALUE_GDF_2022]: {
    id: ROOM_TROPHY_VALUE_GDF_2022,
    slug: "2022",
  },
  [ROOM_TROPHY_VALUE_GDF_2023]: {
    id: ROOM_TROPHY_VALUE_GDF_2023,
    slug: "2023",
  },
  [ROOM_TROPHY_VALUE_GDF_2024]: {
    id: ROOM_TROPHY_VALUE_GDF_2024,
    slug: "2024",
  },
};

export const ROOM_TROPHY_LIST = Object.values(ROOM_TROPHIES);

/**
 * Room epi
 */

export const ROOM_EPI_VALUE_1 = "1";
export const ROOM_EPI_VALUE_2 = "2";
export const ROOM_EPI_VALUE_3 = "3";
export const ROOM_EPI_VALUE_4 = "4";
export const ROOM_EPI_VALUE_5 = "5";

export const ROOM_EPIS = {
  [ROOM_EPI_VALUE_1]: {
    id: ROOM_EPI_VALUE_1,
  },
  [ROOM_EPI_VALUE_2]: {
    id: ROOM_EPI_VALUE_2,
  },
  [ROOM_EPI_VALUE_3]: {
    id: ROOM_EPI_VALUE_3,
  },
  [ROOM_EPI_VALUE_4]: {
    id: ROOM_EPI_VALUE_4,
  },
  [ROOM_EPI_VALUE_5]: {
    id: ROOM_EPI_VALUE_5,
  },
};

export const ROOM_EPI_LIST = Object.values(ROOM_EPIS);
