import get from "../get";

/**
 * Retourne vrai si une des clés passée dans parameterNameList
 *   est différentes entre `prev` et `next`.
 * @example
 * getDifferentInList(["foo"], { foo: "bar" }, { foo: "changed" }); // true
 * getDifferentInList(["foo"], { foo: "bar" }, { foo: "bar" }); // false
 */
const getDifferentInList = (parameterNameList, prev, next) => {
  return parameterNameList.some((parameterName) => {
    return get(prev, parameterName) !== get(next, parameterName);
  });
};

export default getDifferentInList;
