import Route from "./route";
import AbstractRouter from "./abstract-router";

import setPathFor from "./prototypes/setPathFor";
import group from "./prototypes/group";

class GroupRouter extends AbstractRouter {
  protected GroupRouter = GroupRouter;

  protected Route = Route;

  protected constrains: { [name: string]: RegExp } = {};

  protected overloadedPaths: {
    constrainName: string;
    paths: { [constrainName: string]: string };
  } = { constrainName: null, paths: {} };

  public route(
    methods: (
      | "GET"
      | "POST"
      | "PUT"
      | "PATCH"
      | "DELETE"
      | "HEAD"
      | "CONNECT"
      | "OPTIONS"
      | "TRACE"
    )[],
    path: string
  ): Route {
    const route = super.route(methods, path);

    const overloadedPaths = this.getOverloadedPaths();

    const contrains = this.getConstrains();

    route.setConstrains(contrains);

    route.setOverloadedPaths({
      constrainName: overloadedPaths.constrainName,
      paths: {},
    });

    return route;
  }

  public setConstrain(name, constrain: RegExp): this {
    this.constrains[name] = constrain;

    return this;
  }

  public setConstrains(constrains): this {
    this.constrains = constrains;

    return this;
  }

  public getConstrains() {
    return this.constrains;
  }

  public setPathFor(name: string, value: string, path: string): this {
    return setPathFor.call(this, name, value, path);
  }

  public setOverloadedPaths(overloadedPaths: {
    constrainName: string;
    paths: { [constrainName: string]: string };
  }): this {
    this.overloadedPaths = overloadedPaths;

    return this;
  }

  public getOverloadedPaths(): {
    constrainName: string;
    paths: { [constrainName: string]: string };
  } {
    return this.overloadedPaths;
  }

  public group(
    path: string,
    callback: (router: import("./group-router").default) => void
  ) {
    const router = group.call(this, path, callback, {
      setConstrains: ({ newRoute, route }) => {
        newRoute.setConstrains({
          ...route.getConstrains(),
          ...this.getConstrains(),
        });
      },
    });

    const overloadedPaths = this.getOverloadedPaths();

    router.setOverloadedPaths({
      constrainName: overloadedPaths.constrainName,
      paths: {},
    });
  }
}

export default GroupRouter;
