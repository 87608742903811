/**
 * @see https://gist.github.com/necolas/1c494e44e23eb7f8c5864a2fac66299a
 * Touch devices emulate mouse events. This functions makes it possible to know
 * if the current modality supports hover (including for multi-modality
 * devices).
 */
const createHoverMonitor = (): { readonly enabled: boolean } => {
  let isHoverEnabled = false;
  let lastTouchTime = 0;

  const enableHover = (): void => {
    if (isHoverEnabled || Date.now() - lastTouchTime < 500) {
      return;
    }

    isHoverEnabled = true;
  };

  const disableHover = (): void => {
    lastTouchTime = new Date(Date.now()) as unknown as number;
    if (isHoverEnabled) {
      isHoverEnabled = false;
    }
  };

  if (process.browser) {
    document.addEventListener("touchstart", disableHover, true);
    document.addEventListener("mousemove", enableHover, true);
  }

  return {
    get enabled() {
      return isHoverEnabled;
    },
  };
};

export default createHoverMonitor;
