/**
 * Une alternative à throttle qui au lieu de se baser sur un temps, utilise
 *   une requête qui demande au navigateur à quel moment il est opportun de
 *   lancer un traitement d'affichage.
 * En plus de retourner le callback, cette fonction ajoute une fonction
 *   .cancel qui permet d'empêcher le lancement du callack si jamais
 *   le composant a été démonté et que la fonction a été lancée dans le même
 *   laps de temps. A utiliser dans un componentWillUnmount ou hook équivalent.
 * @param {requestCallback} callback
 * @example
 * class MyClass {
 *   constructor() {
 *     this.handleScroll = rafThrottle(this.handleScroll.bind(this)) // this.handleScroll.bind(this) is not required, it depends of your application
 *   }
 * }
 */
const rafThrottle = <T = any>(callback) => {
  let requestAnimationId;

  const func = (thisArg, args) => () => {
    requestAnimationId = null;
    callback.apply(thisArg, args);
  };

  function fn(this: T, ...args) {
    if (requestAnimationId == null) {
      requestAnimationId = requestAnimationFrame(func(this, args));
    }
  }

  fn.cancel = () => {
    cancelAnimationFrame(requestAnimationId);
  };

  return fn;
};

export default rafThrottle;
