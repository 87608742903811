// dependencies
import React, { useMemo } from "react";
import { StyleSheet } from "aphrodite";

// components
import Link from "../Link2";

// constants
import theming from "../../constants/theming";

const { useTheme } = theming;

type PropsType = {
  label: string;
  to: string;
  isActivated: boolean;
  onPress;
};

const MenuDropdownLink: React.FunctionComponent<PropsType> = (props) => {
  const { to, label, isActivated, onPress } = props;

  const theme = useTheme();

  const dynamicStyles = useMemo(
    () =>
      StyleSheet.create({
        link: {
          color: theme.PRIMARY_COLOR,
          backgroundColor: "#ffffff",
          padding: "0.625rem",
          transition:
            "color 160ms linear 0ms, background-color 160ms linear 0ms",
          ":hover": {
            color: theme.SECONDARY_COLOR,
            backgroundColor: "#f8f3ea",
          },
          ":active": {
            color: theme.SECONDARY_COLOR,
            backgroundColor: "#f8f3ea",
          },
        },
        link__typeHighlight: {
          color: "#ffffff",
          backgroundColor: theme.PRIMARY_COLOR,
        },
      }),
    [theme]
  );

  return (
    <Link
      to={to}
      aStyle={[
        dynamicStyles.link,
        isActivated && dynamicStyles.link__typeHighlight,
      ]}
      onClick={onPress}
    >
      {label}
    </Link>
  );
};

export default MenuDropdownLink;
