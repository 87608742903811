// libraries
import { encodeQuery } from "@cloudspire/legacy-shared/src/libraries";

type GenerateUriParam0Type = {
  router?: import("@cloudspire/legacy-shared/src/libraries/Router").Router;
  name?: string;
  route?:
    | import("@cloudspire/legacy-shared/src/libraries/Router").Route
    | import("@cloudspire/legacy-shared/src/libraries/Router").FilledRoute;
  parameters?: { [name: string]: string };
  query?: { [name: string]: string };
  scheme?: string;
  port?: number;
  host?: string;
  fragment?: string;
};

/**
 * @example
 * generateUri({
 *   router: frontRouter,
 *   name: "MyRoute",
 *   parameters: {
 *     lang: "fr",
 *   },
 *   query: { foo: "bar" },
 * });
 *
 * // revient à écrire
 *
 * frontRouter
 *   .findByName("MyRoute")
 *   .toFilled()
 *   .setParameter("lang", "fr"),
 *   .generateUri()
 *   .withQuery(encodeQuery(query))
 *   .toString()
 */
const generateUri = ({
  router,
  route,
  name,
  parameters = {},
  query = {},
  fragment,
  port,
  host,
  scheme,
}: GenerateUriParam0Type) => {
  const tempRoute =
    undefined !== route
      ? route
      : router.findByName(name).toFilled().setParameters(parameters);

  let tempUri = tempRoute.generateUri().withQuery(encodeQuery(query));

  if (undefined !== host) {
    tempUri = tempUri.withHost(host);
  }

  if (undefined !== scheme) {
    tempUri = tempUri.withScheme(scheme);
  }

  if (undefined !== fragment) {
    tempUri = tempUri.withFragment(fragment);
  }

  if (undefined !== port) {
    tempUri = tempUri.withPort(port);
  }

  return tempUri.toString();
};

export default generateUri;
