import React from "react";
import ReactDOM from "react-dom";

type IProps = {
  selector: string;
};

type IState = {};

class Portal extends React.Component<IProps, IState> {
  private element: HTMLElement = null;

  public componentDidMount(): void {
    const { selector } = this.props;

    this.element = document.querySelector(selector);
    this.forceUpdate();
  }

  public render(): JSX.Element {
    if (this.element == null) {
      return null;
    }

    const { children } = this.props;

    return ReactDOM.createPortal(children, this.element);
  }
}

export default Portal;
