import React from "react";
import { Text as TextReactNative, StyleSheet, TextProps } from "react-native";

// theming
import theming from "../../constants/theming";

const { useTheme } = theming;

type PropsType = {} & TextProps;

const getStyles = ({ theme }) =>
  StyleSheet.create({
    text: {
      fontFamily: theme.FONT_FAMILY,
      fontSize: `${theme.FONT_SIZE / 16}em` as any,
      fontWeight: "300",
      color: theme.PRIMARY_COLOR_TEXT,
    },
  });

const Text = React.forwardRef<
  TextReactNative,
  React.PropsWithChildren<PropsType>
>(function Text(props, ref) {
  const { style, ...otherProps } = props;

  const theme = useTheme();

  const styles = getStyles({ theme });

  return (
    <TextReactNative ref={ref} style={[styles.text, style]} {...otherProps} />
  );
});

export default Text;
