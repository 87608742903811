/* Types */

// Ne s'applique que sur les pages du types /page/:slug
export const CONTENT_TYPE_FREE = "fr";
export const CONTENT_TYPE_PAGE = "pa";

// Ne s'applique que sur les pages du types /recherche/:slug
export const CONTENT_TYPE_CATEGORY = "ca";

// Ne s'applique que sur les pages du types /thematiques/:slug
export const CONTENT_TYPE_CAMPAIGN = "cp";

export const CONTENT_TYPES = {
  [CONTENT_TYPE_FREE]: CONTENT_TYPE_FREE,
  [CONTENT_TYPE_PAGE]: CONTENT_TYPE_PAGE,
  [CONTENT_TYPE_CATEGORY]: CONTENT_TYPE_CATEGORY,
  [CONTENT_TYPE_CAMPAIGN]: CONTENT_TYPE_CAMPAIGN,
};

/* Aliases */

export const CONTENT_ALIAS_VALUE_LABEL = "label";
export const CONTENT_ALIAS_VALUE_CGV = "cgu";

export const CONTENT_ALIASES = {
  [CONTENT_ALIAS_VALUE_LABEL]: {
    id: CONTENT_ALIAS_VALUE_LABEL,
  },
  [CONTENT_ALIAS_VALUE_CGV]: {
    id: CONTENT_ALIAS_VALUE_CGV,
  },
};

// Section type

// Mosaïque de page
export const CONTENT_SECTION_TYPE_VALUE_MOSAIC = "mos";
// Résumé de page
export const CONTENT_SECTION_TYPE_VALUE_PAGE = "pag";
// Contenu de campagne
export const CONTENT_SECTION_TYPE_VALUE_CAMPAIGN = "cam";
// Hook
export const CONTENT_SECTION_TYPE_VALUE_HOOK = "hoo";

export const CONTENT_SECTION_TYPES = {
  [CONTENT_SECTION_TYPE_VALUE_MOSAIC]: {
    value: CONTENT_SECTION_TYPE_VALUE_MOSAIC,
    id: CONTENT_SECTION_TYPE_VALUE_MOSAIC,
    useTitle: true,
  },
  [CONTENT_SECTION_TYPE_VALUE_PAGE]: {
    value: CONTENT_SECTION_TYPE_VALUE_PAGE,
    id: CONTENT_SECTION_TYPE_VALUE_PAGE,
    useTitle: false,
  },
  [CONTENT_SECTION_TYPE_VALUE_CAMPAIGN]: {
    value: CONTENT_SECTION_TYPE_VALUE_CAMPAIGN,
    id: CONTENT_SECTION_TYPE_VALUE_CAMPAIGN,
    useTitle: true,
  },
};
export const CONTENT_SECTION_TYPE_LIST = Object.values(CONTENT_SECTION_TYPES);

/* Section page */

// sections de la page d'accueil
export const CONTENT_SECTION_PAGE_VALUE_HOME = "homepage";

export const CONTENT_SECTION_PAGES = {
  [CONTENT_SECTION_PAGE_VALUE_HOME]: CONTENT_SECTION_PAGE_VALUE_HOME,
};

/* States */

export const CONTENT_STATE_VALUE_REGISTERED = "re";
export const CONTENT_STATE_VALUE_ENABLED = "en";
export const CONTENT_STATE_VALUE_DISABLED = "di";
export const CONTENT_STATE_VALUE_TRASHED = "tr";

export const CONTENT_STATES = {
  [CONTENT_STATE_VALUE_REGISTERED]: CONTENT_STATE_VALUE_REGISTERED,
  [CONTENT_STATE_VALUE_ENABLED]: CONTENT_STATE_VALUE_ENABLED,
  [CONTENT_STATE_VALUE_DISABLED]: CONTENT_STATE_VALUE_DISABLED,
  [CONTENT_STATE_VALUE_TRASHED]: CONTENT_STATE_VALUE_TRASHED,
};

export const CONTENT_STATE_LIST = Object.values(CONTENT_STATES);

/* Catégories */

export const CONTENT_CATEGORY_VALUE_DEFAULT = "footer";
export const CONTENT_CATEGORY_VALUE_MENU = "menu";
export const CONTENT_CATEGORY_VALUE_BOTTOM = "bottom";
export const CONTENT_CATEGORY_VALUE_HOME = "home";
export const CONTENT_CATEGORY_VALUE_THEME = "theme";
export const CONTENT_CATEGORY_VALUE_CITY = "city";
export const CONTENT_CATEGORY_VALUE_PROMO = "promo";

/* Common sections */

// Thématique
export const CONTENT_SECTION_VALUE_THEME = "theme";
// Ville
export const CONTENT_SECTION_VALUE_CITY = "city";
// Bons plans
export const CONTENT_SECTION_VALUE_PROMO = "promo";
// Footer
export const CONTENT_SECTION_VALUE_FOOTER = "footer";
