import React from "react";
import { View, StyleSheet, AccessilityRole } from "react-native";

// components
import Text from "../Text";
import UIContainer from "../UIContainer";
import BaseComponent from "../BaseComponent";

// hocs
import withMedia, { WithMediaInjectedPropsType } from "../../hocs/withMedia";

// theming
import theming from "../../constants/theming";

const { useTheme } = theming;

type PropsType = {
  label: string;
  renderSocialList: () => JSX.Element;
} & WithMediaInjectedPropsType;

const getStyles = ({ theme, matches }) =>
  StyleSheet.create({
    view: {
      borderStyle: "solid",
      borderTopColor: theme.SECONDARY_COLOR,
      borderTopWidth: 2,
      paddingTop: "1em",
      paddingBottom: "1em",
    },
    list: {
      width: "100%",
      justifyContent: "flex-end",
      flexDirection: "row",
    },
    wrapper: {
      display: "flex",
      flexDirection: !matches ? "row" : "column",
      alignItems: "center",
      justifyContent: !matches ? "space-between" : "center",
    },
    socialList: {
      marginTop: !matches ? 0 : "1em",
    },
    label: {
      color: theme.PRIMARY_COLOR_TEXT,
      fontWeight: 500,
    },
  });

const SocialBar = (props: PropsType): JSX.Element => {
  const { label, renderSocialList, matches } = props;

  const theme = useTheme();

  const styles = getStyles({ theme, matches });

  return (
    <BaseComponent
      childrenStyle={styles.view}
      accessibilityRole={"section" as AccessilityRole}
    >
      <UIContainer isFullWidth={true}>
        <View style={styles.wrapper}>
          <View>
            <Text style={styles.label}>{label}</Text>
          </View>

          <View style={styles.socialList}>
            <View style={styles.list}>{renderSocialList()}</View>
          </View>
        </View>
      </UIContainer>
    </BaseComponent>
  );
};

export default withMedia({ query: { maxWidth: 690 } })(SocialBar);
