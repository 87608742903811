// dependencies
import React from "react";
import {
  View,
  ImageProps,
  StyleSheet,
  ImageStyle,
  ViewStyle,
  Animated,
  NativeSyntheticEvent,
  ImageLoadEventData,
  ImageErrorEventData,
  StyleProp,
} from "react-native";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";

// components
import FlexEmbed from "../FlexEmbed";

type IProps = {
  ratio?: number;
  accessibilityLabel: string;
  style: StyleProp<ViewStyle>;
  flexEmbedStyle: StyleProp<ViewStyle>;
  renderFallback: () => JSX.Element;
} & ImageProps;

type IState = {
  hasError: boolean;
};

class Image extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private opacity = new Animated.Value(0);
  public state: IState = {
    hasError: false,
  };

  public constructor(props) {
    super(props);

    this.handleLoad = this.handleLoad.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  private getStyles(): StyleSheet.NamedStyles<{
    view: ViewStyle;
    animatedImage: ImageStyle;
    fallbackView: ViewStyle;
  }> {
    const { hasError } = this.state;

    return StyleSheet.create({
      view: {
        position: "relative",
        width: "100%",
        height: "100%",
      },
      animatedImage: {
        width: "100%",
        height: "100%",
        opacity: this.opacity as unknown as number,
      },
      fallbackView: {
        display: hasError ? "flex" : "none",
        ...StyleSheet.absoluteFillObject,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f3f4",
        zIndex: 1,
        opacity: this.opacity as unknown as number,
      },
    });
  }

  private handleLoad(event: NativeSyntheticEvent<ImageLoadEventData>): void {
    const { onLoad } = this.props;

    Animated.timing(this.opacity, {
      toValue: 1,
      duration: 360,
      useNativeDriver: false,
    }).start();

    onLoad(event);
  }

  private handleError(event: NativeSyntheticEvent<ImageErrorEventData>): void {
    const { onError } = this.props;

    this.setState({ hasError: true });
    Animated.timing(this.opacity, {
      toValue: 1,
      duration: 360,
      useNativeDriver: false,
    }).start();

    onError(event);
  }

  public render(): JSX.Element {
    const { ratio, onLoad, style, flexEmbedStyle, renderFallback, ...attrs } =
      this.props;
    const { hasError } = this.state;

    const styles = this.getStyles();

    return (
      <FlexEmbed ratio={ratio} style={flexEmbedStyle}>
        <View style={styles.view}>
          {hasError && (
            <Animated.View style={styles.fallbackView}>
              {renderFallback()}
            </Animated.View>
          )}

          <Animated.Image
            {...attrs}
            onLoad={this.handleLoad}
            onError={this.handleError}
            style={[styles.animatedImage, style]}
          />
        </View>
      </FlexEmbed>
    );
  }
}

Image.defaultProps = {
  ratio: 9 / 16,
  resizeMode: "contain",
  renderFallback() {
    return null;
  },
  onLoad: noop,
  onError: noop,
};

export default Image;
