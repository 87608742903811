// constants
import {
  accountRouter,
  bookingRouter,
  frontRouter,
} from "@cloudspire/legacy-shared/src/constants/router";

// libraries
import Uri from "@cloudspire/legacy-shared/src/libraries/Uri";

// helpers
import channelBuildHostFromConfiguration from "@cloudspire/shared/helpers/channel/buildHostFromConfiguration";

/**
 * Retourne l’ensemble des routers lié au contexte du site.
 */
function appGetRouters({ channelConfiguration, currentWebsiteType }) {
  // Booking
  let bookingHost = undefined;
  let bookingPort = undefined;
  let bookingScheme = undefined;
  if ("booking" !== currentWebsiteType) {
    bookingHost = channelBuildHostFromConfiguration({
      channelConfiguration,
      websiteType: "booking",
    });
    bookingPort = undefined;
    bookingScheme = "https";
  }

  const localBookingRouter = bookingRouter.withUri(
    new Uri(bookingScheme, undefined, bookingHost, bookingPort)
  );

  // Account
  let accountHost = undefined;
  let accountPort = undefined;
  let accountScheme = undefined;
  if ("account" !== currentWebsiteType) {
    accountHost = channelBuildHostFromConfiguration({
      channelConfiguration,
      websiteType: "account",
    });
    accountPort = undefined;
    accountScheme = "https";
  }

  const localAccountRouter = accountRouter.withUri(
    new Uri(accountScheme, undefined, accountHost, accountPort)
  );

  // Front
  let frontHost = undefined;
  let frontPort = undefined;
  let frontScheme = undefined;
  if ("front" !== currentWebsiteType) {
    frontHost = channelBuildHostFromConfiguration({
      channelConfiguration,
      websiteType: "front",
    });
    frontPort = undefined;
    frontScheme = "https";
  }

  const localFrontRouter = frontRouter.withUri(
    new Uri(frontScheme, undefined, frontHost, frontPort)
  );

  let mainRouter;
  switch (currentWebsiteType) {
    case "account": {
      mainRouter = localAccountRouter;
      break;
    }

    case "booking":
      mainRouter = localBookingRouter;
      break;

    case "front":
    default: {
      mainRouter = localFrontRouter;
      break;
    }
  }

  return {
    bookingRouter: localBookingRouter,
    accountRouter: localAccountRouter,
    frontRouter: localFrontRouter,
    mainRouter,
  };
}

export default appGetRouters;
