// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";
import { injectIntl, InjectedIntlProps } from "react-intl";
import {
  IconFacebook,
  IconTwitter,
  IconPinterest,
  IconInstagram,
  IconTumblr,
  IconLinkedin,
  IconViadeo,
  IconYoutube,
} from "@cloudspire/legacy-svg-icon-library";

// components
import Text from "../../Text";
import Link from "../../Link";

// constants
import {
  SOCIALS,
  SOCIAL_VALUE_FACEBOOK,
  SOCIAL_VALUE_TWITTER,
  SOCIAL_VALUE_PINTEREST,
  SOCIAL_VALUE_INSTAGRAM,
  SOCIAL_VALUE_TUMBLR,
  SOCIAL_VALUE_LINKEDIN,
  SOCIAL_VALUE_VIADEO,
  SOCIAL_VALUE_YOUTUBE,
} from "../../../constants/social";

type IProps = {
  social;
  to: string;
} & InjectedIntlProps;

const SocialIcon = (props: IProps): JSX.Element => {
  const { social, intl, to } = props;

  const {
    id: socialName,
    color: socialColor,
    label: socialLabel,
  } = SOCIALS[social];

  const styles = StyleSheet.create({
    view: {
      width: "2.5em",
      height: "2.5em",
    },
    text: {
      fontSize: "1.25em",
      display: "flex",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      borderStyle: "solid",
      borderColor: socialColor,
      color: socialColor,
      lineHeight: 0,
      borderTopWidth: "0.1em" as any,
      borderRightWidth: "0.1em" as any,
      borderBottomWidth: "0.1em" as any,
      borderLeftWidth: "0.1em" as any,
      borderRadius: "50%" as any,
    },
  });

  let Icon: React.ComponentType = () => null;
  switch (socialName) {
    case SOCIAL_VALUE_FACEBOOK: {
      Icon = IconFacebook;
      break;
    }

    case SOCIAL_VALUE_TWITTER: {
      Icon = IconTwitter;
      break;
    }

    case SOCIAL_VALUE_PINTEREST: {
      Icon = IconPinterest;
      break;
    }

    case SOCIAL_VALUE_INSTAGRAM: {
      Icon = IconInstagram;
      break;
    }

    case SOCIAL_VALUE_TUMBLR: {
      Icon = IconTumblr;
      break;
    }

    case SOCIAL_VALUE_LINKEDIN: {
      Icon = IconLinkedin;
      break;
    }

    case SOCIAL_VALUE_VIADEO: {
      Icon = IconViadeo;
      break;
    }

    case SOCIAL_VALUE_YOUTUBE: {
      Icon = IconYoutube;
      break;
    }
  }

  const label = intl.formatMessage(
    { id: "app.component.social.label" },
    { social: socialLabel }
  );

  return (
    <Text>
      <View style={styles.view}>
        <Link target="_blank" to={to} style={styles.text} title={label}>
          <Icon />
        </Link>
      </View>
    </Text>
  );
};

export default injectIntl(SocialIcon);
