import recursiveBracket from "../recursiveBracket";
import flattenDeepPropertyList from "../flattenDeepPropertyList";

/**
 * Transforme un objet en form-data.
 */
const encodeFormData = (obj: object | Array<any>): FormData => {
  const flattenObj = flattenDeepPropertyList(obj);

  const formData = new FormData();

  for (const field of flattenObj) {
    const [fieldNameArr, fieldValue] = field;

    if (undefined !== fieldValue) {
      const fieldName = recursiveBracket(fieldNameArr);

      if ("boolean" === typeof fieldValue) {
        formData.set(fieldName, fieldValue ? "1" : "0");
      } else {
        formData.set(fieldName, fieldValue);
      }
    }
  }

  return formData;
};

export default encodeFormData;
