// normalizers
import parseConfigurationData from "./parseData";

export default function parseConfigurationResponse({ data }) {
  if (!data) {
    return null;
  }

  return parseConfigurationData({
    data: data?.data,
  });
}
