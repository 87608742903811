// constants
import { ROOM_CATEGORIES } from "@cloudspire/legacy-resources/src/constants/room";

// helpers
import contentIsTypeCategory from "../isTypeCategory";

type ContentGetAliasFromSlugForCampaignsParam0Type = {
  slug: string;
  contentList: ReturnType<
    typeof import("@cloudspire/legacy-resources/src/normalizers/configuration").configurationParseData
  >["channel"]["website"]["contents"]["list"];
};

/**
 * Retourne l'alias de la campagne si celui-ci a été modifié dans l'administration.
 * Sinon, retourne la valeur uniquement si elle est présente dans la liste des catégories.
 */
const contentGetAliasFromSlugForCampaigns = ({
  slug,
  contentList,
}: ContentGetAliasFromSlugForCampaignsParam0Type): string => {
  const initialValue = ROOM_CATEGORIES[slug] ? ROOM_CATEGORIES[slug] : null;

  return [...contentList].reduce(
    (category, content, contentIndex, contentList) => {
      if (contentIsTypeCategory({ content }) && slug === content.slug) {
        contentList.splice(1);

        return content.alias;
      }

      return category;
    },
    initialValue
  );
};

export default contentGetAliasFromSlugForCampaigns;
