/**
 * Converti une chaîne de caractère du format "spinal-case" au format "pascal-case".
 * @example convertSpinalCaseToPascalCase("hello-world-foo-bar"); // HelloWorldFooBar
 */
const convertSpinalCaseToPascalCase = (str) =>
  `${str.slice(0, 1).toUpperCase()}${str
    .slice(1)
    .replace(/-([a-z])/g, (_1, letter) => letter.toUpperCase())}`;

export default convertSpinalCaseToPascalCase;
