import isNumber from "../isNumber";
import isEnumerable from "../isEnumerable";

const isEmpty = (obj): boolean => {
  if (
    null == obj ||
    Number.isNaN(obj) ||
    obj === false ||
    (!isNumber(obj) &&
      obj !== true &&
      isEnumerable(obj) &&
      Object.keys(obj).length === 0)
  ) {
    return true;
  }

  return false;
};

export default isEmpty;
