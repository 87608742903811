// dependencies
import React from "react";
import { StyleSheet } from "react-native";

// components
import Link, { IProps as IPropsLink } from "../Link";

type IProps = {
  active: boolean;
} & IPropsLink;

type IState = {};

class Footer extends React.Component<IProps, IState> {
  public render(): JSX.Element {
    const { style, active, isHovered, ...attrs } = this.props;

    const styles = StyleSheet.create({
      link: {
        color: "#ffffff",
      },
      link__hover: {
        textDecorationLine: "underline",
      },
    });

    return (
      <Link {...attrs} style={styles.link} hoverStyle={styles.link__hover} />
    );
  }
}

export default Footer;
