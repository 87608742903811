import { STORAGE_THEME_KEY } from "../../constants/theme";

/**
 * Défini le thème courante (côté client seulement pour le cache).
 */
const setThemeToStorage = ({ realm, theme }) => {
  if (process.browser) {
    window.sessionStorage.setItem(
      `${STORAGE_THEME_KEY}.${realm}`,
      JSON.stringify(theme)
    );
  }
};

export default setThemeToStorage;
