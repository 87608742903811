/**
 * Compare two object key-values to determine if they are equivalent.
 * If objects does not have the same reference, all of their keys (no matter the order) will be compare to other object.
 * @param {any} obj1
 * @param {any} obj2
 * @param {boolean} [strict=true] Define if the key-to-key comparison should be strict
 * @return {boolean}
 * @example
 * isEquivalent({ a: 5 }, { a: 5 }) // true
 * isEquivalent({ a: -5 }, { a: 5 }) // false
 * const a = { foo: "bar" }
 * isEquivalent(a, a) // true
 * const b = a
 * isEquivalent(a, b) // true
 *
 * // with strict parameter
 * isEquivalent({ a: 1 }, { a: true }) // false (default strict=true)
 * isEquivalent({ a: 1 }, { a: true }, false) // true
 */
const isEquivalent = (obj1, obj2, strict = true) => {
  if (obj1 === obj2) {
    return true;
  }

  const obj1Props = Object.getOwnPropertyNames(obj1);
  const obj2Props = Object.getOwnPropertyNames(obj2);

  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (let i = 0; i < obj1Props.length; i++) {
    const propName = obj1Props[i];

    if (strict && obj1[propName] !== obj2[propName]) {
      return false;
    } else if (!strict && obj1[propName] != obj2[propName]) {
      return false;
    }
  }

  return true;
};

export default isEquivalent;
