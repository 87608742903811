// dependencies
import { useState, useMemo, useCallback, useEffect } from "react";
import { Dimensions, ScaledSize } from "react-native";

type UseViewportMediaParam0Type = {
  queries: Array<{
    maxWidth?: number;
    minWidth?: number;
    minHeight?: number;
    maxHeight?: number;
  }>;
  defaultMatches?: boolean;
};

const isMatches = ({ queries, width, height }) => {
  return [...queries].reduce((matches, query, index, queries) => {
    if (!matches) {
      queries.splice(1);
      return false;
    }

    if (undefined !== query.minHeight && !(height >= query.minHeight)) {
      return false;
    }

    if (undefined !== query.maxHeight && !(height <= query.maxHeight)) {
      return false;
    }

    if (undefined !== query.minWidth && !(width >= query.minWidth)) {
      return false;
    }

    if (undefined !== query.maxWidth && !(width <= query.maxWidth)) {
      return false;
    }

    return true;
  }, true as boolean);
};

/**
 * Hook qui permet de retouner un état `matches` à `true` si
 *   l'élément correspond à la contrainte de taille, `false` sinon.
 * Attention, chaque `query` prend des valeurs inclusives.
 */
const useViewportMedia = ({
  queries,
  defaultMatches = true,
}: UseViewportMediaParam0Type) => {
  const [matches, setMatches] = useState<boolean>(defaultMatches);

  const handleChangeDimensions = useCallback<
    ({ window }: { window: ScaledSize; screen: ScaledSize }) => void
  >(
    ({ window }) => {
      const { width, height } = window;

      const newMatches = isMatches({ width, height, queries });

      if (matches !== newMatches) {
        setMatches(newMatches);
      }
    },
    [queries, matches]
  );

  useEffect(() => {
    Dimensions.addEventListener("change", handleChangeDimensions);

    return () => {
      return Dimensions.removeEventListener("change", handleChangeDimensions);
    };
  }, [handleChangeDimensions]);

  useEffect(() => {
    const { width, height } = Dimensions.get("window");

    const newMatches = isMatches({ width, height, queries });

    if (matches !== newMatches) {
      setMatches(newMatches);
    }
  }, []);

  return useMemo(
    () => ({
      matches,
    }),
    [matches]
  );
};

export default useViewportMedia;
