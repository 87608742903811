// dependencies
import React from "react";
import { StyleSheet, View, StyleProp, ViewStyle } from "react-native";

// constants
import theming from "../../constants/theming";

// libraries
import { emToPx } from "../../libraries";

const { useTheme } = theming;

const styles = StyleSheet.create({
  separator: {
    borderStyle: "solid",
    borderBottomWidth: emToPx(0.0625),
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
  },
});

type PropsType = {
  color?: string;
  style?: StyleProp<ViewStyle>;
};

const Separator: React.FunctionComponent<PropsType> = (props) => {
  const { style } = props;

  const theme = useTheme();

  const borderColor = props.color ? props.color : theme.PRIMARY_COLOR;

  return (
    <View
      style={[
        styles.separator,
        style,
        {
          borderColor,
        },
      ]}
    />
  );
};

export default Separator;
