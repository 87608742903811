// deps
import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

// constants
import {
  GOOGLE_TRACKING_COOKIE_NAME,
  GOOGLE_TRACKING_SERVICE_LIST,
  GOOGLE_TRACKING_SERVICE_VALUE_GA,
  GOOGLE_TRACKING_SERVICE_VALUE_GTM,
  GOOGLE_TRACKING_STATE_LIST,
  GOOGLE_TRACKING_STATE_VALUE_ACCEPTED,
  GOOGLE_TRACKING_STATE_VALUE_PENDING,
  GOOGLE_TRACKING_STATE_VALUE_REFUSED,
} from "../constants/googleTracking";

// libraries
import {
  getGoogleAnalyticsScript,
  getGoogleTagManagerScript,
  trackPageView,
} from "../libraries/utils/googleTracking";

// contexts
import { useConfiguration } from "../contexts/configuration";
import { useCookies } from "../contexts/cookies";

const GoogleTrackingContext = createContext();

export function GoogleTrackingProvider(props) {
  const { trackingService, trackingKey, initialState, children } = props;

  const [state, setState] = useState(initialState);

  const configuration = useConfiguration();

  /**
   * Est-ce qu’un service de tracking est configuré correctement.
   */
  const hasService =
    GOOGLE_TRACKING_SERVICE_LIST.some(function (tempTrackingService) {
      return trackingService === tempTrackingService.id;
    }) && trackingKey;

  /**
   * Est-ce que l’utilisateur autorise le tracking.
   */
  const canTrack =
    hasService &&
    (!configuration.channel.website.useSystemRgpd ||
      state === GOOGLE_TRACKING_STATE_VALUE_ACCEPTED);

  const { events: routerEvents } = useRouter();

  useEffect(
    function () {
      if (canTrack) {
        const handleRouteChangeComplete = function () {
          trackPageView(
            window.location.pathname + window.location.search,
            trackingKey
          );
        };
        routerEvents.on("routeChangeComplete", handleRouteChangeComplete);
        return function () {
          routerEvents.off("routeChangeComplete", handleRouteChangeComplete);
        };
      }
    },
    [canTrack, trackingKey, routerEvents]
  );

  const { setCookie } = useCookies();

  return (
    <GoogleTrackingContext.Provider
      value={useMemo(
        function () {
          return {
            state,
            renderBanner:
              configuration.channel.website.useSystemRgpd &&
              hasService &&
              GOOGLE_TRACKING_STATE_VALUE_PENDING === state,
            acceptCookies() {
              setState(GOOGLE_TRACKING_STATE_VALUE_ACCEPTED);

              setCookie({
                name: GOOGLE_TRACKING_COOKIE_NAME,
                value: "1",
                domain: window.location.hostname.includes(
                  configuration.channel.website.domain
                )
                  ? `.${configuration.channel.website.domain}`
                  : undefined,
                sameSite: "lax",
              });
            },
            refuseCookies() {
              setState(GOOGLE_TRACKING_STATE_VALUE_REFUSED);

              setCookie({
                name: GOOGLE_TRACKING_COOKIE_NAME,
                value: "0",
                domain: window.location.hostname.includes(
                  configuration.channel.website.domain
                )
                  ? `.${configuration.channel.website.domain}`
                  : undefined,
                sameSite: "lax",
              });
            },
          };
        },
        [
          state,
          configuration.channel.website.useSystemRgpd,
          configuration.channel.website.domain,
          hasService,
          setCookie,
        ]
      )}
    >
      {canTrack && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${trackingKey}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
      )}

      {canTrack &&
        GOOGLE_TRACKING_SERVICE_VALUE_GA === trackingService &&
        getGoogleAnalyticsScript(trackingKey)}

      {canTrack &&
        GOOGLE_TRACKING_SERVICE_VALUE_GTM === trackingService &&
        getGoogleTagManagerScript(trackingKey)}

      {children}
    </GoogleTrackingContext.Provider>
  );
}

GoogleTrackingProvider.propTypes = {
  initialState: PropTypes.oneOf(
    GOOGLE_TRACKING_STATE_LIST.map(function (googleTrackingState) {
      return googleTrackingState.id;
    })
  ),
  trackingKey: PropTypes.string,
  trackingService: PropTypes.oneOf(
    GOOGLE_TRACKING_SERVICE_LIST.map(function (gogoleTrackingService) {
      return gogoleTrackingService.id;
    })
  ),
  children: PropTypes.node,
};

export function useGoogleTracking() {
  return useContext(GoogleTrackingContext);
}
