/**
 * Retourne les attributs OpenGraph et Twitter Cards.
 */
export default function nextGetSocialMetaTags({ channelConfiguration, uri }) {
  /** Titre */
  let title;
  if (channelConfiguration.channel.website.seo?.home?.title) {
    title = channelConfiguration.channel.website.seo.home.title;
  } else if (channelConfiguration.channel.website.seo?.default?.title) {
    title = channelConfiguration.channel.website.seo.default.title;
  } else {
    title = channelConfiguration.channel.title;
  }

  /** Description */
  let description;
  if (channelConfiguration.channel.website.seo?.home?.desc) {
    description = channelConfiguration.channel.website.seo.home.desc;
  } else if (channelConfiguration.channel.website.seo?.default?.desc) {
    description = channelConfiguration.channel.website.seo.default.desc;
  }

  /** Image de prévisualisation */
  let image;
  if (channelConfiguration.channel.medias?.avatar) {
    image = channelConfiguration.channel.medias.avatar;
  }

  /** Identifiant Twitter */
  let twitterCreator;
  const twitterObjectConfiguration = channelConfiguration.channel.socials.tw;
  if (twitterObjectConfiguration) {
    const twitterAccount = twitterObjectConfiguration.url.match(
      /^https:\/\/twitter\.com\/(.*)$/
    );
    if (twitterAccount) {
      twitterCreator = twitterAccount[1];
    }
  }

  return {
    title,
    description,
    image,
    url: uri,
    ogType: "website",
    ogSiteName: channelConfiguration.channel.title,
    twitterCard: "summary",
    twitterCreator,
  };
}
