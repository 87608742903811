import Uri from "../Uri";
import FilledRoute from "./filled-route";
import AbstractRouter from "./abstract-router";

import setPathFor from "./prototypes/setPathFor";

class AbstractRoute {
  protected readonly methods: (
    | "GET"
    | "POST"
    | "PUT"
    | "PATCH"
    | "DELETE"
    | "HEAD"
    | "CONNECT"
    | "OPTIONS"
    | "TRACE"
  )[];

  protected readonly path: string;

  protected overloadedPaths: {
    constrainName: string;
    paths: { [constrainValue: string]: string };
  } = { constrainName: null, paths: {} };

  protected name: string = null;

  protected page: string = null;

  protected constrains: { [name: string]: RegExp } = {};

  protected ancestor: (router: AbstractRouter) => AbstractRoute = () => null;

  protected uri: Uri = null;

  protected router: AbstractRouter = null;

  public constructor(
    methods: (
      | "GET"
      | "POST"
      | "PUT"
      | "PATCH"
      | "DELETE"
      | "HEAD"
      | "CONNECT"
      | "OPTIONS"
      | "TRACE"
    )[],
    path: string
  ) {
    this.methods = methods;
    this.path = path;
  }

  public setRouter(router: AbstractRouter): this {
    this.router = router;

    return this;
  }

  public clone() {
    const newRoute = new (this.constructor as any)(
      this.getMethods(),
      this.getPath()
    );

    newRoute.constrains = { ...this.constrains };
    newRoute.ancestor = this.ancestor;
    newRoute.overloadedPaths = { ...this.overloadedPaths };
    newRoute.page = this.page;
    newRoute.name = this.name;
    newRoute.uri = this.uri;
    newRoute.router = this.router;

    return newRoute;
  }

  public setUri(uri: Uri): this {
    this.uri = uri;

    return this;
  }

  public getUri(): Uri {
    return this.uri;
  }

  public setConstrain(name, constrain: RegExp): this {
    this.constrains[name] = constrain;

    return this;
  }

  public setConstrains(constrains): this {
    this.constrains = constrains;

    return this;
  }

  public getConstrains() {
    return this.constrains;
  }

  public getMethods(): (
    | "GET"
    | "POST"
    | "PUT"
    | "PATCH"
    | "DELETE"
    | "HEAD"
    | "CONNECT"
    | "OPTIONS"
    | "TRACE"
  )[] {
    return this.methods;
  }

  public getPath(): string {
    return this.path;
  }

  public setName(name: string): this {
    this.name = name;

    return this;
  }

  public getName(): string {
    return this.name;
  }

  public hasName(): boolean {
    return null !== this.name;
  }

  public setPathFor(name: string, value: string, path: string): this {
    return setPathFor.call(this, name, value, path);
  }

  public setOverloadedPaths(overloadedPaths: {
    constrainName: string;
    paths: { [constrainName: string]: string };
  }): this {
    this.overloadedPaths = overloadedPaths;

    return this;
  }

  public getOverloadedPaths(): {
    constrainName: string;
    paths: { [constrainValue: string]: string };
  } {
    return this.overloadedPaths;
  }

  public generateUri(): Uri {
    throw new Error("Cannot use with Route.");
  }

  public setPage(page: string): this {
    this.page = page;

    return this;
  }

  public getPage(): string {
    return this.page;
  }

  public hasPage(): boolean {
    return null !== this.page;
  }

  public setAncestor(ancestor: (router: AbstractRouter) => AbstractRoute) {
    this.ancestor = ancestor;
  }

  public getAncestor(): (router: AbstractRouter) => AbstractRoute {
    return this.ancestor;
  }

  public getAncestors(): AbstractRoute[] {
    const ancestors: AbstractRoute[] = [this];

    const ancestor = this.ancestor(this.router);

    if (null !== ancestor) {
      ancestors.unshift(...ancestor.getAncestors());
    }

    return ancestors;
  }

  public getAlternates(): FilledRoute[] {
    const alternates = [];

    const overloadedPaths = this.getOverloadedPaths();

    for (const value of Object.keys(overloadedPaths.paths)) {
      const route = FilledRoute.fromRoute(this);

      route.setParameter(overloadedPaths.constrainName, value);

      alternates.push(route);
    }

    return alternates;
  }

  public match(uri: Uri): FilledRoute {
    const constrains = this.getConstrains();
    const uriPath = uri.getPath();

    const overloadedPaths = this.getOverloadedPaths();
    const overloadedConstrainName = overloadedPaths.constrainName;

    {
      for (const overloadedValue of Object.keys(overloadedPaths.paths)) {
        const overloadedPath = overloadedPaths.paths[overloadedValue];

        const parameterNameList = [];

        const regExpPath = new RegExp(
          `^${overloadedPath.replace(
            /([^\\\\]*?):([A-z][A-z0-9-]*)/g,
            (_1, fragment1, constrainName) => {
              parameterNameList.push(constrainName);

              if (overloadedConstrainName === constrainName) {
                return `${fragment1}(${overloadedValue})`;
              }

              if (constrainName in constrains) {
                return `${fragment1}(${constrains[constrainName].source})`;
              }

              return `${fragment1}`;
            }
          )}$`
        );

        const match = uriPath.match(regExpPath);

        if (null !== match) {
          const filledRoute = FilledRoute.fromRoute(this);

          let i = 0;
          for (const parameterName of parameterNameList) {
            filledRoute.setParameter(parameterName, match[i + 1]);

            i += 1;
          }

          filledRoute.setFoundByOverloadedPath(true);
          filledRoute.setUri(uri);

          return filledRoute;
        }
      }
    }

    {
      const path = this.getPath();

      const parameterNameList = [];

      const regExpPath = new RegExp(
        `^${path.replace(
          /([^\\\\]*?):([A-z][A-z0-9-]*)/g,
          (_1, fragment1, constrainName) => {
            parameterNameList.push(constrainName);

            if (constrainName in constrains) {
              return `${fragment1}(${constrains[constrainName].source})`;
            }

            return `${fragment1}`;
          }
        )}$`
      );

      const match = uriPath.match(regExpPath);

      if (null !== match) {
        const filledRoute = FilledRoute.fromRoute(this);

        let i = 0;
        for (const parameterName of parameterNameList) {
          if (
            parameterName === overloadedConstrainName &&
            Object.keys(overloadedPaths.paths).includes(match[i + 1])
          ) {
            return null;
          }

          filledRoute.setParameter(parameterName, match[i + 1]);

          i += 1;
        }

        filledRoute.setUri(uri);

        return filledRoute;
      }
    }

    return null;
  }
}

export default AbstractRoute;
