// libraries
import { Router } from "../../libraries/Router";

const bookingRouter = new Router();

const ROOM_REFERENCE_REGEX = /[A-z0-9]+/;
const BOOKING_REFERENCE_REGEX = /[A-z0-9]+/;

bookingRouter.get("/pdf/room-booklet").setName("Pdf.Action.RoomBooklet");

bookingRouter.group("/:lang", (bookingRouter) => {
  bookingRouter.get("/_error").setPage("notFound").setName("App.NotFound");

  bookingRouter.setConstrain("lang", /[a-z]{2,}/);
  bookingRouter.setPathFor("lang", "fr", "/:lang");
  bookingRouter.setPathFor("lang", "en", "/:lang");

  bookingRouter.get("").setPage("home").setName("App.Action.Booking.Home");

  bookingRouter
    .get("/reservation")
    .setPage("booking")
    .setName("App.Action.Booking.Create");

  bookingRouter
    .get("/reservation/:roomReference")
    .setPage("booking")
    .setName("App.Action.Booking.CreateLegacy")
    .setConstrain("roomReference", ROOM_REFERENCE_REGEX);

  bookingRouter.group("/paiement", (bookingRouter) => {
    bookingRouter
      .get("/choix")
      .setPage("payment-choice")
      .setName("App.Action.Payment.Choice");

    bookingRouter
      .get("/choix/:bookingReference")
      .setPage("payment-choice")
      .setName("App.Action.Payment.ChoiceLegacy")
      .setConstrain("bookingReference", BOOKING_REFERENCE_REGEX);

    bookingRouter
      .get("/statut")
      .setPage("payment-status")
      .setName("App.Action.Payment.Status");
  });
});

export default bookingRouter;
