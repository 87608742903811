import React, { useContext } from "react";

const rowContext = React.createContext<{
  verticalGutter?: number;
  horizontalGutter?: number;
  type: "cell" | "inline" | "column";
  maxColumns?: number;
}>(null as any);

const Provider = rowContext.Provider;

export const RowContextProvider = Provider;

export const useRowContext = () => {
  return useContext(rowContext);
};
