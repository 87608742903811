import hasOwnProperty from "../hasOwnProperty";

/**
 * Format an object issued from recursiveUnbracket to
 *   bracket-style string parameter.
 */
const recursiveBracket = (values: unknown[]) => {
  let output = "";

  for (let i = 0; i < values.length; i += 1) {
    if (hasOwnProperty(values, i)) {
      const value = values[i];

      if (i > 0) {
        output += `[${value}]`;
      } else {
        output += value;
      }
    }
  }

  return output;
};

export default recursiveBracket;
