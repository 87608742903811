export const METHOD_VALUE_GET = "GET";
export const METHOD_VALUE_POST = "POST";
export const METHOD_VALUE_PUT = "PUT";
export const METHOD_VALUE_PATCH = "PATCH";
export const METHOD_VALUE_DELETE = "DELETE";
export const METHOD_VALUE_HEAD = "HEAD";
export const METHOD_VALUE_CONNECT = "CONNECT";
export const METHOD_VALUE_OPTIONS = "OPTIONS";
export const METHOD_VALUE_TRACE = "TRACE";

export const METHOD_LIST = [
  METHOD_VALUE_GET,
  METHOD_VALUE_POST,
  METHOD_VALUE_PUT,
  METHOD_VALUE_PATCH,
  METHOD_VALUE_DELETE,
  METHOD_VALUE_HEAD,
  METHOD_VALUE_CONNECT,
  METHOD_VALUE_OPTIONS,
  METHOD_VALUE_TRACE,
];
