/**
 * Converti une valeur en em to pixel.
 * En version web, cette méthode laisse l'unité des em car elle est supportée par les navigateurs.
 * En version native, cette méthode multiple la valeur par 16.
 */
const emToPx = (em: number): number => {
  return `${em}rem` as unknown as number;
};

export default emToPx;
