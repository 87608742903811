import cookie from "cookie";

type GetCookieParam0Type = {
  req?;
  rawCookies?;
};

/**
 * Récupère un cookie (côté client et côté serveur).
 */
const getCookies = (
  { req, rawCookies }: GetCookieParam0Type = {} as GetCookieParam0Type
) => {
  if (!process.browser && undefined !== rawCookies) {
    return rawCookies;
  }

  return cookie.parse(
    process.browser ? window.document.cookie : req.headers.cookie || ""
  );
};

export default getCookies;
