import immutable from "../utils/immutable";
import parseQuery from "../utils/parseQuery";

type UserInfoType = {
  user: string;
  password?: string;
};

class Uri {
  /**
   * Represent the scheme.
   * @see https://tools.ietf.org/html/rfc3986#section-3.1
   */
  private scheme: string = null;

  /**
   * Represent the user information (user and password)
   * @see https://tools.ietf.org/html/rfc3986#section-3.2.1
   */
  private userInfo: string;

  /**
   * Represent the host
   * @see https://tools.ietf.org/html/rfc3986#section-3.2.2
   */
  private host: string;

  /**
   * Represent the port
   * @see https://tools.ietf.org/html/rfc3986#section-3.2.3
   */
  private port: number;

  /**
   * Represent the path
   * @see https://tools.ietf.org/html/rfc3986#section-3.3
   */
  private path: string = null;

  /**
   * Represent the query
   * @see https://tools.ietf.org/html/rfc3986#section-3.4
   */
  private query: string = null;

  /**
   * Represent the query parsed.
   */
  private parsedQuery: { [name: string]: string } = {};

  /**
   * Represent the fragment
   * @see https://tools.ietf.org/html/rfc3986#section-3.5
   */
  private fragment: string = null;

  public constructor(
    scheme: string = null,
    userInfo: UserInfoType | string = null,
    host: string = null,
    port: number = null,
    path: string = null,
    query: string = null,
    fragment: string = null
  ) {
    Object.defineProperty(this, "withScheme", {
      value: immutable(this.withScheme, this),
    });
    Object.defineProperty(this, "withHost", {
      value: immutable(this.withHost, this),
    });
    Object.defineProperty(this, "withPath", {
      value: immutable(this.withPath, this),
    });
    Object.defineProperty(this, "withPort", {
      value: immutable(this.withPort, this),
    });
    Object.defineProperty(this, "withQuery", {
      value: immutable(this.withQuery, this),
    });
    Object.defineProperty(this, "withFragment", {
      value: immutable(this.withFragment, this),
    });
    Object.defineProperty(this, "withUserInfo", {
      value: immutable(this.withUserInfo, this),
    });

    this.setScheme(scheme);
    this.setHost(host);
    this.setPath(path);
    this.setPort(port);
    this.setQuery(query);
    this.setFragment(fragment);
    this.setUserInfo(userInfo);
  }

  private setFragment(fragment: string = null): this {
    if ("" !== fragment) {
      this.fragment = fragment;
    }

    return this;
  }

  public withFragment(fragment: string): this {
    return this.setFragment(fragment);
  }

  public getFragment(): string {
    return this.fragment;
  }

  private setHost(host: string = null): this {
    this.host = null !== host ? host.toLowerCase() : null;

    return this;
  }

  public withHost(host: string): this {
    return this.setHost(host);
  }

  public getHost(): string {
    return this.host;
  }

  private setPath(path: string): this {
    this.path = path;

    return this;
  }

  public withPath(path: string): this {
    return this.setPath(path);
  }

  public getPath(): string {
    return this.path;
  }

  private setPort(port: number): this {
    this.port = port;

    return this;
  }

  public withPort(port: number): this {
    return this.setPort(port);
  }

  public getPort(): number {
    return this.port;
  }

  private setQuery(query: string = null): this {
    if (query !== "") {
      this.query = query;

      if ("string" === typeof query) {
        this.parsedQuery = parseQuery(query);
      }
    }

    return this;
  }

  public withQuery(query: string): this {
    return this.setQuery(query);
  }

  public getQuery(): string {
    return this.query;
  }

  public getParsedQuery(): { [name: string]: string } {
    return this.parsedQuery;
  }

  private setScheme(scheme: string): this {
    this.scheme = scheme;

    return this;
  }

  public withScheme(scheme: string): this {
    return this.setScheme(scheme);
  }

  public getScheme(): string {
    return this.scheme;
  }

  private setUserInfo(userInfo: UserInfoType | string): this {
    let user = "";
    let password = "";

    if (null !== userInfo) {
      if (typeof userInfo === "object") {
        ({ user = "", password = "" } = userInfo);
      } else if (typeof userInfo === "string") {
        [user, password] = userInfo.split(":");
      }

      this.userInfo =
        user === "" ? "" : `${user}${password !== "" ? `:${password}` : ""}`;
    } else {
      this.userInfo = null;
    }

    return this;
  }

  public withUserInfo(userInfo: UserInfoType | string): this {
    return this.setUserInfo(userInfo);
  }

  public getUserInfo(): string {
    return this.userInfo;
  }

  public getAuthority(): string {
    let authority = "";
    const host = this.getHost();
    const userInfo: string = this.getUserInfo();
    const port: number = this.getPort();

    if (null !== host) {
      authority += host;
    }

    if (userInfo !== null && userInfo !== "") {
      authority = `${userInfo}@${authority}`;
    }

    if (port !== null) {
      authority += `:${port}`;
    }

    return authority;
  }

  public toString(): string {
    let uri = "";
    const scheme: string = this.getScheme();
    const authority: string = this.getAuthority();
    const path: string = this.getPath();
    const query: string = this.getQuery();
    const fragment: string = this.getFragment();

    if (null !== scheme) {
      uri += `${scheme}:`;

      if (typeof authority === "string" && authority !== "") {
        // Append "//" only if authority exists
        // @see http://tools.ietf.org/html/rfc3986#section-3
        uri += `//${authority}`;
      }
    }

    if (null !== path) {
      uri += path;
    }

    if (null !== query) {
      uri += `?${query}`;
    }

    if (null !== fragment) {
      uri += `#${fragment}`;
    }

    return uri;
  }
}

export default Uri;
