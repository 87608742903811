// dependencies
import React from "react";
import { StyleSheet, css } from "aphrodite";

// hocs
import useViewportMedia from "@cloudspire/legacy-resources/src/hocs/useViewportMedia";

const styles = StyleSheet.create({
  uIContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    marginLeft: "auto",
    width: "73.75rem",
    maxWidth: "100%",
  },
  uIContainer__plain: {
    maxWidth: "120rem",
    width: "100%",
    paddingTop: 0,
    paddingRight: "5em",
    paddingBottom: 0,
    paddingLeft: "5em",
  },
  uIContainer__responsive: {
    width: "100%",
    padding: "0 .625em",
    paddingTop: 0,
    paddingRight: "0.625em",
    paddingBottom: 0,
    paddingLeft: "0.625em",
  },
  uIContainer__fullView: {
    maxWidth: "120rem",
    width: "100%",
    paddingTop: 0,
    paddingRight: "5em",
    paddingBottom: 0,
    paddingLeft: "5em",
  },
});

type PropsType = {
  fullWidth?: boolean;
  aStyle?;
};

const UIContainer: React.FunctionComponent<PropsType> = (props) => {
  const { children, fullWidth = false, aStyle, ...otherProps } = props;

  const { matches } = useViewportMedia({ queries: [{ minWidth: 1220 }] });

  return (
    <div
      {...otherProps}
      className={css([
        styles.uIContainer,
        fullWidth &&
          (matches
            ? styles.uIContainer__plain
            : styles.uIContainer__responsive),
        aStyle,
      ])}
    >
      {children}
    </div>
  );
};

export default UIContainer;
