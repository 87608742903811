// constants
import {
  METHOD_VALUE_GET,
  METHOD_VALUE_HEAD,
} from "@cloudspire/legacy-shared/src/constants/methods";

// libraries
import { encodeFormData } from "@cloudspire/legacy-shared/src/libraries";

/**
 * Crée une fonction qui va associer un contexte à la fonction de requêtage.
 * @param {object} context
 * @param {string} context.channelId
 * @param {string} context.token
 */
export function buildFetcher(context) {
  return function (url, init = {}) {
    const { method, mode = "cors", body, headers = {}, type } = init;

    const newHeaders = new Headers(headers);
    let newBody = body;

    if ([METHOD_VALUE_GET, METHOD_VALUE_HEAD].includes(method)) {
      newBody = undefined;
    } else {
      switch (type) {
        case "json": {
          if (!newHeaders.has("Content-Type")) {
            newHeaders.append("Content-Type", "application/json");
          }

          if ("object" === typeof body && null !== body) {
            newBody = JSON.stringify(body);
          }

          break;
        }

        case "formData": {
          if ("object" === typeof body && null !== body) {
            newBody = encodeFormData(body);
          }

          break;
        }
      }
    }

    if (context) {
      const { token, channelId } = context;

      if (null !== channelId) {
        newHeaders.append("Channel", channelId);
      }

      if (token) {
        newHeaders.append("Authorization", `Bearer ${token}`);
      }
    }

    return fetch(url, {
      method,
      mode,
      body: newBody,
      headers: newHeaders,
    }).then((response) => {
      return Promise.resolve()
        .then(() => {
          if ("application/json" === response.headers.get("content-type")) {
            return response.json();
          }

          return response.text();
        })
        .then(async (body) => {
          if (undefined !== context && 401 === response.status) {
            // Si le contexte est défini et que le code de retour est 401,
            //   on appelle un callback.

            const { onUnauthorized } = context;

            await onUnauthorized?.();
          }

          return response.ok
            ? Promise.resolve({ body: body, status: response.status })
            : Promise.reject({ body: body, status: response.status });
        });
    });
  };
}

export async function fetchFallback({ key, fetcher, normalizer }) {
  const rawData = await fetcher(key);

  return [{ [key]: rawData }, normalizer({ data: rawData.body }), rawData];
}

export async function rawFetch({ key, fetcher, normalizer }) {
  const rawData = await fetcher(key);

  return normalizer({ data: rawData.body });
}
