// dependencies
import React from "react";
import { View, Dimensions, ScaledSize, LayoutChangeEvent } from "react-native";

// libraries
import { matchQuery, rafThrottle } from "../../libraries";
import { noop } from "@cloudspire/legacy-shared/src/libraries";

export type IQuery = {
  minWidth?: string | number;
  maxWidth?: string | number;
  minHeight?: string | number;
  maxHeight?: string | number;
};

type IProps = {
  breakpointType?: "viewport" | "element";
  query: IQuery;
  onChange: (matches: boolean) => void;
  render: () => JSX.Element;
  children: (matches: boolean) => JSX.Element;
};

type IState = {
  matches: boolean;
};

class Media extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      matches: matchQuery({
        query: props.query,
        width: undefined,
        height: undefined,
      }),
    };

    this.handleChangeDimensions = rafThrottle(
      this.handleChangeDimensions.bind(this)
    );

    this.handleLayout = rafThrottle(this.handleLayout.bind(this));
    this.computeMatch = this.computeMatch.bind(this);
  }

  public componentDidMount(): void {
    const { breakpointType } = this.props;

    if (breakpointType === "viewport") {
      this.initChangeDimensions({ update: true });
    }
  }

  public componentDidUpdate(prevProps: IProps): void {
    if (prevProps.breakpointType !== this.props.breakpointType) {
      if (this.props.breakpointType === "element") {
        this.terminateChangeDimensions();
      } else {
        this.initChangeDimensions({ update: true });
      }
    }
  }

  public componentWillUnmount(): void {
    this.terminateChangeDimensions();

    /**
     * En plus de retourner la fonction, rafThrottle met à disposition une
     *   fonction .cancel sur l'objet retourné afin d'empêcher le lancement
     *   du callback. Cette méthode est à appeler lors du démontage
     *   du composant.
     */
    (this.handleLayout as any).cancel();
  }

  private initChangeDimensions({ update = false } = {}): void {
    Dimensions.addEventListener("change", this.handleChangeDimensions);

    if (update) {
      const { width, height } = Dimensions.get("window");

      this.computeMatch({ width, height });
    }
  }

  private terminateChangeDimensions(): void {
    Dimensions.removeEventListener("change", this.handleChangeDimensions);

    (this.handleChangeDimensions as any).cancel();
  }

  private computeMatch({ width, height }): void {
    const { query, onChange } = this.props;
    const { matches } = this.state;

    const localMatches = matchQuery({ query, width, height });

    if (matches !== localMatches) {
      onChange(localMatches);
      this.setState({ matches: localMatches });
    }
  }

  private handleChangeDimensions({
    window,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }): void {
    const { width, height } = window;

    this.computeMatch({ width, height });
  }

  private handleLayout(event: LayoutChangeEvent): void {
    const { width, height } = event.nativeEvent.layout;

    this.computeMatch({ width, height });
  }

  public renderChildren() {
    const { render, children } = this.props;
    const { matches } = this.state;

    if ("function" === typeof render && matches) {
      return render();
    }

    if ("function" === typeof children) {
      return children(matches);
    }

    return null;
  }

  public render() {
    const { breakpointType } = this.props;

    return (
      <View
        {...(breakpointType === "element" && { onLayout: this.handleLayout })}
      >
        {this.renderChildren()}
      </View>
    );
  }
}

Media.defaultProps = {
  breakpointType: "viewport",
  onChange: noop,
};

export default Media;
