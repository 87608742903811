import isNumber from "../isNumber";
import isFunction from "../isFunction";

/**
 * Returns if the value is enumerable or not.
 * @param val Any value.
 */
const isEnumerable = (val) => {
  if (isNumber(val, true) || "boolean" === typeof val || isFunction(val)) {
    return false;
  }

  try {
    Object.keys(val);

    return true;
  } catch (_) {
    return false;
  }
};

export default isEnumerable;
