// dependencies
import React, { useCallback, useContext, useState } from "react";

// api
import { getCookies, setCookie } from "../api/cookies";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";

const cookies = React.createContext<{
  cookies: { [name: string]: string };
  setCookie;
}>({ cookies: {}, setCookie: noop });

type ProviderPropsType = {
  initialCookies: { [name: string]: string };
};

export const CookiesProvider: React.FunctionComponent<ProviderPropsType> = (
  props
) => {
  const { initialCookies, ...otherProps } = props;
  const [value, setValue] = useState(initialCookies);

  const Provider = cookies.Provider;

  const handleSetCookie = useCallback(
    ({
      name,
      value,
      expires,
      maxAge,
      domain,
      secure = false,
      httpOnly = false,
      path = "/",
    }: Parameters<typeof setCookie>[0]) => {
      setCookie({
        name,
        value,
        expires,
        maxAge,
        secure,
        domain,
        httpOnly,
        path,
      });
      setValue(getCookies());
    },
    []
  );

  return (
    <Provider
      {...otherProps}
      value={{ cookies: value, setCookie: handleSetCookie }}
    />
  );
};

export const CookiesConsumer = cookies.Consumer;

export const useCookies = () => {
  return useContext(cookies);
};

export default cookies;
