// dependencies
import React from "react";
import { StyleSheet } from "react-native";

// libraries
import { emToPx } from "../../libraries";

// components
import Text from "../Text";
import Link from "../Link";

// hocs
import useHover from "../../hocs/useHover";

type PropsType = {
  to: string;
  linkProps?: any;
};

const styles = StyleSheet.create({
  linkText: {
    fontSize: emToPx(0.875),
  },
  linkText__hovered: {
    textDecorationLine: "underline",
  },
});

const ClassicLink: React.FunctionComponent<PropsType> = (props) => {
  const { to, children, linkProps } = props;
  const { hovered, onMouseEnter, onMouseLeave } = useHover();

  return (
    <Link {...linkProps} to={to}>
      <Text
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={[styles.linkText, hovered && styles.linkText__hovered]}
      >
        {children}
      </Text>
    </Link>
  );
};

export default ClassicLink;
