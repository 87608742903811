// constants
import browser from "../../constants/browser";

/**
 * Retourne le nom d’hôte de la requête.
 * @param {object} param0
 * @param {object} param0.ctx
 * @return {string}
 */
export default function nextGetHost({ req }) {
  if (browser) {
    return window.location.hostname;
  }
  // Retourne le nom d’hôte de la requête en fonction de l’en-tête `host` en supprimant l’éventuel port.
  return req.headers.host.split(":")[0];
}
