// dependencies
import React from "react";
import { View, StyleSheet } from "react-native";

// components
import ActivityIndicator from ".";

type PropsType = {
  loading: boolean;
};

const SurroundActivityIndicator: React.FunctionComponent<PropsType> = (
  props
) => {
  const { loading, children } = props;

  return (
    <View style={{ position: "relative", width: "100%" }}>
      {loading && (
        <View
          style={{
            ...StyleSheet.absoluteFillObject,
            zIndex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActivityIndicator />
        </View>
      )}

      <View style={{ opacity: loading ? 0.3 : 1 }}>{children}</View>
    </View>
  );
};

export default SurroundActivityIndicator;
