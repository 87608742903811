import Uri from "@cloudspire/legacy-shared/src/libraries/Uri";

/**
 * Retourne les informations de la route si le prop `to`
 *   est une route interne.
 * @param {object} param0
 * @param {Uri} param0.to
 * @param {typeof import("@cloudspire/legacy-shared/src/libraries/Router").Router} param0.router
 * @param {boolean} param0.external
 */
const getRoute = ({ router, to, external }) => {
  let route;

  if (null !== to && undefined !== to && to.constructor.name === Uri.name) {
    route = router.match(to as Uri);
  } else if ("string" === typeof to) {
    const baseUri = router.getUri().toString();

    if (to.startsWith(baseUri)) {
      to = to.slice(baseUri.length);
    }

    if (!external && (to.charAt(0) === "" || to.charAt(0) === "/")) {
      // Si la route commence par "/" ou "", c'est qu'il s'agit
      //   d'un chemin relatif, on cherche alors la
      //   route correspondante dans le router.

      /**
       * Supprime `?` et `#` et tout ce qui se trouve après ces deux caractères.
       */
      const normalizedTo = to.split("?")[0].split("#")[0];

      route = router.match(
        new Uri(undefined, undefined, undefined, undefined, normalizedTo)
      );
    }
  }

  if (undefined !== route && null !== route) {
    let tempTo;
    let fragment = null;
    let query = null;

    [tempTo, fragment = null] = to.split("#");
    [tempTo, query = null] = tempTo.split("?");

    const as = new Uri()
      .withPath(route.generateUri().toString())
      .withQuery(query)
      .withFragment(fragment);

    return {
      nextLink: true,
      as: as.toString(),
      href: as.toString(),
    };
  } else {
    return {
      nextLink: false,
    };
  }
};

export default getRoute;
