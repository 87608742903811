// dependencies
import React from "react";
import {
  Animated,
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  TouchableWithoutFeedback,
  GestureResponderEvent,
  Platform,
} from "react-native";

// libraries
import { noop } from "@cloudspire/legacy-shared/src/libraries";

// constants
import { DRAWER as DRAWER_Z_INDEX } from "../../constants/zIndex";

type IProps = {
  contentStyle?: StyleProp<ViewStyle>;
  isOpened: boolean;
  shouldCloseOnClickOutside?: boolean;
  shouldCloseOnEscape?: boolean;
  onHide: () => void;
  onOpen: () => void;
  onShouldClose: () => void;
};

type IState = {
  visible: boolean;
};

class Drawer extends React.PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private animationValue: Animated.Value;
  private animationValueKeyframes;

  public constructor(props: IProps) {
    super(props);

    this.animationValue = new Animated.Value(props.isOpened ? 1 : 0);

    this.animationValueKeyframes = {
      visible: Animated.timing(this.animationValue, {
        toValue: 1,
        duration: 160,
        useNativeDriver: false,
      }),
      hide: Animated.timing(this.animationValue, {
        toValue: 0,
        duration: 160,
        useNativeDriver: false,
      }),
    };

    this.state = {
      visible: props.isOpened,
    };

    this.handlePressOutside = this.handlePressOutside.bind(this);
    this.handleKeydown = this.handleKeydown.bind(this);
  }

  public componentDidMount(): void {
    const { shouldCloseOnEscape } = this.props;

    if (shouldCloseOnEscape) {
      this.initKeypress();
    }
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { isOpened, shouldCloseOnEscape } = this.props;

    if (prevProps.isOpened !== isOpened) {
      if (isOpened) {
        this.makeVisible();

        this.preventScroll();
      } else {
        this.makeHide();

        this.allowScroll();
      }
    }

    if (prevProps.shouldCloseOnEscape !== shouldCloseOnEscape) {
      if (shouldCloseOnEscape) {
        this.initKeypress();
      } else {
        this.terminateKeypress();
      }
    }
  }

  public componentWillUnmount(): void {
    this.terminateKeypress();
    this.allowScroll();
  }

  private allowScroll() {
    if (Platform.OS === "web") {
      window.document.querySelector("html").style.overflow = null;
    }
  }

  private preventScroll() {
    if (Platform.OS === "web") {
      window.document.querySelector("html").style.overflow = "hidden";
    }
  }

  private initKeypress(): void {
    document.addEventListener("keydown", this.handleKeydown);
  }

  private terminateKeypress(): void {
    document.removeEventListener("keydown", this.handleKeydown);
  }

  /**
   * Ce callback n'est appelé que si le prop `shouldCloseOnEscape` vaut `true` et que la modal est ouverte
   */
  private handleKeydown(event: KeyboardEvent): void {
    const { isOpened } = this.props;

    if (isOpened && event.code === "Escape") {
      const { onShouldClose } = this.props;

      event.preventDefault();

      onShouldClose();
    }
  }

  private makeVisible(): void {
    const { onOpen } = this.props;

    this.animationValue.setValue(0);

    // s'assure que la valeur a bien été réinitialisée à 0 avant de lancer la mise à jour du state
    requestAnimationFrame(() => {
      this.setState({ visible: true }, () => {
        this.animationValueKeyframes.visible.start(onOpen);
      });
    });
  }

  private makeHide(): void {
    const { onHide } = this.props;

    this.animationValue.setValue(1);
    this.animationValueKeyframes.hide.start(() => {
      this.setState({ visible: false }, onHide);
    });
  }

  private handlePressOutside(event: GestureResponderEvent): void {
    const { shouldCloseOnClickOutside, onShouldClose } = this.props;

    if (shouldCloseOnClickOutside) {
      event.stopPropagation();
      onShouldClose();
    }
  }

  private getStyles(): StyleSheet.NamedStyles<{
    view: ViewStyle;
    overlay: ViewStyle;
    content: ViewStyle;
  }> {
    const backgroundOpacity = this.animationValue;
    const { visible } = this.state;

    return StyleSheet.create({
      view: {
        ...StyleSheet.absoluteFillObject,
        overflow: "hidden",
        display: visible ? "flex" : "none",
        zIndex: DRAWER_Z_INDEX,
      },
      overlay: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: backgroundOpacity.interpolate({
          inputRange: [0, 1],
          outputRange: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.65)"],
        }) as any,
      } as ViewStyle,
      content: {
        position: "fixed" as any,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#ffffff",
        shadowColor: "#000000",
        shadowRadius: 25,
        shadowOffset: { width: 10, height: 0 },
        transform: [
          {
            translateX: backgroundOpacity.interpolate({
              inputRange: [0, 1],
              outputRange: ["100%", "0%"],
            }),
          },
        ] as any,
        overflow: "auto" as any,
      } as ViewStyle,
    });
  }

  public render(): JSX.Element {
    const { isOpened, contentStyle, children } = this.props;

    const styles = this.getStyles();

    return (
      <View
        style={styles.view}
        importantForAccessibility={!isOpened ? "no-hide-descendants" : "auto"}
        aria-hidden={!isOpened}
      >
        <TouchableWithoutFeedback onPress={this.handlePressOutside}>
          <Animated.View style={styles.overlay} />
        </TouchableWithoutFeedback>

        <Animated.View style={[styles.content, contentStyle]}>
          {children}
        </Animated.View>
      </View>
    );
  }
}

Drawer.defaultProps = {
  shouldCloseOnClickOutside: true,
  shouldCloseOnEscape: true,
  onShouldClose: noop,
  onHide: noop,
  onOpen: noop,
};

export default Drawer;
