// polyfill
import "core-js/features/set";
import "core-js/features/map";
import "core-js/features/array/find";
import "core-js/features/array/find-index";
import "core-js/features/array/includes";
import "core-js/features/array/some";
import "core-js/features/array/every";
import "core-js/features/array/fill";
import "core-js/features/object/values";
import "core-js/features/string/starts-with";
import "core-js/features/string/ends-with";
import "core-js/features/string/pad-start";
import "core-js/features/promise";
import "core-js/features/weak-map";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/en";
import "@formatjs/intl-pluralrules/locale-data/fr";
import "@formatjs/intl-getcanonicallocales/polyfill";

// dependencies
import React from "react";

import App from "next/app";
import Head from "next/head";
import { IntlProvider } from "react-intl";
import { register, unregister } from "next-offline/runtime";
import { StyleSheet } from "aphrodite";

// api
import { getCookies } from "@cloudspire/legacy-resources/src/api/cookies";
import {
  getMessages,
  setMessagesToStorage,
} from "@cloudspire/legacy-resources/src/api/messages";
import {
  getTheme,
  setThemeToStorage,
} from "@cloudspire/legacy-resources/src/api/theme";
import { getRoute } from "@cloudspire/legacy-resources/src/api/route";

// constants
import theming from "@cloudspire/legacy-resources/src/constants/theming";
import browser from "@cloudspire/legacy-resources/src/constants/browser";
import { GOOGLE_TRACKING_COOKIE_NAME } from "@cloudspire/legacy-resources/src/constants/googleTracking";
import {
  apiRouter,
  frontRouter,
} from "@cloudspire/legacy-shared/src/constants/router";

// contexts
import { ConfigurationProvider } from "@cloudspire/legacy-resources/src/contexts/configuration";
import { RouteProvider } from "@cloudspire/legacy-resources/src/contexts/route";
import { CookiesProvider } from "@cloudspire/legacy-resources/src/contexts/cookies";
import { RequestContextProvider } from "@cloudspire/legacy-resources/src/contexts/requestContext";
import { RoutersProvider } from "@cloudspire/legacy-resources/src/contexts/routers";
import { GoogleTrackingProvider } from "@cloudspire/legacy-resources/src/contexts/GoogleTracking";
import { AuthProvider } from "@cloudspire/legacy-resources/src/contexts/AuthContext";

// components
import GlobalError from "@cloudspire/legacy-resources/src/components/GlobalError";

// containers
import Layout from "../containers/Layout";
import ErrorBoundary from "@cloudspire/legacy-resources/src/containers/ErrorBoundary";
import RoutingProgressBar from "@cloudspire/legacy-resources/src/containers/RoutingProgressBar";

// helpers
import { appGetRouters } from "@cloudspire/legacy-resources/src/helpers/app";
import {
  nextGetUri,
  nextGetHost,
  nextGetAppUri,
  nextGetChannelConfiguration,
  nextSetChannelConfiguration,
  nextRedirect,
  nextGetSocialMetaTags,
} from "@cloudspire/legacy-resources/src/helpers/next";
import channelBuildHostFromConfiguration from "@cloudspire/shared/helpers/channel/buildHostFromConfiguration";
import nextGetAuthToken from "@cloudspire/legacy-resources/src/helpers/next/getAuthToken";
import nextDeleteAuthToken from "@cloudspire/legacy-resources/src/helpers/next/deleteAuthToken";

// utils
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";
import {
  getGoogleTrackingServiceUsingConfiguration,
  getGoogleTrackingKeyUsingConfiguration,
  getTrackingState,
} from "@cloudspire/legacy-resources/src/libraries/utils/googleTracking";
import { buildFetcher } from "@cloudspire/legacy-resources/src/libraries/utils/fetcher";

// errors
import { BaseError } from "@cloudspire/legacy-resources/src/errors";

// pkg
import pkg from "../package.json";

import "@cloudspire/legacy-shared/src/css/index.css";
import "./styles.css";

if (browser) {
  StyleSheet.rehydrate(window.__REHYDRATE_IDS);
}

const { ThemeProvider } = theming;

class MyApp extends App {
  static async getInitialProps(appContext) {
    const {
      Component,
      ctx,
      router: {
        locale,
        defaultLocale = process.env.NEXT_PUBLIC_DEFAULT_LOCALE,
      },
    } = appContext;

    let pageProps = {};

    let error = null;

    const cookies = getCookies({ req: ctx.req });

    const url = new URL(nextGetUri({ ctx }));

    const uri = nextGetAppUri({
      url,
      locale,
    });

    const host = nextGetHost({ req: ctx.req });

    try {
      // Récupère la configuration du channel.
      const channelConfiguration = await nextGetChannelConfiguration({
        host,
      });

      // Récupère les messages de traduction
      const { messages } = await getMessages({ locale, defaultLocale });

      const realm = channelConfiguration.channel.realm.reference.toLowerCase();

      /* Récupère le thème */
      const { theme } = await getTheme({ realm, uri });

      /* Récupère la route courante */
      const { route } = await getRoute({
        router: frontRouter,
        uri,
      });

      const routers = appGetRouters({
        channelConfiguration,
        currentWebsiteType: "front",
      });

      const authToken = nextGetAuthToken({ cookies });

      if (Component.getInitialProps) {
        const requestContext = {
          channelId: channelConfiguration.channel.id,
          token: authToken,
          async onUnauthorized() {
            nextDeleteAuthToken({
              res: ctx.res,
              domain: channelConfiguration.channel.website.domain,
            });
            await nextRedirect({
              code: 302,
              redirectionUri: url,
              res: ctx.res,
            });
          },
        };

        await Promise.resolve(
          Component.getInitialProps({
            ...ctx,
            configuration: channelConfiguration,
            route,
            language: locale,
            routers,
            query: uri.getParsedQuery(),
            cookies,
            isomorphicFetcher: buildFetcher(requestContext),
            requestContext,
          })
        )
          .then((initialPageProps) => {
            pageProps = initialPageProps;
          })
          .catch((err) => {
            if (err instanceof BaseError) {
              // Est-ce une erreur qu'on sait traiter

              error = err;

              if (!process.browser) {
                ctx.res.statusCode = err.code;
              }
            } else {
              // On ne sait pas traiter l'erreur, on laisse Next.js s'en occuper.
              return Promise.reject(err);
            }
          });
      }

      return {
        pageProps,
        language: locale,
        channelConfiguration,
        messages,
        theme,
        realm,
        route,
        cookies,
        authToken,
        url,
        error,
      };
    } catch (error) {
      if ("development" === process.env.NODE_ENV) {
        throw error;
      } else {
        return {
          globalError: error,
        };
      }
    }
  }

  static getDerivedStateFromProps(props) {
    if (!props.globalError) {
      const uri = nextGetAppUri({
        url: new URL(props.url),
        locale: props.language,
      });

      let { route } = getRoute({
        router: frontRouter,
        uri,
      });

      if (null === route) {
        route = frontRouter.findByName("App.NotFound").toFilled();
      }

      return {
        route,
      };
    }

    return {};
  }

  constructor(props) {
    super(props);

    if (!props.globalError) {
      setMessagesToStorage({
        language: props.language,
        messages: props.messages,
      });

      setThemeToStorage({ realm: props.realm, theme: props.theme });

      this.state = {
        route: null,
        requesterManager: null,
        routers: appGetRouters({
          channelConfiguration: props.channelConfiguration,
          currentWebsiteType: "front",
        }),
      };
    } else {
      this.state = {};
    }
  }

  componentDidMount() {
    register();
  }

  componentWillUnmount() {
    // Désactivation du service-worker.
    unregister();
  }

  render() {
    const {
      Component,
      pageProps,
      language,
      messages,
      channelConfiguration,
      theme,
      cookies,
      authToken,
      realm,
      error,
      globalError,
    } = this.props;
    if (globalError) {
      return <GlobalError error={globalError} />;
    }

    const { route, routers } = this.state;

    const { siteVerificationKey } =
      channelConfiguration.channel.thirdPartyServices.google;

    const twitterObjectConfiguration = channelConfiguration.channel.socials.tw;
    const twitterAccount = twitterObjectConfiguration
      ? twitterObjectConfiguration.url.match(/^https:\/\/twitter\.com\/(.*)$/)
      : undefined;

    const uri = generateUri({
      route,
      scheme: "https",
      host: channelConfiguration.channel.website.domain,
    });

    nextSetChannelConfiguration({ channelConfiguration });

    const {
      title,
      description,
      image,
      url,
      ogType,
      ogSiteName,
      twitterCard,
      twitterCreator,
    } = nextGetSocialMetaTags({ channelConfiguration, uri });

    return (
      <>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
          <meta name="author" content={channelConfiguration.channel.title} />
          {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
          {title && (
            <>
              <meta property="og:title" content={title} />
              <meta name="twitter:title" content={title} />
            </>
          )}
          {description && (
            <>
              <meta property="og:description" content={description} />
              <meta name="twitter:description" content={description} />
            </>
          )}
          {image && (
            <>
              <meta property="og:image" content={image} />
              <meta name="twitter:image" content={image} />
            </>
          )}
          {ogType && <meta property="og:type" content="website" />}
          {url && (
            <>
              <meta property="og:url" content={url} />
              <meta name="twitter:url" content={uri} />
            </>
          )}
          {twitterCard && <meta name="twitter:card" content="summary" />}
          {twitterCreator && (
            <meta name="twitter:creator" content={twitterCreator} />
          )}

          {null !== route &&
            route.getAlternates().map((alternateRoute) => (
              <link
                key={alternateRoute.getParameter("lang")}
                rel="alternate"
                hrefLang={alternateRoute.getParameter("lang")}
                href={generateUri({
                  route: alternateRoute,
                  scheme: "https",
                  host: channelBuildHostFromConfiguration({
                    channelConfiguration,
                    websiteType: "front",
                  }),
                })}
              />
            ))}

          {null != theme.FONT_FAMILY_URL && (
            <link
              href={theme.FONT_FAMILY_URL}
              rel="stylesheet"
              type="text/css"
            />
          )}

          <link
            rel="preconnect dns-prefetch"
            href={generateUri({
              router: apiRouter,
              name: "Api.Info",
            })}
          />

          {/* Met en cache l'image de fallback */}
          <link rel="prefetch dns-prefetch" href="/images/fallback.png" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
          <meta name="format-detection" content="telephone=no" />
          <link
            rel="apple-touch-icon"
            sizes="144x144"
            href={`/favicons/${realm}/apple-touch-icon.png?v=${pkg.version}`}
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/favicon-32x32.png?v=${pkg.version}`}
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/favicon-16x16.png?v=${pkg.version}`}
            sizes="16x16"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/android-chrome-192x192.png?v=${pkg.version}`}
            sizes="192x192"
          />
          <link
            rel="icon"
            type="image/png"
            href={`/favicons/${realm}/android-chrome-512x512.png?v=${pkg.version}`}
            sizes="512x512"
          />
          <link
            rel="mask-icon"
            href={`/favicons/${realm}/safari-pinned-tab.svg?v=${pkg.version}`}
            color={theme.PRIMARY_COLOR}
          />
          <link rel="manifest" href={`/favicons/${realm}/manifest.json`} />
          <meta name="msapplication-TileColor" content="#ffffff" />
          <meta
            name="msapplication-TileImage"
            content={`/favicons/${realm}/mstile-150x150.png?v=${pkg.version}`}
          />
          <meta name="theme-color" content="#ffffff" />
          <link rel="shortcut icon" href={`/favicons/${realm}/favicon.ico`} />
          <meta
            name="msapplication-config"
            content="/favicons/gdf/browserconfig.xml"
          />

          <title key="title">{channelConfiguration.channel.title}</title>

          {siteVerificationKey && (
            <meta
              name="google-site-verification"
              content={siteVerificationKey}
            />
          )}
        </Head>

        <RoutingProgressBar />

        <IntlProvider locale={language} messages={messages}>
          <ThemeProvider theme={theme}>
            <ConfigurationProvider configuration={channelConfiguration}>
              <RouteProvider route={route}>
                <CookiesProvider initialCookies={cookies}>
                  <AuthProvider
                    domain={channelConfiguration.channel.website.domain}
                    initialToken={authToken}
                  >
                    <RequestContextProvider
                      requestContext={{
                        channelId: channelConfiguration.channel.id,
                      }}
                    >
                      <RoutersProvider routers={routers}>
                        <GoogleTrackingProvider
                          trackingService={getGoogleTrackingServiceUsingConfiguration(
                            { configuration: channelConfiguration }
                          )}
                          trackingKey={getGoogleTrackingKeyUsingConfiguration({
                            configuration: channelConfiguration,
                          })}
                          initialState={getTrackingState({
                            cookieValue: cookies[GOOGLE_TRACKING_COOKIE_NAME],
                          })}
                        >
                          <Layout>
                            <ErrorBoundary error={error}>
                              <Component {...pageProps} />
                            </ErrorBoundary>
                          </Layout>
                        </GoogleTrackingProvider>
                      </RoutersProvider>
                    </RequestContextProvider>
                  </AuthProvider>
                </CookiesProvider>
              </RouteProvider>
            </ConfigurationProvider>
          </ThemeProvider>
        </IntlProvider>
      </>
    );
  }
}

export default MyApp;
