import BaseError from "./BaseError";

class ContentNotFoundError extends BaseError {
  public status = "not_found";

  public code = 404;

  public component = "ContentNotFoundError";

  public constructor(message, props) {
    super(message);

    this.props = props;
  }
}

export default ContentNotFoundError;
