// constants
import getCache from "../../constants/cache";

// normalizers
import { configurationParseResponse } from "../../normalizers/configuration";

// constants
import { NEXT_CHANNEL_CONFIGURATION_WINDOW_KEY } from "../../constants/next";
import { METHOD_VALUE_GET } from "@cloudspire/legacy-shared/src/constants/methods";
import { apiRouter } from "@cloudspire/legacy-shared/src/constants/router";
import browser from "../../constants/browser";

// libraries
import { request, generateUri } from "@cloudspire/legacy-shared/src/libraries";

/**
 * Récupère la configuration du canal de distribution courant.
 */
export default async function nextGetChannelGetConfiguration({
  host,
  locale = "fr",
}): Promise<
  ReturnType<
    typeof import("../../normalizers/configuration").configurationParseResponse
  >
> {
  // Si on est côté client, on récupère les informations de la configuration du channel depuis l'objet `window`.
  if (browser) {
    // Si la clé n'existe pas dans l'objet `window`, on lève une erreur.
    // En cas d’exécution normale, la clé ne peut pas être absente.
    if (!NEXT_CHANNEL_CONFIGURATION_WINDOW_KEY) {
      throw new Error("Channel configuration not found in window.");
    }
    return window[NEXT_CHANNEL_CONFIGURATION_WINDOW_KEY];
  }
  // Si l’utilisation du cache est activée, on vérifie si on a du cache disponible.
  if ("ON" === process.env.ENABLE_CACHE_EXPIRATION) {
    const cache = await getCache();

    const parts = host.split(".");

    // S’il y a moins de 5 parties dans le domaine, on ne fait rien.
    if (5 < parts.length) {
      throw new Error("Invalid hostname, too much sub-domains parts.");
    }

    const tld = parts.pop();
    const domain = `${parts.pop()}.${tld}`;

    const subdomains = [...parts];

    const domains = [domain];
    for (let i = subdomains.length - 1; 0 <= i; i--) {
      domains.unshift(`${subdomains.slice(i).join(".")}.${domain}`);
    }

    for (const domain of domains) {
      const cachedResponse = await cache.get(
        `monolith:cache:channels:${domain}:configuration:${locale}`
      );
      if (cachedResponse) {
        return configurationParseResponse({
          data: { data: cachedResponse },
        });
      }
    }
  }
  // Sinon, on exécute la requête API pour récupérer la configuration du channel.
  return request({
    url: generateUri({
      router: apiRouter,
      name: "Api.Channels.Action.Configuration",
      query: { domain: host },
    }),
    method: METHOD_VALUE_GET,
  }).then(({ body: configuration }) =>
    configurationParseResponse({
      data: configuration,
    })
  );
}
