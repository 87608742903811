import matchQuery from "../matchQuery";
import convertUnit from "../convertUnit";

const getBreakpoint = ({ breakpointList = {}, width }): string => {
  const breakpointNames = Object.keys(breakpointList).sort(
    (breakpointName1, breakpointName2) =>
      convertUnit(breakpointList[breakpointName1]) -
      convertUnit(breakpointList[breakpointName2])
  );
  const breakpointWidths = breakpointNames.map(
    (breakpointName) => breakpointList[breakpointName]
  );

  let previousWidth = 0;

  for (const x in breakpointNames) {
    const breakpointName = breakpointNames[x];
    const breakpointWidth = breakpointWidths[x];

    const query = { minWidth: previousWidth, maxWidth: breakpointWidth };

    if (matchQuery({ query, width, height: 0 })) {
      return breakpointName;
    }

    previousWidth = breakpointWidth;
  }

  return breakpointNames[breakpointNames.length - 1];
};

export default getBreakpoint;
