// libraries
import Uri from "@cloudspire/legacy-shared/src/libraries/Uri";

export default function nextGetAppUri({ url, locale }) {
  let path = url.pathname;

  if (!path.startsWith(`/${locale}`)) {
    if ("/" === path) {
      path = `/${locale}`;
    } else {
      path = `/${locale}${path}`;
    }
  }

  return new Uri(
    undefined,
    undefined,
    undefined,
    undefined,
    path,
    decodeURIComponent(url.search.slice(1))
  );
}
