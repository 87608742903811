import delay from "../delay";

/**
 * If the promises takes too long to be executed (in given delay), it will be automatically
 *   resolves without waiting.
 * @example
 * class {
 *   public constructor() {
 *     this.asyncFunction = promiseTimeout(this.asyncFunction.bind(this), 1000);
 *   }
 * }
 */
const promiseTimeout = <T, U extends (...args: any) => T>(
  fn: U,
  timeout: number
) => {
  return function (this, ...args): Promise<T | void> {
    return Promise.race([
      fn.apply(this, args),
      delay(timeout),
    ]) as Promise<T | void>;
  };
};

export default promiseTimeout;
