import React from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";

type IProps = {
  ratio?: number;
  style?: StyleProp<ViewStyle>;
};

type IState = {};

class FlexEmbed extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private getStyles(): StyleSheet.NamedStyles<{
    flexEmbed: ViewStyle;
    ratio: ViewStyle;
    content: ViewStyle;
  }> {
    const { ratio } = this.props;

    return StyleSheet.create({
      flexEmbed: {
        position: "relative",
        overflow: "hidden",
      },
      ratio: {
        paddingBottom: `${ratio * 100}%`,
      },
      content: {
        height: "100%",
        ...StyleSheet.absoluteFillObject,
      },
    });
  }

  public render(): JSX.Element {
    const { style, children } = this.props;

    const styles = this.getStyles();

    return (
      <View style={[styles.flexEmbed, style]}>
        <View style={styles.ratio} />

        <View style={styles.content}>{children}</View>
      </View>
    );
  }
}

FlexEmbed.defaultProps = {
  ratio: 9 / 16,
};

export default FlexEmbed;
