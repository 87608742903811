// dependencies
import React, { useContext } from "react";

const configuration =
  React.createContext<
    ReturnType<
      typeof import("../normalizers/configuration").configurationParseData
    >
  >(null);

type ProviderPropsType = {
  configuration;
};

export const ConfigurationProvider: React.FunctionComponent<
  ProviderPropsType
> = (props) => {
  const { configuration: value, ...otherProps } = props;

  const Provider = configuration.Provider;

  return <Provider {...otherProps} value={value} />;
};

export const ConfigurationConsumer = configuration.Consumer;

export const useConfiguration = () => useContext(configuration);

export default configuration;
