/**
 * Lighten a color to a given amplitude.
 * @param color An hexadecimal color.
 */
const lighten = (color: string, amplitude: number) => {
  if (color[0] === "#") {
    color = color.slice(1);
  }

  const num = parseInt(color, 16);

  let r = (num >> 16) + amplitude;

  if (r > 255) {
    r = 255;
  } else if (r < 0) {
    r = 0;
  }

  let b = ((num >> 8) & 0x00ff) + amplitude;

  if (b > 255) {
    b = 255;
  } else if (b < 0) {
    b = 0;
  }

  let g = (num & 0x0000ff) + amplitude;

  if (g > 255) {
    g = 255;
  } else if (g < 0) {
    g = 0;
  }

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
};

export default lighten;
