/**
 * Retourne une valeur en itérant en profondeur dans un objet.
 * @param {object} obj
 * @param {string} path
 * @param {*} defaultValue
 * @return {*}
 */
export default function get(obj, path, defaultValue = null) {
  if (!path) {
    return defaultValue
  }
  let parts = path.split(".");
  if (0 === parts[0].length) {
    return defaultValue
  }
  let instance = obj;
  for (const part of parts) {
    if (null === instance || undefined === instance || !["[object Object]", "[object Array]"].includes(Object.prototype.toString.call(instance))) {
      return defaultValue
    }
    instance = instance[part];
  }
  if (null === instance || undefined === instance) {
    return defaultValue
  }
  return instance;
}
