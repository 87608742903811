/**
 * Récupère les messages de traduction.
 */
const getMessages = ({ locale, defaultLocale }) => {
  return import(
    `@cloudspire/legacy-resources/src/constants/translations/${locale}.json`
  )
    .catch(function () {
      return import(
        `@cloudspire/legacy-resources/src/constants/translations/${defaultLocale}.json`
      );
    })
    .then(({ default: messages }) => ({ messages }));
};

export default getMessages;
