// constants
import browser from "../../constants/browser";

/**
 * Récupère l’Uri courant.
 * @param {object} param0
 * @param {object} param0.ctx
 * @return {URL}
 */
export default function nextGetUri({ ctx }) {
  const protocol = browser
    ? window.location.protocol
    : `${
        ctx.req.headers["x-forwarded-proto"] ?? ctx.req.protocol
          ? "https"
          : "http"
      }:`;
  const host = browser
    ? window.location.host
    : ctx.req.headers["x-forwarded-host"] ?? ctx.req.headers.host;

  const authority = `${protocol}//${host}`;

  const url = new URL(ctx.asPath, authority);

  return url.href;
}
