import EnhancedDate from "./EnhancedDate";

/**
 * Importe un jour natif en EnhancedDate.
 */
const fromDate = (date: Date = new Date(Date.now())): EnhancedDate => {
  if (!(date instanceof Date)) {
    throw new TypeError("EnhancedDate.fromDate `date` must be of type `Date`");
  }

  const enhancedDate = new EnhancedDate(date);

  return enhancedDate;
};

export default fromDate;
