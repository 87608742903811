import Uri from "../Uri";
import {
  METHOD_VALUE_GET,
  METHOD_VALUE_HEAD,
  METHOD_VALUE_POST,
  METHOD_VALUE_PUT,
  METHOD_VALUE_DELETE,
  METHOD_VALUE_CONNECT,
  METHOD_VALUE_OPTIONS,
  METHOD_VALUE_TRACE,
} from "../../constants/methods";

import Route from "./route";
import FilledRoute from "./filled-route";
import GroupRouter from "./group-router";

import group from "./prototypes/group";

type Instantiable<T = any> = { new (...args: any[]): T };

class AbstractRouter {
  protected GroupRouter: Instantiable<GroupRouter>;

  protected Route: Instantiable<Route>;

  protected routes: Route[] = [];

  protected uri: Uri;

  public constructor(uri: Uri = new Uri()) {
    this.uri = uri;
  }

  public get(path: string): Route {
    return this.route([METHOD_VALUE_GET], path);
  }

  public head(path: string): Route {
    return this.route([METHOD_VALUE_HEAD], path);
  }

  public post(path: string): Route {
    return this.route([METHOD_VALUE_POST], path);
  }

  public put(path: string): Route {
    return this.route([METHOD_VALUE_PUT], path);
  }

  public delete(path: string): Route {
    return this.route([METHOD_VALUE_DELETE], path);
  }

  public connect(path: string): Route {
    return this.route([METHOD_VALUE_CONNECT], path);
  }

  public options(path: string): Route {
    return this.route([METHOD_VALUE_OPTIONS], path);
  }

  public trace(path: string): Route {
    return this.route([METHOD_VALUE_TRACE], path);
  }

  private setUri(uri: Uri) {
    this.uri = uri;

    this.routes.forEach((route) => route.setUri(uri));
  }

  public clone(): this {
    const newRouter = new (this.constructor as any)(this.getUri());

    this.routes.forEach((route) => {
      const newRoute = route.clone();

      newRoute.setRouter(newRouter);

      newRouter.routes.push(newRoute);
    });

    return newRouter;
  }

  public withUri(uri: Uri) {
    const router = this.clone();

    router.setUri(uri);

    return router;
  }

  public group(
    path: string,
    callback: (router: import("./group-router").default) => void
  ) {
    group.call(this, path, callback);
  }

  public route(
    methods: (
      | "GET"
      | "POST"
      | "PUT"
      | "PATCH"
      | "DELETE"
      | "HEAD"
      | "CONNECT"
      | "OPTIONS"
      | "TRACE"
    )[],
    path: string
  ): Route {
    const route = new this.Route(methods, path);
    route.setUri(this.uri);
    route.setRouter(this);

    this.routes.push(route);

    return route;
  }

  public getUri() {
    return this.uri;
  }

  public match(uri: Uri): FilledRoute {
    return [...this.routes].reduce<FilledRoute>((_1, route, _2, routes) => {
      const matchRoute = route.match(uri);

      if (null !== matchRoute) {
        routes.splice(1); // trick to stock reduce loop.

        return matchRoute;
      }

      return null;
    }, null);
  }

  public find(callback): Route {
    const route = this.routes.find(callback);

    if ("undefined" !== typeof route) {
      return route;
    }

    return null;
  }

  public findByName(name: string): Route {
    const route = this.routes.find((route) => route.getName() === name);

    if ("undefined" !== typeof route) {
      return route;
    }

    return null;
  }

  public findByPage(page: string): Route {
    const route = this.routes.find((route) => route.getPage() === page);

    if ("undefined" !== typeof route) {
      return route;
    }

    return null;
  }

  public getRoutes() {
    return [...this.routes];
  }
}

export default AbstractRouter;
