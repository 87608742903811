/**
 * Transforme un numéro du format 0123456789 au format 01 23 45 67 89
 */
const formatPhone = (phone: string) => {
  if ("string" === typeof phone) {
    return phone.replace(
      /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      "$1 $2 $3 $4 $5"
    );
  }

  return null;
};

export default formatPhone;
