// Nom du cookie
export const GOOGLE_TRACKING_COOKIE_NAME = "allow_cookies";

// État d’acceptation

export const GOOGLE_TRACKING_STATE_VALUE_PENDING = "pending";
export const GOOGLE_TRACKING_STATE_VALUE_ACCEPTED = "accepted";
export const GOOGLE_TRACKING_STATE_VALUE_REFUSED = "refused";

export const GOOGLE_TRACKING_STATES = {
  [GOOGLE_TRACKING_STATE_VALUE_PENDING]: {
    id: GOOGLE_TRACKING_STATE_VALUE_PENDING,
  },
  [GOOGLE_TRACKING_STATE_VALUE_ACCEPTED]: {
    id: GOOGLE_TRACKING_STATE_VALUE_ACCEPTED,
  },
  [GOOGLE_TRACKING_STATE_VALUE_REFUSED]: {
    id: GOOGLE_TRACKING_STATE_VALUE_REFUSED,
  },
};

export const GOOGLE_TRACKING_STATE_LIST = Object.values(GOOGLE_TRACKING_STATES);

// Services supportés

/** Google Tag Manager */
export const GOOGLE_TRACKING_SERVICE_VALUE_GTM = "GTM";
/** Google Analytics */
export const GOOGLE_TRACKING_SERVICE_VALUE_GA = "GA";

export const GOOGLE_TRACKING_SERVICES = {
  [GOOGLE_TRACKING_SERVICE_VALUE_GTM]: {
    id: GOOGLE_TRACKING_SERVICE_VALUE_GTM,
  },
  [GOOGLE_TRACKING_SERVICE_VALUE_GA]: {
    id: GOOGLE_TRACKING_SERVICE_VALUE_GA,
  },
};

export const GOOGLE_TRACKING_SERVICE_LIST = Object.values(
  GOOGLE_TRACKING_SERVICES
);
