// dependencies
import React from "react";
import { StyleSheet } from "react-native";
import { IconRateO } from "@cloudspire/legacy-svg-icon-library";

// libraries
import { emToPx } from "../../libraries";

// components
import Text from "../Text";
import Link from "../Link";
import IconText from "../IconText";

// hocs
import useHover from "../../hocs/useHover";

type PropsType = {
  to: string;
};

const styles = StyleSheet.create({
  link: {
    display: "flex",
    alignItems: "center",
  },
  linkText: {
    color: "#f45d5c",
    fontWeight: "600",
    fontSize: emToPx(0.875),
  },
  iconWrapper: {
    fontSize: emToPx(1),
    color: "#f45d5c",
  },
  linkText__hovered: {
    textDecorationLine: "underline",
  },
});

const LabelLink: React.FunctionComponent<PropsType> = (props) => {
  const { to, children } = props;
  const { hovered, onMouseEnter, onMouseLeave } = useHover();

  return (
    <Link style={styles.link} to={to}>
      <IconText Icon={IconRateO} iconWrapperStyle={styles.iconWrapper}>
        <Text
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={[styles.linkText, hovered && styles.linkText__hovered]}
        >
          {children}
        </Text>
      </IconText>
    </Link>
  );
};

export default LabelLink;
