// dependencies
import React from "react";
import {
  View,
  StyleSheet,
  ViewStyle,
  TextStyle,
  AccessibilityRole,
} from "react-native";

// constants
import theming from "../../constants/theming";

// component
import UIContainer from "../UIContainer";
import Text from "../Text";
import { List, ListItem } from "../List";
import { LinkBottomBar } from "../Link";
import BaseComponent from "../BaseComponent";

// hocs
import withMedia, { WithMediaInjectedPropsType } from "../../hocs/withMedia";
import { emToPx } from "../../libraries";

const { useTheme } = theming;

export type ILink = {
  label: string;
  to: string;
};

export type PropsType = {
  label: string;
  linkList?: ILink[];
  matches?: boolean;
} & WithMediaInjectedPropsType;

const getStyles = (
  theme,
  matches
): StyleSheet.NamedStyles<{
  component: TextStyle;
  view: ViewStyle;
  container: ViewStyle;
  wrapper: ViewStyle;
  label: TextStyle;
}> => {
  return StyleSheet.create({
    component: {
      fontSize: `${theme.FONT_SIZE / 16}rem`,
      height: "100%",
    },
    view: {
      paddingTop: ".625em",
      paddingRight: 0,
      paddingBottom: ".625em",
      paddingLeft: 0,
      backgroundColor: theme.PRIMARY_COLOR,
    },
    list: {
      width: "100%",
      flexWrap: "wrap",
      flexShrink: 1,
      marginLeft: matches ? emToPx(1) : 0,
      marginTop: !matches ? emToPx(1) : 0,
    },
    container: {
      height: "100%",
    },
    wrapper: {
      flexDirection: matches ? "row" : "column",
      justifyContent: matches ? "center" : "flex-start",
      alignItems: matches ? "center" : "flex-start",
    },
    label: {
      color: "#fff",
      flexShrink: 0,
      fontSize: `${12.8 / 16}em` as any,
    },
  });
};

const BottomBar: React.FunctionComponent<PropsType> = (props) => {
  const { label, linkList = [], matches } = props;
  const theme = useTheme();

  const styles = getStyles(theme, matches);

  return (
    <BaseComponent
      containerStyle={styles.view}
      accessibilityRole={"section" as AccessibilityRole}
    >
      <UIContainer style={styles.container} isFullWidth={true}>
        <View
          style={styles.wrapper}
          accessibilityRole={"navigation" as AccessibilityRole}
        >
          <Text style={styles.label}>{label}</Text>

          {linkList.length > 0 && (
            <List gutter={0.625} inline={matches} style={styles.list}>
              {linkList.map((link, key) => {
                return (
                  <ListItem key={key}>
                    <Text style={styles.label}>
                      <LinkBottomBar to={link.to}>{link.label}</LinkBottomBar>
                    </Text>
                  </ListItem>
                );
              })}
            </List>
          )}
        </View>
      </UIContainer>
    </BaseComponent>
  );
};

export default withMedia({ query: { minWidth: 820 } })(BottomBar);
