import AbstractFilledRoute from "./abstract-filled-route";
import AbstractRoute from "./abstract-route";

class FilledRoute extends AbstractFilledRoute {
  public static fromRoute(route: AbstractRoute): FilledRoute {
    const filledRoute = new FilledRoute(route.getMethods(), route.getPath())
      .setConstrains(route.getConstrains())
      .setName(route.getName())
      .setPage(route.getPage())
      .setOverloadedPaths(route.getOverloadedPaths())
      .setUri(route.getUri());

    if (route instanceof FilledRoute) {
      filledRoute.setParameters(route.getParameters());
    }

    return filledRoute;
  }
}

export default FilledRoute;
