/**
 * Memoize the result of a function.
 * It takes in account parameters using a shallow comparison (object reference are not taken in account).
 * @param {requestCallback} fn
 * @return {any} Cached result if not already store, the cache otherwise
 */
const memoize = (fn) => {
  const cache = {};

  return (...args) => {
    const memoizeKey = JSON.stringify(args);

    if (memoizeKey in cache) {
      return cache[memoizeKey];
    } else {
      const result = fn(...args);

      cache[memoizeKey] = result;
      return result;
    }
  };
};

export default memoize;
