function setPathFor(this: any, name: string, value: string, path: string) {
  if (
    null !== this.overloadedPaths.constrainName &&
    name !== this.overloadedPaths.constrainName
  ) {
    throw new Error("Cannot redefine setPathFor with other constrain name.");
  }

  const constrains = this.getConstrains();

  const constrain =
    constrains[name] instanceof RegExp
      ? new RegExp(`^${constrains[name].source}$`)
      : /^[A-z0-9-]+$/;

  if (!constrain.test(value)) {
    throw new Error(
      "Cannot define setPathFor with a value that do not match constrain."
    );
  }

  this.overloadedPaths.constrainName = name;
  this.overloadedPaths.paths[value] = path;

  return this;
}

export default setPathFor;
