/**
 * Normalise une URL en ajoutant le protocole https si aucun protocole n’est présent.
 * @param {string} url
 * @return {string?}
 */
export default function normalizeUrl(url) {
  if ("string" !== typeof url) {
    return null;
  }

  if (url.length === 0) {
    return null;
  }

  if (!(url.startsWith("http://") || url.startsWith("https://"))) {
    return `https://${url}`;
  }

  return url;
}
