// dependencies
import { memoize } from "@cloudspire/legacy-shared/src/libraries";

abstract class AbstractCacheManager {
  /**
   * Génère un hash.
   * @param {Array<string>|string} key
   * @return {string} Retourne un sha1 en fonction de la clé.
   */
  static async getHash(key) {
    if (Array.isArray(key)) {
      return key.join(".");
    }

    return key;
  }

  /**
   * Retourne vrai si la clé existe dans la cache, faux sinon.
   * @param {Array<string>|string} key
   * @return {boolean}
   */
  public abstract async has(key: string): Promise<boolean>;

  /**
   * Retourne une valeur du cache en fonction de la clé.
   * @param {Array<string>|string} key
   * @return mixed
   */
  public abstract async get(key: string): Promise<any>;

  /**
   * Ajoute une valeur dans le cache.
   * @param {Array<string>|string} key
   * @param {any} value
   * @param {any} expiresAt Date d'expiration de la valeur.
   */
  public abstract async set(key: string, value, expiresAt?): Promise<void>;

  /**
   * Supprime valeur du cache en fonction de la clé.
   * @param {Array<string>|string} key
   * @return boolean true si suppression réussie
   */
  public abstract async delete(key: string): Promise<void>;
}

AbstractCacheManager.getHash = memoize(AbstractCacheManager.getHash);

export default AbstractCacheManager;
