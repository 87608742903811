import { createTheming } from "@callstack/react-theme-provider";

import defaultStyle from "./style/default";

export type IDefaultType = typeof defaultStyle;

export type IPropsWithTheme = {
  theme: IDefaultType;
};

const theming = createTheming<IDefaultType>({} as IDefaultType);

export default theming;
