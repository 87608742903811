// dependencies
import React from "react";
import { StyleSheet } from "react-native";

// theming
import theming, { IPropsWithTheme } from "../../../constants/theming";

// components
import Link, { IProps as IPropsLink } from "../Link";

const { withTheme } = theming;

type IProps = {
  isActivated?: boolean;
  type?: "basic" | "highlight";
} & IPropsLink &
  IPropsWithTheme;

type IState = {};

class Menu extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  public render(): JSX.Element {
    const {
      style,
      isActivated: isActived,
      theme,
      isHovered,
      type,
      ...attrs
    } = this.props;

    const styles = StyleSheet.create({
      link: {
        color: theme.PRIMARY_COLOR,
        backgroundColor: "#ffffff",
      },
      link__hover: {
        color: theme.SECONDARY_COLOR,
        backgroundColor: "#f8f3ea",
      },
      link__active: {
        color: theme.SECONDARY_COLOR,
        backgroundColor: "#f8f3ea",
      },
      link__typeHighlight: {
        color: "#ffffff",
        backgroundColor: theme.PRIMARY_COLOR,
      },
    });

    return (
      <Link
        {...attrs}
        style={[
          styles.link,
          type === "highlight" && styles.link__typeHighlight,
          isActived && styles.link__active,
          style,
        ]}
        hoverStyle={styles.link__hover}
      />
    );
  }
}

Menu.defaultProps = {
  type: "basic",
};

export default withTheme(Menu);
