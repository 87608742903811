// dependencies
import React from "react";
import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  ViewProps,
} from "react-native";

type IProps = {
  gutter?: number;
  inline?: boolean;
  noWrap?: boolean;
  style?: StyleProp<ViewStyle>;
} & ViewProps;

type IState = {};

class List extends React.PureComponent<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private getStyles(): StyleSheet.NamedStyles<{
    view__isInline: ViewStyle;
    view__noWrap: ViewStyle;
  }> {
    return StyleSheet.create({
      view__isInline: {
        flexDirection: "row",
      },
      view__noWrap: {
        flexWrap: "nowrap",
      },
    });
  }

  public render(): JSX.Element {
    const { gutter, inline, noWrap, style, children, ...restProps } =
      this.props;

    const styles = this.getStyles();

    const childrenCount = React.Children.count(children);

    return (
      <View
        {...restProps}
        style={[
          inline && styles.view__isInline,
          noWrap && styles.view__noWrap,
          style,
        ]}
      >
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              gutter,
              last: index + 1 === childrenCount,
              inline,
            });
          }
        })}
      </View>
    );
  }
}

List.defaultProps = {
  gutter: 0,
  inline: false,
  noWrap: false,
};

export default List;
