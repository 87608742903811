// dependencies
import React from "react";
import { StyleSheet, View } from "react-native";

// components
import Icon from "../Icon";

export type ISocialLink = {
  social;
  to: string;
};

type IProps = {
  socialList: ISocialLink[];
};

const IconList: React.FunctionComponent<IProps> = (props): JSX.Element => {
  const { socialList } = props;

  const styles = StyleSheet.create({
    view: {
      flexDirection: "row",
    },
    iconView: {
      marginRight: "1.25em",
    },
    iconView__lastChild: {
      marginRight: 0,
    },
  });

  return (
    <View style={styles.view}>
      {socialList.map((social, index) => {
        return (
          <View
            style={[
              styles.iconView,
              index + 1 === socialList.length && styles.iconView__lastChild,
            ]}
            key={index}
          >
            <Icon social={social.social} to={social.to} />
          </View>
        );
      })}
    </View>
  );
};

export default IconList;
