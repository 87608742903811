import hasOwnProperty from "../hasOwnProperty";

const clone = (obj) => {
  if (null !== obj) {
    switch (typeof obj) {
      case "object": {
        const newObj = new obj.constructor();

        for (const key of Object.keys(obj)) {
          newObj[key] = clone(obj[key]);
        }

        return newObj;
      }

      case "function": {
        const newObj = function (this: any, ...args) {
          return obj.apply(this, args);
        };

        for (const key in obj) {
          if (hasOwnProperty(obj, key)) {
            newObj[key] = clone(obj[key]);
          }
        }

        return newObj;
      }

      default:
    }
  }

  return obj;
};

export default clone;
