/**
 * Returns if the value is a number or not.
 * @param val Any value.
 * @param lax If comparison should be lax.
 */
const isNumber = (val, lax = false): boolean => {
  if (lax) {
    return Object.prototype.toString.call(val) === "[object Number]";
  }

  return "number" === typeof val && !isNaN(val);
};

export default isNumber;
