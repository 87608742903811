// dependencies
import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import dynamic from "next/dynamic";
import Head from "next/head";
import { View } from "react-native";

// errors
import { BaseError, InternalServerError } from "../../errors";

// components
import Stack from "../../components/Stack";
import UIContainer from "../../components/UIContainer";
import ActivityIndicator from "../../components/ActivityIndicator";

type PropsType = {
  error?: BaseError | { message: string };
} & WrappedComponentProps;

type StateType = {
  error: BaseError;
};

const componentLoader = {
  store: {},
  get(key: string) {
    if (!(key in componentLoader.store)) {
      componentLoader.store[key] = dynamic(() => import(`../Error/${key}`), {
        loading: () => (
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              paddingTop: "6.25rem",
              paddingBottom: "6.25rem",
            }}
          >
            <ActivityIndicator size={"large"} />
          </View>
        ),
      });
    }

    return componentLoader.store[key];
  },
};

class ErrorBoundary extends React.Component<PropsType, StateType> {
  public static defaultProps: Partial<PropsType>;

  public static getDerivedStateFromError(error): Partial<StateType> {
    console.error(error);

    let newError;
    if (error instanceof BaseError) {
      newError = error;
    } else if (error instanceof Error) {
      newError = new InternalServerError(error.message);
    } else {
      newError = new InternalServerError("error.generic");
    }

    return {
      error: newError,
    };
  }

  static getComponent({ error }) {
    return {
      componentName:
        "string" === typeof error.component ? error.component : "Error",
      props:
        "object" === typeof error.props && null !== error.props
          ? { message: error.message, ...error.props }
          : { message: error.message },
    };
  }

  public state: StateType = {
    error: null,
  };

  public getError() {
    return null !== this.props.error ? this.props.error : this.state.error;
  }

  public render() {
    const { children } = this.props;

    const error = this.getError();

    if (null === error) {
      return children;
    }

    const { intl } = this.props;

    const { componentName, props: errorProps } = ErrorBoundary.getComponent({
      error,
    });

    const Error = componentLoader.get(componentName);

    return (
      <>
        <Head>
          <title key="title">
            {intl.formatMessage({ id: "app.containers.errorBoundary.title" })}
          </title>
        </Head>

        <Stack marginTop={3} />

        <UIContainer>
          <Error {...errorProps} />
        </UIContainer>

        <Stack marginTop={3} />
      </>
    );
  }
}

ErrorBoundary.defaultProps = {
  error: null,
};

export default injectIntl(ErrorBoundary);
