import convertUnit from "../convertUnit";

const matchQuery = ({ query, width, height }): boolean => {
  const { minWidth, maxWidth, minHeight, maxHeight } = query;

  const localWidth = convertUnit(width);
  const localHeight = convertUnit(height);

  if ("undefined" !== typeof minWidth && localWidth < minWidth) {
    return false;
  }

  if ("undefined" !== typeof maxWidth && localWidth >= maxWidth) {
    return false;
  }

  if ("undefined" !== typeof minHeight && localHeight < minHeight) {
    return false;
  }

  if ("undefined" !== typeof maxHeight && localHeight >= maxHeight) {
    return false;
  }

  return true;
};

export default matchQuery;
