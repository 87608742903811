/**
 * Helper permettant d'assigner une ref d'un composant à plusieurs variables.
 * Supporte la nouvelle syntaxe ainsi que la version legacy avec callback.
 */
const reactMultipleRefs =
  <T>(refPropList: React.Ref<T>[] = []) =>
  (ref: T) => {
    refPropList.forEach((refProp) => {
      if ("object" === typeof refProp) {
        (refProp as { current: T }).current = ref;
      } else if ("function" === typeof refProp) {
        // support de la syntaxe legacy
        refProp(ref);
      }
    });
  };

export default reactMultipleRefs;
