abstract class BaseError extends Error {
  public abstract status: string;

  public abstract code: number;

  public props: { [name: string]: any };

  public component = "Error";

  public toJSON() {
    return {
      message: this.message,
      stack: this.stack,
      name: this.name,
      status: this.status,
      component: this.component,
      props: this.props,
    };
  }
}

export default BaseError;
