import hasOwnProperty from "../hasOwnProperty";
import recursiveUnbracket from "../recursiveUnbracket";

/**
 * `/\[?((?:[\/\x30-\x39\x41-\x5a\x61-\x7a!$'()*+,;=:@\-._~]|%[0-9a-f][0-9a-f])*)\]?/gi`
 * @see https://tools.ietf.org/html/rfc3986#appendix-A
 */

const parseQuery = (query: string) => {
  if (query.length === 0) {
    return {};
  }

  return query
    .split("&")
    .reduce<{ [name: string]: string }>((peerAcc, peer) => {
      const [rawName, value] = peer.split("=");

      if ("" !== rawName && undefined !== value) {
        let parentRef;
        let ref: { [name: string]: string } | string[] = peerAcc;
        let parentKeyName;

        recursiveUnbracket(rawName).forEach((keyName, index, nameList) => {
          let localKeyName: number | string = decodeURIComponent(keyName);

          if (ref === null) {
            parentRef[parentKeyName] = ref = localKeyName === "" ? [] : {};
          }

          if ("object" === typeof ref) {
            if (!hasOwnProperty(ref, localKeyName)) {
              if (localKeyName === "") {
                localKeyName = (ref as string[]).push(null) - 1;
              } else {
                ref[localKeyName] = null;
              }
            }

            if (index === nameList.length - 1) {
              ref[localKeyName] = decodeURIComponent(value);
            }
          }

          parentRef = ref;
          parentKeyName = localKeyName;

          ref = ref[keyName];
        });
      }

      return peerAcc;
    }, {});
};

export default parseQuery;
