// constants
import {
  CONTENT_TYPE_CATEGORY,
  CONTENT_TYPE_CAMPAIGN,
} from "@cloudspire/legacy-resources/src/constants/content";

// libraires
import { generateUri } from "@cloudspire/legacy-shared/src/libraries";

/**
 * Détermine le lien du content en fonction du type
 *
 * @param {object} param0
 */
const generateUriRelayOnType = ({ intl, frontRouter, content }) => {
  switch (content.type) {
    case CONTENT_TYPE_CATEGORY: {
      return generateUri({
        router: frontRouter,
        name: "App.Action.RoomListWithSlug",
        parameters: {
          lang: intl.locale,
          slug: content.slug,
        },
      });
    }
    case CONTENT_TYPE_CAMPAIGN: {
      return generateUri({
        router: frontRouter,
        name: "App.Action.Campaign",
        parameters: {
          lang: intl.locale,
          slug: content.slug,
        },
      });
    }
    default: {
      return generateUri({
        router: frontRouter,
        name: "App.Action.Content",
        parameters: {
          lang: intl.locale,
          slug: content.slug,
        },
      });
    }
  }
};

export default generateUriRelayOnType;
