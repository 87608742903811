/**
 * Deeptly iterate over object to get a list of paths associated to a value.
 * @example
 * flattenDeepPropertyList({
 *   foo: {
 *     bar: ["quuz", "quux"],
 *     corge: "garply"
 *   },
 *   barfoo: Infinity,
 * });
 * // [
 * //   [["foo", "bar", "0"], "quuz"],
 * //   [["foo", "bar", "1"], "quux"],
 * //   [["foo", "corge"], "garply"],
 * //   [["barfoo"], Infinity],
 * // ]
 */
const flattenDeepPropertyList = (data, path = []) =>
  Object.keys(data).reduce((keyListAcc, key) => {
    if ("object" === typeof data[key] && null !== data[key]) {
      keyListAcc.push(...flattenDeepPropertyList(data[key], [...path, key]));
    } else {
      keyListAcc.push([[...path, key], data[key]]);
    }

    return keyListAcc;
  }, []);

export default flattenDeepPropertyList;
