// utils
import hasOwnProperty from "../../utils/hasOwnProperty";

function group(
  this: any,
  path: string,
  callback: (router: import("../group-router").default) => void,
  mixins: any = {}
) {
  const {
    setConstrains = ({ newRoute, route }) => {
      newRoute.setConstrains(route.getConstrains());
    },
    setPage = ({ newRoute, route }) => {
      newRoute.setPage(route.getPage());
    },
    setName = ({ newRoute, route }) => {
      newRoute.setName(route.getName());
    },
    setAncestor = ({ newRoute, route }) => {
      newRoute.setAncestor(route.getAncestor());
    },
  } = mixins;

  const router = new this.GroupRouter();

  callback(router);

  const routerOverloadedPaths = router.getOverloadedPaths();

  router.getRoutes().forEach((route) => {
    const tempRoute = this.route(
      route.getMethods(),
      `${path}${route.getPath()}`
    );

    setConstrains({ newRoute: tempRoute, route });

    setPage({ newRoute: tempRoute, route });

    setName({ newRoute: tempRoute, route });

    setAncestor({ newRoute: tempRoute, route });

    const routeOverloadedPaths = route.getOverloadedPaths();

    const routePath = route.getPath();

    const newPaths = {};

    for (const value of Object.keys({
      ...routerOverloadedPaths.paths,
      ...routeOverloadedPaths.paths,
    })) {
      const routerPathFragment = hasOwnProperty(
        routerOverloadedPaths.paths,
        value
      )
        ? routerOverloadedPaths.paths[value]
        : path;

      const routePathFragment = hasOwnProperty(
        routeOverloadedPaths.paths,
        value
      )
        ? routeOverloadedPaths.paths[value]
        : routePath;

      newPaths[value] = routerPathFragment + routePathFragment;
    }

    tempRoute.setOverloadedPaths({
      constrainName: routeOverloadedPaths.constrainName,
      paths: newPaths,
    });
  });

  return router;
}

export default group;
