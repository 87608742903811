// dependencies
import React from "react";
import {
  View,
  StyleSheet,
  ViewStyle,
  TextStyle,
  StyleProp,
} from "react-native";

import Text from "../Text";

// theming
import theming, { IPropsWithTheme } from "../../constants/theming";

const { withTheme } = theming;

type IProps = {
  Icon: React.ComponentType<{}>;
  viewStyle?: StyleProp<ViewStyle>;
  iconContainerStyle?: StyleProp<ViewStyle>;
  iconWrapperStyle?: StyleProp<TextStyle>;
  textContainerStyle?: StyleProp<TextStyle>;
} & IPropsWithTheme;

type IState = {};

class IconText extends React.Component<IProps, IState> {
  public static defaultProps: Partial<IProps>;

  private getStyles(): StyleSheet.NamedStyles<{
    view: ViewStyle;
    iconView: ViewStyle;
    iconText: TextStyle;
    text: TextStyle;
  }> {
    const { theme } = this.props;

    return StyleSheet.create({
      view: {
        flexDirection: "row",
        alignItems: "center",
      },
      text: {
        fontSize: `${theme.FONT_SIZE / 16}rem` as any,
      },
      iconView: {
        marginRight: ".625em",
      },
      iconText: {
        fontSize: `${theme.FONT_SIZE / 16}rem` as any,
        lineHeight: 0,
      },
    });
  }

  public render(): JSX.Element {
    const {
      Icon,
      viewStyle,
      iconContainerStyle,
      iconWrapperStyle,
      textContainerStyle,
      children,
    } = this.props;

    const styles = this.getStyles();

    return (
      <View style={[styles.view, viewStyle]}>
        <View style={[styles.iconView, iconContainerStyle]}>
          <Text style={[styles.iconText, iconWrapperStyle]}>
            <Icon />
          </Text>
        </View>

        <Text style={[styles.text, textContainerStyle]}>{children}</Text>
      </View>
    );
  }
}

IconText.defaultProps = {
  Icon: () => null,
};

export default withTheme(IconText);
