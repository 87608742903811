import isEmpty from "../isEmpty";

const localGet = (obj, keys, defaultValue?, currentKey = 0) => {
  // On arrive au plus profond niveau indiqué, on retourne l'objet.

  const key = keys[currentKey];

  if (keys.length === currentKey) {
    return obj;
  }

  if (null != obj && Object.prototype.hasOwnProperty.call(obj, key)) {
    return localGet(obj[key], keys, defaultValue, currentKey + 1);
  }

  return defaultValue;
};

const get = <T = any>(
  obj,
  keys: string | Array<string | number>,
  defaultValue?: T,
  normalize = true
) => {
  const localKeys =
    "string" === typeof keys ? (keys.length > 0 ? keys.split(".") : []) : keys;

  const value = localGet(obj, localKeys, defaultValue);

  if (normalize && isEmpty(value)) {
    return defaultValue;
  }

  return value;
};

export default get;
