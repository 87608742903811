// dependencies
import React from "react";
import { View, StyleSheet, ViewStyle, StyleProp } from "react-native";

// libraries
import { emToPx } from "../../../libraries";

export type PropsType = {
  breakpoint?: string;
  type?: "fixed" | "autofit";
  col?: { [breakpointName: string]: number };
  visible?: { [breakpointName: string]: boolean };
  span?: { [breakpointName: string]: number } | number;
  offset?: { [breakpointName: string]: number } | number;
  gutter?: number;
  style?: StyleProp<ViewStyle>;
};

const getStyles = ({
  breakpoint,
  gutter,
  col,
  span,
  offset,
  type,
  visible,
}) => {
  if (type === "fixed") {
    const localSpan: number =
      "object" === typeof span ? span[breakpoint] : span;

    const localOffset: number =
      "object" === typeof offset ? offset[breakpoint] : offset;

    const localVisible =
      "object" === typeof visible ? visible[breakpoint] : true;

    return StyleSheet.create({
      col: {
        display: localVisible ? "flex" : "none",
        width: `${localSpan * (100 / col[breakpoint])}%`,
        paddingTop: emToPx(gutter),
        paddingRight: emToPx(gutter),
        paddingBottom: emToPx(gutter),
        paddingLeft: emToPx(gutter),
        marginLeft: `${localOffset * (100 / col[breakpoint])}%`,
      },
    });
  }

  if (type === "autofit") {
    return StyleSheet.create({
      col: {
        paddingTop: gutter,
        paddingRight: gutter,
        paddingBottom: gutter,
        paddingLeft: gutter,
        flexShrink: 1,
        flexGrow: 1,
        flexBasis: "auto",
      },
    });
  }

  return StyleSheet.create({
    col: {},
  });
};

const Col: React.FunctionComponent<PropsType> = (props) => {
  const {
    breakpoint,
    gutter,
    col,
    span = 1,
    offset = 0,
    type,
    visible,
    style,
    children,
    ...otherProps
  } = props;

  const styles = getStyles({
    breakpoint,
    gutter,
    col,
    span,
    offset,
    type,
    visible,
  });

  return (
    <View {...otherProps} style={[styles.col, style]}>
      {children}
    </View>
  );
};

export default Col;
