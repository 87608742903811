// libraries
import {
  ObjectCacheManager,
  RedisCacheManager,
} from "../libraries/CacheManager";

const getCache = () => {
  if (!process.browser) {
    return import("./getRedisConnection")
      .then(({ default: getRedisConnection }) => getRedisConnection)
      .then(
        (getRedisConnection) => new RedisCacheManager(getRedisConnection())
      );
  } else {
    return new ObjectCacheManager();
  }
};

export default getCache;
