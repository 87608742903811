// dependencies
import { Router } from "../../libraries/Router";
import Uri from "../../libraries/Uri";

// constants
import { METHOD_VALUE_GET, METHOD_VALUE_POST } from "../methods";

const ID_REGEX = /[A-z0-9]+/;
const ROOM_REFERENCE_REGEX = /^[h][a-z0-9]{3}\d{6}$/;
const CUSTOMER_REGEXP = /C\d{9}/;
const USER_ID_REGEXP = /d{32}/;
const BOOKING_REFERENCE_REGEXP = /[Rr]\d{9}/;

if (
  "undefined" === typeof process.env.NEXT_PUBLIC_API_SCHEME ||
  "undefined" === typeof process.env.NEXT_PUBLIC_API_HOSTNAME
) {
  throw new Error(
    "You must define `NEXT_PUBLIC_API_SCHEME` and `NEXT_PUBLIC_API_HOSTNAME` in `.env` configuration."
  );
}

const apiUri = new Uri(
  process.env.NEXT_PUBLIC_API_SCHEME,
  undefined,
  process.env.NEXT_PUBLIC_API_HOSTNAME
);

const apiRouter = new Router(apiUri);

apiRouter.get("").setName("Api.Info");

apiRouter.group("", (apiRouter) => {
  // Monolithe

  /**
   * Channels endpoints
   */
  apiRouter.group("/channels", (apiRouter) => {
    apiRouter.group("/action", (apiRouter) => {
      apiRouter.get("/discover").setName("Api.Channels.Action.Discover");

      apiRouter.get("/config").setName("Api.Channels.Action.Config");

      apiRouter
        .get("/configuration")
        .setName("Api.Channels.Action.Configuration");

      apiRouter
        .post("/:id/action/owner-notify")
        .setName("Api.Channels.Action.OwnerNotify")
        .setConstrain("id", ID_REGEX);
    });

    apiRouter
      .get("/:id")
      .setName("Api.Channels.Show")
      .setConstrain("id", ID_REGEX);
  });

  /**
   * Rooms endpoints
   */
  apiRouter.group("/rooms", (apiRouter) => {
    apiRouter
      .route([METHOD_VALUE_GET, METHOD_VALUE_POST], "")
      .setName("Api.Rooms");

    apiRouter.group("/action", (apiRouter) => {
      apiRouter.get("/indexes").setName("Api.Rooms.Indexes");
    });

    apiRouter.group("/:roomReference", (apiRouter) => {
      apiRouter.setConstrain("roomReference", ROOM_REFERENCE_REGEX);

      apiRouter
        .post("/action/message")
        .setName("Api.Rooms.Action.Message.Create");

      apiRouter.post("/metrics").setName("Api.Action.Room.Metric.Create");
    });

    apiRouter
      .get("/:referenceOrSlug/action/current")
      .setName("Api.Rooms.Action.Current")
      .setConstrain("referenceOrSlug", ID_REGEX);
  });

  /**
   * Comments endpoints
   */
  apiRouter.group("/comments", (apiRouter) => {
    apiRouter
      .route([METHOD_VALUE_GET, METHOD_VALUE_POST], "")
      .setName("Api.Comments");
  });

  /**
   * Contents EndPoints
   */
  apiRouter.group("/contents", (apiRouter) => {
    apiRouter.get("").setName("Api.Content.Search");

    apiRouter
      .get("/:slug")
      .setName("Api.Content.Show")
      .setConstrain("slug", ID_REGEX);
  });

  /**
   * Campaigns EndPoints
   */
  apiRouter.group("/campaigns", (apiRouter) => {
    apiRouter.get("").setName("Api.Campaigns");

    apiRouter
      .get("/:slug")
      .setName("Api.Campaign.Show")
      .setConstrain("slug", ID_REGEX);
  });

  /**
   * FeatureList EndPoints
   */
  apiRouter.group("/room-features", (apiRouter) => {
    apiRouter.get("").setName("Api.RoomFeature.Search");
  });
});

/**
 * Alfred
 */
apiRouter.group("/alfred", (apiRouter) => {
  /**
   * Availabilities
   */

  apiRouter
    .get("/rooms/:reference/availabilities/legacy")
    .setName("Api.Alfred.RoomsAvailabilities.ListLegacy")
    .setConstrain("reference", ID_REGEX);

  /**
   * Pricing
   */
  apiRouter.get("/rooms/pricing").setName("Api.Alfred.Room.Pricing.Estimate");

  /**
   * Users
   */
  apiRouter.group("/users", (apiRouter) => {
    /**
     * Customers
     */
    apiRouter.group("/customers", (apiRouter) => {
      apiRouter.post("").setName("Api.Alfred.Users.Customers.Create");
    });
  });

  apiRouter.group("/payments", (apiRouter) => {
    apiRouter.group("/ancv-connect", (apiRouter) => {
      apiRouter.group("/transactions", (apiRouter) => {
        apiRouter
          .post("")
          .setName("Api.Payment.AncvConnect.Transaction.Create");
        apiRouter.group("/:transactionId", (apiRouter) => {
          apiRouter.setConstrain("transactionId", ID_REGEX);
          apiRouter.get("").setName("Api.Payment.AncvConnect.Transaction.Show");
        });
      });
    });
  });

  apiRouter
    .get("/hotels/:roomReference/services")
    .setConstrain("roomReference", ID_REGEX)
    .setName("Api.Action.Hotel.Service.Search");
});

/**
 * Users
 */
apiRouter.group("/users", (apiRouter) => {
  apiRouter.post("/login").setName("Api.Action.User.Login");

  apiRouter.group("/:userId", (apiRouter) => {
    apiRouter.setConstrain("userId", USER_ID_REGEXP);

    apiRouter.group("/action", (apiRouter) => {
      apiRouter.get("/whois").setName("Api.Action.User.Action.Whois");
    });
  });
});

/**
 * Customers
 */
apiRouter.group("/customers", (apiRouter) => {
  apiRouter.group("/:customerReference", (apiRouter) => {
    apiRouter.setConstrain("customerReference", CUSTOMER_REGEXP);

    apiRouter.get("").setName("Api.Action.User.Customer.Show");
  });

  apiRouter.post("").setName("Api.User.Customer.Create");
});

/**
 * Contact EndPoints
 */
apiRouter.group("/contact", (apiRouter) => {
  apiRouter.get("").setName("Api.Campaigns");

  apiRouter.post("").setName("App.Contact.Create");
});

/**
 * Booking
 */
apiRouter.group("/bookings", (apiRouter) => {
  apiRouter.group("/:bookingReference", (apiRouter) => {
    apiRouter.setConstrain("bookingReference", BOOKING_REFERENCE_REGEXP);

    apiRouter.get("").setName("Api.Booking.Show");
  });

  apiRouter.group("/action", (apiRouter) => {
    apiRouter.get("/estimate").setName("Api.Bookings.Action.Estimate");

    apiRouter.post("/aio").setName("Api.Bookings.AllInOne");
  });
});

/**
 * Slides
 */
apiRouter.group("/slides", (apiRouter) => {
  apiRouter.get("").setName("Api.Slides.Search");
});

/**
 * Place Autocomplete
 */
apiRouter.group("/tools/place-autocomplete", (apiRouter) => {
  apiRouter.get("").setName("Api.Place.Autocomplete");
});

/**
 * Payment
 */
apiRouter.group("/payments", (apiRouter) => {
  apiRouter.group("/action", (apiRouter) => {
    apiRouter.get("/paybox/config").setName("Api.Payment.Action.Paybox.Config");
  });
});

/**
 * PDF
 */
apiRouter.group("/pdf", (apiRouter) => {
  apiRouter.group("/action", (apiRouter) => {
    apiRouter.post("/token").setName("Api.Action.Pdf.Token");
  });
});

export default apiRouter;
