// libraries
import { Router } from "../../libraries/Router";

const BOOKING_REGEXP = /R\d{9}/;

const accountRouter = new Router();

accountRouter.group("/:lang", (accountRouter) => {
  accountRouter.setConstrain("lang", /[a-z]{2,}/);

  // Index
  accountRouter.get("").setPage("index").setName("App.Action.Home");

  accountRouter.group("/auth", (accountRouter) => {
    // Login
    accountRouter.get("/login").setName("App.Action.Auth.Login");

    // Register
    accountRouter.get("/register").setName("App.Action.Auth.Register");

    // Recover
    accountRouter.get("/recover").setName("App.Action.Auth.Recover");

    // Restore
    accountRouter.get("/restore").setName("App.Action.Auth.Restore");
  });

  accountRouter.group("/mes-voyages", (accountRouter) => {
    accountRouter
      .get("/detail/:bookingReference")
      .setConstrain("bookingReference", BOOKING_REGEXP)
      .setName("App.Action.BookingCustomer.Show");
  });

  accountRouter.group("/mon-compte", (accountRouter) => {
    accountRouter
      .get("/informations-personnelles")
      .setName("App.Action.MyAccount.Personnal");
  });

  accountRouter.group("/mes-projets", (accountRouter) => {
    accountRouter.post("/creer").setName("@account/hotel/project/create");
  });
});

export default accountRouter;
