import { STORAGE_MESSAGES_KEY } from "../../constants/message";

const setMessagesToStorage = ({ language, messages }) => {
  if (process.browser) {
    window.sessionStorage.setItem(
      `${STORAGE_MESSAGES_KEY}.${language}`,
      JSON.stringify(messages)
    );
  }
};

export default setMessagesToStorage;
