import { fromDate } from ".";

const fromString = (date, format = "Y-m-d") => {
  switch (format) {
    case "Y-m-d": {
      const parser = /^(\d{4})-(\d{2})-(\d{2})$/;

      if (!parser.test(date)) {
        return null;
      }

      const parsedDate = parser.exec(date);

      const year = parseInt(parsedDate[1]);
      const month = parseInt(parsedDate[2]);
      const day = parseInt(parsedDate[3]);

      return fromDate(new Date(year, month - 1, day));
    }

    case "Y-m-d H:i:s": {
      const parser = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;

      if (!parser.test(date)) {
        return null;
      }

      const parsedDate = parser.exec(date);

      const year = parseInt(parsedDate[1]);
      const month = parseInt(parsedDate[2]);
      const day = parseInt(parsedDate[3]);
      const hours = parseInt(parsedDate[4]);
      const minutes = parseInt(parsedDate[5]);
      const seconds = parseInt(parsedDate[6]);

      return fromDate(new Date(year, month - 1, day, hours, minutes, seconds));
    }

    case "H:i": {
      const parser = /^(\d{2}):(\d{2})$/;

      if (!parser.test(date)) {
        return null;
      }

      const parsedDate = parser.exec(date);

      const hours = parseInt(parsedDate[1]);
      const minutes = parseInt(parsedDate[2]);

      return fromDate(new Date(1970, 0, 1, hours, minutes, 0));
    }

    case "d/m/Y": {
      const parser = /^(\d{2})\/(\d{2})\/(\d{4})$/;

      if (!parser.test(date)) {
        return null;
      }

      const parsedDate = parser.exec(date);

      const day = parseInt(parsedDate[1]);
      const month = parseInt(parsedDate[2]);
      const year = parseInt(parsedDate[3]);

      return fromDate(new Date(year, month - 1, day));
    }
  }
};

export default fromString;
