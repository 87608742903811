const convertUnit = (value: string | number = 0, em = 16): number => {
  if ("number" === typeof value) {
    return value;
  }

  let suffixIndex;

  if ((suffixIndex = value.indexOf("px")) > -1) {
    return Number(value.slice(0, suffixIndex));
  }

  if ((suffixIndex = value.indexOf("rem")) > -1) {
    return Number(value.slice(0, suffixIndex)) * em;
  }

  if ((suffixIndex = value.indexOf("em")) > -1) {
    return Number(value.slice(0, suffixIndex)) * em;
  }

  return Number(value);
};

export default convertUnit;
