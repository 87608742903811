import {
  IDate,
  IDay,
  IDayName,
  DAY_NAMES,
  IMonth,
  IWeeksInMonth,
  IHours,
  IMinutes,
  ISeconds,
  IDaysInMonth,
  MONTHS_IN_YEAR,
  DAYS_IN_WEEK,
} from "./constants";

import formats from "./formats";

import fromDate from "./fromDate";

/**
 * Objet de manipulation des dates.
 * Attention, toutes les méthodes sont mutables.
 * Il est nécessaire d'utilise les méthodes `.clone()` qui renvoie une nouvelle instance
 *   pour éviter toute mutation de l'objet initial.
 */
class EnhancedDate {
  /**
   * Récupère le nombre de jours à décaler en fonction du premier jour de la semaine.
   */
  public static getDayShift(firstDayOfWeek: IDayName = "sunday"): IDay {
    return DAY_NAMES[firstDayOfWeek] as IDay;
  }

  private date: Date;

  public static fromDate(date: Date = new Date(Date.now())): EnhancedDate {
    return fromDate(date);
  }

  public constructor(date: Date = new Date(Date.now())) {
    this.date = date;
  }

  /**
   * Créer une nouvelle instance de l'objet.
   * Permet de ne pas altérer/muter une version de la date.
   */
  public clone(): EnhancedDate {
    return new EnhancedDate(new Date(this.date.valueOf()));
  }

  /**
   * Récupère l'année.
   */
  public getYear(): number {
    return this.date.getFullYear();
  }

  /**
   * Récupère le mois.
   */
  public getMonth(): IMonth {
    return this.date.getMonth() as IMonth;
  }

  /**
   * Récupère le jour.
   * 0 pour dimanche et 6 pour samedi.
   */
  public getDay(): IDay {
    return this.date.getDay() as IDay;
  }

  /**
   * Récupère la date du jour.
   */
  public getDate(): IDate {
    return this.date.getDate() as IDate;
  }

  /**
   * Récupère le nombre d'heures.
   */
  public getHours(): IMinutes {
    return this.date.getHours() as IHours;
  }

  /**
   * Récupère le nombre de minutes.
   */
  public getMinutes(): IMinutes {
    return this.date.getMinutes() as IMinutes;
  }

  /**
   * Récupère le nombre de secondes.
   */
  public getSeconds(): ISeconds {
    return this.date.getSeconds() as ISeconds;
  }

  /**
   * Récupère le nombre de millisecondes.
   */
  public getMilliseconds(): number {
    return this.date.getMilliseconds();
  }

  /**
   * Récupère le premier jour de la semaine du mois courant compris entre 0 et 6.
   * 0 pour dimanche et 6 pour samedi.
   */
  public getFirstWeekDayOfMonth(): IDay {
    const enhancedDate = this.clone();

    enhancedDate.setDate(1);

    return enhancedDate.getDay();
  }

  public getLastDayOfMonth(): IDay {
    const enhancedDate = this.clone();

    enhancedDate.setDate(this.getNumberOfDaysInMonth());

    return enhancedDate.getDay();
  }

  /**
   * Récupère le nombre de jours dans un mois.
   */
  public getNumberOfDaysInMonth(): IDaysInMonth {
    const enhancedDate = this.clone();

    const month: number = enhancedDate.getMonth() as number;

    const newMonth = month + 1;
    if (newMonth === MONTHS_IN_YEAR) {
      enhancedDate.setYear(enhancedDate.getYear() + 1);
      enhancedDate.setMonth(0);
    } else {
      enhancedDate.setMonth(newMonth as IMonth);
    }

    // normalement, la date est comprise en 1 et 31 mais ici, on souhaite revenir au mois précédent
    enhancedDate.setDate(0 as IDate);

    return enhancedDate.getDate() as IDaysInMonth;
  }

  /**
   * Récupère le nombre de semaines dans un mois.
   * Algorithme:
   * 1) `hasCutFirstWeek` On regarde si la première semaine du mois est complète (son nombre de jour vaut 7).
   * 2) `hasCutLastWeek` On regarde si la dernière semaine du mois est complète (son nombre de jour vaut 7).
   * 3) `weeksInMonth` On effectue la division entière du nombre de jour dans le mois par 7 (le nombre de jour dans une semaine).
   * 4) (tricky) Si la somme du nombre de jour de la première semaine avec le nombre de jour dans la dernière semaine
   *               est supérieur à 7 (cela signifie que ce nombre a été inclus dans la division entière précédemment effectué), on retranche 1 à `weeksInMonth`
   * 5) Si `hasCutFirstWeek` = `vrai`, on ajoute 1 à `weeksInMonth`.
   * 6) Si `hasCutLastWeek` = `vrai`, on ajoute 1 à `weeksInMonth`.
   */
  public getNumberOfWeeksInMonth(
    firstDayOfWeek: IDayName = "sunday"
  ): IWeeksInMonth {
    const shift = EnhancedDate.getDayShift(firstDayOfWeek);

    const daysInMonth = this.getNumberOfDaysInMonth();
    const firstDayOfMonth =
      (this.getFirstWeekDayOfMonth() - shift + daysInMonth) % daysInMonth;
    const lastDayOfMonth =
      (this.getLastDayOfMonth() - shift + daysInMonth) % daysInMonth;

    // 1)
    const hasCutFirstWeek = firstDayOfMonth !== 0;
    // 2)
    const hasCutLastWeek = lastDayOfMonth !== 6;

    // 3)
    return (Math.floor(daysInMonth / DAYS_IN_WEEK) +
      // 5)
      (hasCutFirstWeek ? 1 : 0) +
      // 6)
      (hasCutLastWeek ? 1 : 0) +
      (hasCutFirstWeek &&
      hasCutLastWeek &&
      // 4)
      DAYS_IN_WEEK - firstDayOfMonth + lastDayOfMonth + 1 >= 7
        ? -1
        : 0)) as IWeeksInMonth;
  }

  public setYear(year: number): this {
    this.date.setFullYear(year);

    return this;
  }

  /**
   * Définis le mois.
   */
  public setMonth(month: IMonth): this {
    this.date.setMonth(month);

    return this;
  }

  /**
   * Ajoute un mois à la date actuuelle.
   * Si le montant indiqué est négatif, un mois sera retranché.
   */
  public addMonth(add = 1): this {
    this.date.setMonth(this.date.getMonth() + add);

    return this;
  }

  /**
   * Ajoute un jour à la date actuuelle.
   * Si le montant indiqué est négatif, un mois sera retranché.
   */
  public addDate(add = 1): this {
    this.date.setDate(this.date.getDate() + add);

    return this;
  }

  /**
   * Définis la date du jour.
   */
  public setDate(date: IDate): this {
    this.date.setDate(date);

    return this;
  }

  /**
   * Définis la date comme au dernier jour du mois actuel.
   */
  public setDateToLastOfMonth(): this {
    this.date.setDate(this.getNumberOfDaysInMonth());

    return this;
  }

  /**
   * Définis l'heure.
   */
  public setHours(hours: IHours): this {
    this.date.setHours(hours);

    return this;
  }

  /**
   * Définis les minutes.
   */
  public setMinutes(minutes: IMinutes): this {
    this.date.setMinutes(minutes);

    return this;
  }

  /**
   * Définis les secondes.
   */
  public setSeconds(seconds: ISeconds): this {
    this.date.setSeconds(seconds);

    return this;
  }

  /**
   * Défini les millisecondes.
   */
  public setMilliseconds(milliseconds: number): this {
    this.date.setMilliseconds(milliseconds);

    return this;
  }

  /**
   * Défini le timestamp, le nombre de millisecondes écoulées depuis le 1 janvier 1970.
   */
  public setTime(time: number): this {
    this.date.setTime(time);

    return this;
  }

  /**
   * Retourne le timestamp, le nombre de millisecondes écoulées depuis le 1 janvier 1970.
   */
  public getTime(): number {
    return this.date.getTime();
  }

  /**
   * Calcule la différene entre deux dates.
   */
  public monthDiff(date: EnhancedDate) {
    let diff = 0;

    const tempDate1 = this.clone().startOfMonths();
    const tempDate2 = date.clone().startOfMonths();
    const tempDate3 = tempDate1.clone().startOfMonths();

    const factor = tempDate1.isGreaterThan(tempDate2) ? -1 : 1;

    while (
      factor > 0
        ? tempDate3.isLessThan(tempDate2)
        : tempDate3.isGreaterThan(tempDate2)
    ) {
      diff += factor;

      tempDate3.addMonth(factor);
    }

    return diff;
  }

  /**
   * Supprime les informations des millisecondes.
   */
  public startOfMilliseconds() {
    return this;
  }

  /**
   * Supprime les informations des millisecondes et secondes.
   */
  public startOfSeconds() {
    this.setMilliseconds(0);

    return this;
  }

  /**
   * Supprime les informations des millisecondes, secondes et minutes.
   */
  public startOfMinutes() {
    this.setSeconds(0);
    this.setMilliseconds(0);

    return this;
  }

  /**
   * Supprime les informations des millisecondes, secondes et minutes.
   */
  public startOfHours() {
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);

    return this;
  }

  /**
   * Supprime les informations des millisecondes, secondes, minutes et heures.
   */
  public startOfDays() {
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);

    return this;
  }

  /**
   * Supprime les informations des millisecondes, secondes, minutes, heures
   *   et jours.
   */
  public startOfMonths() {
    this.setDate(1);
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);

    return this;
  }

  /**
   * Supprime les informations des millisecondes, secondes, minutes, heures,
   *   mois et jours.
   */
  public startOfYears() {
    this.setMonth(0);
    this.setDate(1);
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);

    return this;
  }

  /**
   * Retourne vrai si la date courante est plus grande que celle indiquée.
   */
  public isGreaterThan(date: EnhancedDate): boolean {
    if (date instanceof EnhancedDate) {
      return this.date.getTime() > date.date.getTime();
    }

    return false;
  }

  /**
   * Retourne vrai si la date courante est plus grande ou égale à celle indiquée.
   */
  public isGreaterOrEqualTo(date: EnhancedDate): boolean {
    if (date instanceof EnhancedDate) {
      return this.date.getTime() >= date.date.getTime();
    }

    return false;
  }

  /**
   * Retourne vrai si la date courante est plus petite que celle indiquée.
   */
  public isLessThan(date: EnhancedDate): boolean {
    if (date instanceof EnhancedDate) {
      return this.date.getTime() < date.date.getTime();
    }

    return false;
  }

  /**
   * Retourne vrai si la date courante est plus petite ou égale à celle indiquée.
   */
  public isLessOrEqualTo(date: EnhancedDate): boolean {
    if (date instanceof EnhancedDate) {
      return this.date.getTime() <= date.date.getTime();
    }

    return false;
  }

  /**
   * Retourne vrai si la date courante est égale à celle indiquée.
   */
  public isEqualTo(date: EnhancedDate): boolean {
    if (date instanceof EnhancedDate) {
      return this.date.getTime() === date.date.getTime();
    }

    return false;
  }

  /**
   * @deprecated
   * Retourne vrai si la date courante est égale à celle indiquée.
   */
  public isEqual(date: EnhancedDate): boolean {
    return this.isEqualTo(date);
  }

  public format(format) {
    return format.replace(/(\\?)(.)/g, (_, esc, char) =>
      esc === "" && formats[char] ? formats[char].call(null, this) : char
    );
  }

  /**
   * Retourne l'objet Date natif.
   */
  public getNativeDate(): Date {
    return this.date;
  }

  public valueOf() {
    return this.date.valueOf();
  }
}

export default EnhancedDate;
