import clone from "../clone";

/**
 * Rend immutable le contexte de la propriété.
 * @example
 * class Foo {
 *   constructor() {
 *     Object.defineProperty(this, "immutableBar", {
 *       value: immutable(this.immutableBar, this),
 *     });
 *   }
 *
 *   bar() { return this; }
 *
 *   immutableBar() { return this; }
 * }
 *
 * foo = new Foo();
 * foo.immutableBar() === foo; // false
 * foo.bar() === foo; // true
 */
const immutable = (fn: Function, context) => {
  return (...args) => {
    return fn.apply(clone(context), args);
  };
};

export default immutable;
