import GroupRouter from "./group-router";
import Route from "./route";
import AbstractRouter from "./abstract-router";

class Router extends AbstractRouter {
  protected GroupRouter = GroupRouter;

  protected Route = Route;
}

export default Router;
